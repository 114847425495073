/**
 * @type {import('@builder.io/sdk/dist/src/builder.class').Component}
 */

import { idConfig } from "../util/shared-configs";

export default {
  name: 'exploreFeatures',
  friendlyName: 'Explore Features',
broadcast: true,
  noWrap: true,
  defaultStyles: {},
  image:
    'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fb082775cbfbb4ff595fb9a5fc5dc45c1?quality=60&width=200&height=200',
  inputs: [
    {
      name: 'selectFeatures',
      friendlyName: 'Select Features Mobile Text',
      type: 'string',
      defaultValue: 'Select Features',  
    },
    {
      type: 'list',
      name: 'features',
      subFields: [
        {
          name: 'name',
          friendlyName: 'Feature Name',
          type: 'string',
          defaultValue: 'Name',
        },
        {
          name: 'descriptionDesktop',
          friendlyName: 'Feature Description Desktop',
          type: 'string',
          defaultValue: 'Desktop Description',
        },
        {
          name: 'descriptionMobile',
          friendlyName: 'Feature Description Mobile',
          type: 'string',
          defaultValue: 'Mobile Description',
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'webp'],
          defaultValue: 'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d',
        },
      ],
    },
    idConfig
  ],
};
