import { useCallback, useEffect } from 'react';
import useLocalStorage from '@glowforge/builder-components/lib/util/use-local-storage';

const SECOND = 1000;
const MINUTE = 60 * SECOND;

const UPDATE_INTERVAL = 1 * SECOND;

const PREFIX = 'GF_PageviewAndTimeTracker';
const PAGEVIEWS = `${PREFIX}.pageviews`;
const TIME = `${PREFIX}.time`;
const FIRED_CALLBACKS = `${PREFIX}.firedCallbacks`;

const usePageviews = (reset, url) => {
  const [pageviews, setPageviews] = useLocalStorage(PAGEVIEWS, 0, reset);
  useEffect(() => {
    setPageviews((views) => views + 1);
  }, [setPageviews, url]);
  return pageviews;
};

const useTime = (reset) => {
  const [time, setTime] = useLocalStorage(TIME, 0, reset);
  useEffect(() => {
    const interval = setInterval(
      () => setTime((t) => t + UPDATE_INTERVAL),
      UPDATE_INTERVAL,
    );
    return () => clearInterval(interval);
  }, [setTime]);
  return time;
};

const useFired = ({
  name,
  minutes,
  pages,
  pageviews,
  time,
  callback,
  reset,
}) => {
  const [fired, setFired] = useLocalStorage(
    `${FIRED_CALLBACKS}.${name}`,
    false,
    reset,
  );

  const fireCallback = useCallback(() => {
    callback({ name, pageviews, time });
    setFired(true);
  }, [callback, name, pageviews, setFired, time]);

  useEffect(() => {
    if (fired) return;

    const milliseconds = minutes * MINUTE;
    if (pageviews >= pages || time >= milliseconds) {
      fireCallback();
    }
  }, [fireCallback, fired, minutes, pages, pageviews, time]);
  return fired;
};

const usePageviewsAndTime = ({
  name,
  minutes,
  pages,
  url,
  callback,
  reset = false,
}) => {
  const pageviews = usePageviews(reset, url);
  const time = useTime(reset);
  const fired = useFired({
    name,
    minutes,
    pages,
    pageviews,
    time,
    callback,
    reset,
  });

  return { pageviews, time, fired };
};

export default usePageviewsAndTime;
