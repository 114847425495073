import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import useEscapeHandler from '../useEscapeHandler';
import useOutsideClick from '../useOutsideClick';
import { PopoverBody } from './usePopover.styles';

const ARROW_SIZE = 8;

/**
 * The Popover Component sets a span immediately after its anchor element.
 * It then absolutely positions itself in relation to its parent.
 * This is why we provide the PopoverWrapper (unnecesary if the anchor's
 * parent has positioning set)
 */
const Popover = ({
  isOpen,
  close,
  position,
  align,
  arrowSize,
  children,
  anchorRef,
}) => {
  const ref = useRef(null);
  const [root, setRoot] = useState();

  useOutsideClick(
    () => {
      if (isOpen) close(); // prevents running when initially opening the popover
    },
    [],
    ref
  );
  useEscapeHandler(close);

  useEffect(() => {
    if (anchorRef.current) {
      const elem = document.createElement('span');
      const currentRoot = anchorRef.current.appendChild(elem);
      setRoot(currentRoot);

      return () => {
        // eslint-disable-next-line no-unused-expressions
        currentRoot?.remove();
      };
    }
    return () => {};
  }, [anchorRef]);

  return isOpen && root
    ? createPortal(
        <PopoverBody
          position={position}
          align={align}
          arrowSize={arrowSize}
          role='dialog'
          ref={ref}>
          {children}
        </PopoverBody>,
        root
      )
    : null;
};

Popover.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  anchorRef: PropTypes.shape({
    current: PropTypes.shape({
      appendChild: PropTypes.func,
    }),
  }).isRequired,
  align: PropTypes.oneOf(['start', 'end']),
  arrowSize: PropTypes.number,
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
};

Popover.defaultProps = {
  isOpen: false,
  arrowSize: ARROW_SIZE,
  position: 'bottom',
  align: 'end',
};

export default Popover;
