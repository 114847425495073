const defaultRoutes = {
  logoLink: '/',
  primaryRoutes: [
    {
      text: 'Meet Glowforge',
      url: '/products',
      local: true,
    },
    {
      text: 'Reviews',
      url: '/reviews',
      local: true,
    },
    {
      text: 'Stories',
      url: '/v/stories',
      local: true,
    },
    {
      text: 'Community',
      url: 'https://community.glowforge.com',
      local: false,
    },
    {
      text: 'Gallery',
      url: '/discover',
      local: false,
    },
  ],
  userRoutes: [
    {
      local: true,
      text: 'Glowforge Print',
      url: 'https://app.glowforge.com',
      icon: 'print',
    },
    {
      local: false,
      text: 'Referrals',
      url: '/referrals',
      icon: 'referrals',
    },
    {
      local: false,
      text: 'Settings',
      url: '/settings',
      icon: 'settings',
    },
  ]
}

export default defaultRoutes