import { useCallback, useState } from 'react';

import useInterval from '../useInterval';
import { Carousel, PaginationDots } from './useCarouselComponents';

/* See useCarousel.README.md */
const useCarousel = (delay = null) => {
  const [length, setLength] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  // This cb is used by useInterval -> simply increments the index.
  const goNext = useCallback(
    () => {
      setActiveIndex((currIndex) => {
        /* We wrap our index if we are at the end,
    otherwise we move up one */
        const newIndex = currIndex === length - 1 ? 0 : currIndex + 1;
        return newIndex;
      });
    },
    [length]
  );

  // set up the interval with cb
  const { toggle, reset } = useInterval(goNext, delay);


  /**
   * Function for going forward one -> this is meant to be manually invoked,
   * so we also want to reset the interval counter.
   */
   const goNextAndReset = useCallback(
    () => {
      reset();
      goNext();
    },
    [goNext, reset]
  );


  /**
   * Function for going back one -> this is meant to be manually invoked,
   * so we also want to reset the interval counter.
   */
  const goPrevAndReset = useCallback(
    () => {
      reset();
      setActiveIndex((currIndex) => {
        /* We wrap our index if we are at
    the beginning, otherwise we move down one */
        const newIndex = currIndex === 0 ? length - 1 : currIndex - 1;
        return newIndex;
      });
    },
    [length, reset]
  );

  /**
   * Function for setting an arbitrary index. 
   * This is meant to be manually invoked,
   * so we also want to reset the interval counter.
   */
  const setActiveIndexAndReset = useCallback(
    (...args) => {
      reset();
      setActiveIndex(...args);
    },
    [reset]
  );
  const carouselProps = {
    length,
    activeIndex,
    setActiveIndex: setActiveIndexAndReset,
    setLength,
    goPrev: goPrevAndReset,
    goNext: goNextAndReset,
    resetDelay: reset,
    toggle,
  };

  return { carouselProps, Carousel, PaginationDots };
};

export default useCarousel;
