import React from 'react';
import PropTypes from 'prop-types';
import SVG from '../../svg/svg';
import VisuallyHidden from '../../visually-hidden/visually-hidden';

import { Nav, NavSection, NavUL } from '../shared/top-nav.styles';

import NavLinks from '../shared/nav-links';
import DesktopUserNavSection from './desktop-user-nav-section';

const DesktopNav = ({
  activeLink,
  buttonSection,
  routes,
  user,
  userFetchState,
  logoLink,
}) => {
  const { primaryRoutes, userRoutes } = routes;

  const logoHref =
    logoLink || primaryRoutes.find((route) => route.logoLink === true)?.url;
  return (
    <Nav aria-labelledby='nav-desktop-label' desktopOnly>
      <VisuallyHidden id='nav-desktop-label'>Main Menu</VisuallyHidden>
      <NavSection>
        <a
          href={logoHref}
          tabIndex='0' // for some reason, screen readers aren't picking up this anchor tag.
          aria-label='Home Link'>
          {/* unset logo width - 27px in design */}
          <SVG id='glowforge-logo' width='130px' height='27px' stroke='none' />
        </a>
        <NavUL role='list'>
          <NavLinks
            activeLink={activeLink}
            routes={primaryRoutes.filter((route) => !route.logoLink)}
          />
        </NavUL>
      </NavSection>
      <NavSection>
        {buttonSection}
        <DesktopUserNavSection
          user={user}
          userFetchState={userFetchState}
          routes={userRoutes}
          activeLink={activeLink}
        />
      </NavSection>
    </Nav>
  );
};

DesktopNav.propTypes = {
  activeLink: PropTypes.string,
  buttonSection: PropTypes.node,
  routes: PropTypes.shape({
    primaryRoutes: PropTypes.arrayOf(PropTypes.shape({})),
    userRoutes: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  user: PropTypes.shape({}),
  userFetchState: PropTypes.string,
  logoLink: PropTypes.string,
};

DesktopNav.defaultProps = {
  activeLink: '',
  buttonSection: null,
  routes: {
    primaryRoutes: [],
    userRoutes: [],
  },
  user: {},
  userFetchState: 'pending',
  logoLink: '/',
};

export default DesktopNav;
