import { allowedColors } from '@glowforge/gf-styled/src/theme/constants/colors';

export default {
  name: 'Glowforge:QuoteCard',
  image: 'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F64129cd54314415d9400530570344285?width=2000',
  inputs: [
    {
      friendlyName: 'Text Background Image',
      helperText: 'Used as a background texture for the Text card portion.',
      name: 'backgroundImage',
      type: 'string',
      enum: [
        'magic dots',
        'wood',
        'honeycomb',
      ],
      defaultValue: 'magic dots',
    }, 
    {
      friendlyName: 'Background Color',
      name: 'backgroundColor',
      type: 'string',
      enum: allowedColors,
      defaultValue: 'magic.dark',
    },
    {
      friendlyName: 'Display Quote?',
      name: 'showQuote',
      type: 'boolean',
      defaultValue: false,
      helperText:
        'The quote icon color is the lightest variation of the chosen Background Color.',
    },
  ],
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: {
        name: 'Box',
      },
      children: [
        {
          '@type': '@builder.io/sdk:Element',
          component: {
            name: 'Glowforge:Text',
            options: {
              variant: 'heading',
              as: 'p',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae elementum curabitur vitae nunc sed.',
            },
          },
          responsiveStyles: {
            large: {
              color: '#26B8CE',
              paddingBottom: '20px',
              textAlign: 'left',
            }
          },
        },
        {
          '@type': '@builder.io/sdk:Element',
          component: {
            name: 'Glowforge:Text',
            options: {
              variant: 'paragraph',
              as: 'p',
              text: 'Name Here',
            },
          },
          responsiveStyles: {
            large: {
              textAlign: 'left',
            },
          },
        },
      ],
    },
  ]
}