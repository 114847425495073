import {
  HeroContainer,
  BackgroundImageContainer,
  HeroContentContainer,
  HeroContentWrapper,
  HeroTitle,
  AuthorDateWrapper,
  DateText,
  AuthorLink,
} from '../blog-hero.styles';
import { Overlay } from '../../hero-banner/hero-banner.styles';
import { dateConverter } from '../../util/dateConverter';

const BlogHeroArticle = ({ props }) => {
  const {
    textColor,
    showOverlay,
    backgroundImageDesktop,
    backgroundImageMobile,
    backgroundPositionDesktop,
    backgroundPositionMobile,
    overlayOpacityLevel
  } = props;
  const { title, date, image } = props?.builderState?.content?.data || {};
  const authorInfo = {};
  const desktopImage = backgroundImageDesktop ? backgroundImageDesktop : image;
  const mobileImage = backgroundImageMobile ? backgroundImageMobile : backgroundImageDesktop ? backgroundImageDesktop : image;

  if (props?.builderState?.content?.data?.author?.value?.data) {
    const authorData = props.builderState.content.data.author.value.data;
    authorInfo['name'] = authorData.name;
    authorInfo['slug'] = authorData.slug;
  }
  return (
    title && (
      <HeroContainer>
        <HeroContentContainer>
          <HeroContentWrapper>
            <HeroTitle textColor={textColor}>{title}</HeroTitle>
            {authorInfo?.name && date && authorInfo?.slug && (
              <AuthorDateWrapper>
                <DateText className='date-text' textColor={textColor}>{dateConverter(date)}</DateText>
                <AuthorLink className='author-link' textColor={textColor} href={`/blog/author/${authorInfo?.slug}`}>
                  {authorInfo?.name}
                </AuthorLink>
              </AuthorDateWrapper>
            )}
          </HeroContentWrapper>
        </HeroContentContainer>
        {(desktopImage || mobileImage) && (
          <>
            <BackgroundImageContainer
              desktopImage={desktopImage}
              mobileImage={mobileImage}
              backgroundPositionDesktop={backgroundPositionDesktop}
              backgroundPositionMobile={backgroundPositionMobile}
            />
            {showOverlay && <Overlay opacity={overlayOpacityLevel} />}
          </>
        )}
      </HeroContainer>
    )
  );
};

export default BlogHeroArticle;
