const COLORS = {
  magic: {
    dark: '#0A3036',
    mid: '#0F4B55',
    base: '#26B8CE',
    lighter: '#51C6D8',
    lightest: '#A6E1EB',
    buttonBase: '#1E93A5'
  },
  maple: {
    dark: '#423C35',
    mid: '#665E53',
    base: '#F8E5C7',
    lighter: '#FAECD5',
    lightest: '#FDF5E9'
  },
  sunburst: {
    dark: '#423C1F',
    mid: '#685E31',
    base: '#FFE56E',
    lighter: '#FFEB92',
    lightest: '#FFF5C7'
  },
  macaroon: {
    dark: '#422A28',
    mid: '#68423E',
    base: '#FFA195',
    lighter: '#FFB5AD',
    lightest: '#FFD9D5'
  },
  plasma: {
    dark: '#22072D',
    mid: '#350B46',
    base: '#8107AC',
    lighter: '#9B48BC',
    lightest: '#CCA1DD'
  },
  midnight: {
    dark: '#000527',
    mid: '#00073D',
    base: '#000196',
    lighter: '#3341AA',
    lightest: '#979DD4'
  },
  saddle: {
    dark: '#160A07',
    mid: '#23100B',
    base: '#642417',
    lighter: '#765349',
    lightest: '#B8A6A2'
  },
  slate: {
    dark: '#050607',
    mid: '#08090B',
    base: '#12151A',
    lighter: '#424448',
    lightest: '#9E9FA1'
  },
  backgrounds: {
    gray: '#F5F5F5',
    none: 'transparent',
  },
  white: '#FFFFFF',
  black: '#000000',
}

// Creates a flattened array from the keys in COLORS
export const allowedColors = Object.keys(COLORS).map(key => {
  if (key === 'white' || key === 'black') return key
  return Object.keys(COLORS[key]).map(subKey => `${key}.${subKey}`)
}).flat()

export default COLORS
