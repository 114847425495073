import { useCallback, useEffect, useRef, useState } from 'react';

// Shamelessly nicked from Dan Abramov:
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
// Adjusted with toggle and reset functionality.

function useInterval(callback, delay) {
  const savedCallback = useRef();
  const interval = useRef();
  const [currentDelay, setDelay] = useState(delay);

  // Start or stop an interval from happening.
  const toggle = useCallback(
    () => setDelay(_currentDelay => (_currentDelay === null ? delay : null)),
    [delay]
  );

  const tick = useCallback(() => {
    savedCallback.current();
  }, [savedCallback]);

  // Function to allow resetting our interval.
  const reset = useCallback(() => {
    if (interval.current) {
      clearInterval(interval.current);
    }
    if (currentDelay) {
      interval.current = setInterval(tick, delay);
    }
  }, [interval, tick, delay, currentDelay]);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    reset();
    return () => { clearInterval(interval.current) };
  }, [currentDelay, reset, delay, tick]);

  return { toggle, reset }
}

export default useInterval