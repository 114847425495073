import get from 'lodash/get'
import map from 'lodash/map'
import pickBy from 'lodash/pickBy'
import PropTypes from 'prop-types';
import { stringSubstitution } from '../components/contentful-views/util';

/**
 * The Model for a contentful Page Metadata.
 * https://app.contentful.com/spaces/ljtyf78xujn2/content_types/page/fields
 */
export const contentfulMetadataPropType = {
  fields: PropTypes.shape({
    pageTitle: PropTypes.string,
    shareTitle: PropTypes.string,
    description: PropTypes.string,
    primaryImage: PropTypes.shape({}),
    openGraphType: PropTypes.string,
    twitterCard: PropTypes.string,
    twitterSite: PropTypes.string,
    additionalMetadata: PropTypes.arrayOf({}),
    jsonLd: PropTypes.shape({}),
  }),
};

/**
 * Glowforge-internal representation of Page Metadata. This may be obtained by
 * passing a `contentfulMetadataPropType` shape into `interpretMetadataFields`.
 */
export const interpretedMetadataPropType = {
  pageTitle: PropTypes.string,
  shareTitle: PropTypes.string,
  description: PropTypes.string,
  primaryImage: PropTypes.shape({
    url: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  openGraphType: PropTypes.string,
  twitterCard: PropTypes.string,
  twitterSite: PropTypes.string,
  additionalMetadata: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    property: PropTypes.string,
    content: PropTypes.string,
    httpEquiv: PropTypes.string,
  })),
  pageTitleText: PropTypes.string, // Deprecated metadata from parent Page object
  metaDescription: PropTypes.string, // Deprecated metadata from parent Page object
  jsonLd: PropTypes.object,
};

/**
 * Helpers for retrieving content from an object that adheres to the 
 * 'pageMetadataPropType' model.
 */

const ensureProtocol = url => (url && url.match(/^\/\/\w/) ? `https:${url}` : url);

const getImageUrl = (image = {}) => ensureProtocol(get(image, ['fields', 'file', 'url']));

const getImageWidth = (image = {}) => (
  get(image, ['fields', 'file', 'details', 'image', 'width'])
);

const getImageHeight = (image = {}) => (
  get(image, ['fields', 'file', 'details', 'image', 'height'])
);

const getAdditionalMetadata = (metadata = {}) => (
  map(metadata.additionalMetadata, rawMetadata => get(rawMetadata, 'fields'))
);

const interpretImage = contentfulImage => (
  pickBy({
    url: getImageUrl(contentfulImage),
    width: getImageWidth(contentfulImage),
    height: getImageHeight(contentfulImage),
  }, value => !!value)
);

/**
 * Interprets Page Metadata model from Contentful into a more usable internal
 * representation (modeled as `interpretedMetadataPropType`.
 *
 * @param contentfulMetadataFields - a contentfulMetadataPropType shape
 * @returns {interpretedMetadataPropType shape}
 */
export const interpretMetadata = ({ fields = {} } = {}, macros = {}) => (
  pickBy({
    pageTitle: stringSubstitution(fields.pageTitle, macros),
    shareTitle: stringSubstitution(fields.shareTitle, macros),
    description: stringSubstitution(fields.description, macros),
    primaryImage: interpretImage(fields.primaryImage),
    twitterCard: fields.twitterCard,
    twitterSite: fields.twitterSite,
    additionalMetadata: getAdditionalMetadata(fields),
    jsonLd: fields.jsonLd,
  }, value => !!value)
);
