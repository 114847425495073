import styled from 'styled-components';
import css from '@styled-system/css';
import { m } from 'framer-motion';

import UnstyledHR from '../hr';
import { getTypographyVariant } from '../typography';
import { GridContainer as UnstyledGridContainer } from '../grid-container';

export const GridContainer = styled(UnstyledGridContainer)`
  ${(props) =>
    css({
      backgroundColor: props.backgroundColor,
    })};
  padding-top: ${(props) => (props.variant === 'body' ? '0px' : '64px')};
`;

export const HR = styled(UnstyledHR)`
  grid-column: full-start / full-end;
  width: 100%;
  &:first-of-type {
    display: none;
  }

  @media ${(props) => props.theme.queries.desktopAndUp} {
    grid-column: main-start / main-end;
    &:first-of-type {
      display: block;
    }
  }
`;

export const QuestionWrapper = styled('button')`
  /* Unset user agent button styles */
  border: none;
  background: transparent;
  color: ${props => props.theme.colors.slate.base};
  padding-top: 24px;
  padding-bottom: 24px;
  cursor: pointer;
  grid-column: main-start / main-end;
  display: grid;
  align-items: center;
  justify-items: start;
  grid-template-columns: 1fr 15px;
  grid-column-gap: 10px;
  text-align: start;
  ${getTypographyVariant('subheading')}

  @media ${(props) => props.theme.queries.desktopAndUp} {
    grid-column: col-start 1 / col-end 11;
    padding-left: 48px;
  }
`;

export const AnswerWrapper = styled(m.div)`
  overflow: hidden;
  grid-column: main-start / main-end;

  @media ${(props) => props.theme.queries.desktopAndUp} {
    grid-column: col-start 2 / col-end 11;
  }
`;

export const ButtonWrapper = styled('div')`
  grid-column: main-start / main-end;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 24px;
  padding-bottom: 52px;

  @media ${(props) => props.theme.queries.desktopAndUp} {
    align-items: center;
    padding-top: 62px;
    padding-bottom: 67px;
  }
`;
