import { LoadMoreBtn, DotsContainer } from '../blog-articles-filtered.styles';
const LoadMoreButton = ({ hasMore, isLoading, loadMore }) => {
  return (
    <LoadMoreBtn hasMore={hasMore} className="load-more-btn" onClick={loadMore}>
      {isLoading ? (
        <DotsContainer className='dots-contanier'>
          Loading
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </DotsContainer>
      ) : (
        'Load More'
      )}
    </LoadMoreBtn>
  );
};

export default LoadMoreButton;
