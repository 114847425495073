const CountryCodes = {
  US: 'US',
  GB: 'GB'
};

const StateCodes = {
  unknown: 'unknown',
};

const CloudFrontHeaders = {
  COUNTRY_CODE: 'cloudfront-viewer-country',
  STATE_CODE: 'cloudfront-viewer-country-region',
};


const REACT_ROOT = '#__next';

// Load Balancer test general key
const gfABCKey = 'gf_abc'
// August 2021 rebrand experiment values
const gfStyledExperimentCookieKey = 'gfs21';
const gfLegacyCookieValue = `${gfStyledExperimentCookieKey}-00`;
const gfStyledCookieValue = `${gfStyledExperimentCookieKey}-01`;

module.exports = {
  CloudFrontHeaders,
  CountryCodes,
  StateCodes,
  gfABCKey,
  gfStyledExperimentCookieKey,
  gfLegacyCookieValue,
  gfStyledCookieValue,
  REACT_ROOT,
}