import React from 'react';
import PropTypes from 'prop-types';
import { withChildren } from '@builder.io/react/lite';
import CardComponent from './card.styles';

export const Card = ({
  backgroundColor,
  children,
  overflowHidden,
  variant,
  hoverPointer,
}) => (
    <CardComponent
      backgroundColor={backgroundColor}
      hoverPointer={hoverPointer}
      overflowHidden={overflowHidden}
      variant={variant}>
      {children}
    </CardComponent>
  );

Card.propTypes = {
  children: PropTypes.node,
  ...CardComponent.propTypes
};

Card.defaultProps = {
  ...CardComponent.defaultProps,
};

const BuilderCard = withChildren(Card);
export default BuilderCard;
