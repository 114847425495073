import styled from 'styled-components';

// Note, we can pull font sizes from theme context, but I want this to have
// more of a control from design/UI/UX since it is relatively standalone
// (Especially in context to font size and the impact it has).
// Going to review this with Adrian once its up and we might "choose" a
// none official font size.

// Wrapper/Container padding
const WRAPPER_PADDING = '1.8rem';
const WRAPPER_PADDING_MOBILE = '1.25rem';
// Main font size
const MAIN_FONT_SIZE = '1.7rem';
// Main font size mobile
const MAIN_FONT_SIZE_MOBILE = '1rem';
// Over sized font size
const OVER_SIZED_FONT_SIZE = '2.5rem';
// Over sized font size mobile
const OVER_SIZED_FONT_SIZE_MOBILE = '1.5rem';

export const Wrapper = styled('div')`
  font-family: 'Space Grotesk', sans-serif;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 10px 15px #00000033;
  max-width: 800px;
  @media ${(props) => props.theme.queries.desktopDown} {
    box-shadow: none;
    border-radius: unset;
  }
`;

export const OptionsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: ${WRAPPER_PADDING};
  @media ${(props) => props.theme.queries.desktopDown} {
    padding: ${WRAPPER_PADDING_MOBILE};
  }
`;

export const SelectContainer = styled('div')`
  flex-direction: column;
  margin-bottom: 10px;
  @media ${(props) => props.theme.queries.desktopDown} {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const Text = styled('div')`
  display: inline-block;
  font-size: ${MAIN_FONT_SIZE};
  @media ${(props) => props.theme.queries.desktopDown} {
    font-size: ${MAIN_FONT_SIZE_MOBILE};
  }
`;

export const BottomWrapper = styled('div')`
  background-color: ${(props) => props.theme.colors.magic.base};
`;

export const SubmitContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 160px;
  padding: ${WRAPPER_PADDING};
  @media ${(props) => props.theme.queries.desktopDown} {
    padding: ${WRAPPER_PADDING_MOBILE};
  }
`;

export const CalculateButton = styled('button')`
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.magic.base};
  background: white;
  padding: 8px 12px;
  border-radius: 25px;
  box-shadow: none;
  border: none;
  @media ${(props) => props.theme.queries.desktopDown} {
    font-size: ${MAIN_FONT_SIZE_MOBILE};
  }
`;

export const EarningsContainer = styled('div')`
  align-self: end;
  background-color: ${(props) => props.theme.colors.magic.base};
  color: white;
  justify-content: space-evenly;
  align-items: center;
  min-height: 120px;
  padding: ${WRAPPER_PADDING};
  @media ${(props) => props.theme.queries.desktopDown} {
    padding: ${WRAPPER_PADDING_MOBILE};
  }
`;

export const EarningsText = styled('p')`
  font-size: 1.5rem;
  @media ${(props) => props.theme.queries.desktopDown} {
    font-size: 1rem;
  }
`;

export const EarningsAmountText = styled('span')`
  font-size: ${OVER_SIZED_FONT_SIZE};
  padding-left: 10px;
  padding-right: 10px;
  @media ${(props) => props.theme.queries.desktopDown} {
    font-size: ${OVER_SIZED_FONT_SIZE_MOBILE};
    padding-left: 2px;
    padding-right: 2px;
  }
`;

export const Select = styled('select')`
  color: ${(props) => props.theme.colors.magic.base};
  font-size: ${MAIN_FONT_SIZE};
  margin-left: 10px;
  margin-right: 10px;
  border: none;
  border-width: 0;
  border-bottom: 1px dashed black;
  
  /*
  Below allows for a standard way of styling native select across devices.
  Taken from this SO answer https://stackoverflow.com/a/57510283
  */
  /* Reset border-radius */
  border-radius: 0;
  /* styled-components applies vendor prefixes automatically */
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%2326B8CE'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
  background-size: 12px;
  /* Visually center the down arrow */
  background-position: 100% 70%;
  /* Right padding accounts for 12px wide arrow. */
  padding-right: 16px;
  background-color: ${(props) => props.theme.colors.white};
  background-repeat: no-repeat;
  cursor: pointer;

  @media ${(props) => props.theme.queries.desktopDown} {
    font-size: ${MAIN_FONT_SIZE_MOBILE};
    margin-left: 5px;
    margin-right: 5px;
  }
`;

export const Option = styled('option')`
  color: ${(props) => props.theme.colors.magic.base};
`;

export default {
  Wrapper,
  OptionsWrapper,
  BottomWrapper,
  Select,
  Option,
};
