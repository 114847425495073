// TODO: Update to newer CSS reset.

import { css } from 'styled-components'

const cssReset = css`
/* 
  Pulled and modified from https://piccalil.li/blog/a-modern-css-reset/
*/

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: "Exo 2", sans-serif;
  color: ${props => props.theme.colors.slate.base};
}

/* Get rid of all user agent margins */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin:0;
  padding: 0;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* 
  Force a role if we want to style our own lists. 
  This list-style rule requires opt-in for accesibility reasons:
  https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html
*/
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* 
  Blockquotes and quotes will be styled individually.
  Once their Components are added in, we can potentially remove this.
*/
blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content:'';
  content:none;
}

/* Inherit fonts for inputs and buttons */
input, textarea, keygen, select, button {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
`

export default cssReset
