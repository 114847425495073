// @ts-check
import React from 'react';
import {
  widgetDefaultProps,
  widgetPropType,
} from '@glowforge/gf-styled/src/models/widget';

import ExploreFeatures from './explore-features';

const ExploreFeaturesWidget = ({ features, attributes, selectFeatures, dataTestId }) => (
  <ExploreFeatures
    features={features}
    attributes={attributes}
    selectFeatures={selectFeatures}
    dataTestId={dataTestId}
  />
);

ExploreFeaturesWidget.propTypes = { ...widgetPropType };

ExploreFeaturesWidget.defaultProps = { ...widgetDefaultProps };

export default ExploreFeaturesWidget;
