import React from 'react';
import PropTypes from 'prop-types';
import { Builder } from '@builder.io/react/lite';
import styled from 'styled-components';

const ErrorWrapper = styled('div')`
  background: red;
  padding: 5px 10px;
  color: white;
`

/**
 * EditorError - Shows errors only during editor mode
 * @param {*} children Child nodes 
 * @returns 
 */
const EditorError = ({children}) => {
  if(Builder.isEditing) {
    return (
      <ErrorWrapper>
        <h3>Editor Mode Error:</h3>
        {children}
      </ErrorWrapper>
    )
  }
  return null
}

EditorError.propTypes = {
  children: PropTypes.node,
}

EditorError.defaultProps = {
  children: null,
}

export default EditorError