import styled from 'styled-components';

import { BaseTypographyComponent, getTypographyVariant } from '../../typography'
import Link from '../../link'

import { NavLink as UnstyledNavLink } from '../shared/top-nav.styles'

const NavLink = styled(UnstyledNavLink)`
  display: flex;
  align-items: center;
  padding-bottom: 12px;
`

const SVGWrapper = styled('div')`
  display: inline-flex;
  margin-right: 8px;
`

const SignInLink = styled(Link)`
  display: 'block';
  transition: opacity 0.25s ease-in-out;
  opacity: ${props => props.isPending ? 0 : 1};
`

const Wrapper = styled('div')`
display: none;
@media ${props => props.theme.queries.navbarBreakpoint} {
  padding-left: 24px;
  min-width: fit-content;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
`

const UserLinksWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  /* TODO: Integrate with spacing sizes (GROW-117) */
  padding: 24px 21px 12px 12px;
  width: fit-content;
  min-width: 250px;

  & >:not(:first-child) {
    padding-top: 18px;
  }
  ul {
    list-style: none;
  }
`

const UserButton = styled(BaseTypographyComponent)`
  /* increase the click size of the name to be more accessible. */
  ${getTypographyVariant('subheading')}
  --font-size-mim: 1rem;
  --font-size-max: 1rem;
  --letter-spacing: -0.4px;
  --font-weight: 500;
  white-space: pre;
  &:after {
    content: '';
    position: absolute;
    top: -12px;
    left: -12px;
    right: -12px;
    height: 44px;
  }
  display: flex;
  align-items: center;

  svg {
    margin-left: 8px;

    animation: ${props => props.popoverOpen ? `open 0.5s` : `none`};
    animation-fill-mode: forwards;

    @keyframes open {
      100% {
        transform: rotate(180deg);
      }
    }
  }
`

export {
  NavLink,
  SignInLink,
  SVGWrapper,
  UserButton,
  UserLinksWrapper,
  Wrapper,
}
