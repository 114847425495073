import React, { useEffect, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';

import { shareProgramActions, shareProgramReducer } from './shareProgramReducer';
import ShareProgramContext from './ShareProgramContext';
import { getCookie } from '../../util/cookies';

export default function ShareProgramProvider({ children }) {

  const [state, dispatch] = useReducer(shareProgramReducer, {
    referralInfo: {},
    affiliateInfo: {},
    fetching: true,
  });

  /**
   * FriendBuy data comes from their API that we load in via a
   * third party script. We need to know when the script has
   * finished loading before we try to push further actions to
   * it's window object.
   */
  useEffect(() => {
    // Add a call to get the visitor status so we can get advocate data
    const getFBAdvocateOnLoad = () => {

      const fbAPI = window.friendbuyAPI;
      fbAPI.push(["getVisitorStatus", (status) => {
        if (status.payload.advocate) {
          const { advocate } = status.payload;

          const fbUrlParameters = {
            fbuy: `${new URLSearchParams(window.location.search).get('fbuy')}`,
            fbshare: `${new URLSearchParams(window.location.search).get('fbshare')}`,
            referrerId: advocate.customerId ? `${advocate.customerId}`: null,
          };

          dispatch({ 
            type: shareProgramActions.API_INITIALIZE, 
            payload: {
              code: new URLSearchParams(window.location.search).get('referralCode'),
              name: advocate.firstName,
              parameters: fbUrlParameters,
            },
          });
        }
      },]);
    }

    // Listening for 3rd party script load from Segment, does not
    // dispatch event we could listen for instead.
    const pollFriendbuy = (remainingTries) => {
      if (window.friendbuyAPI?.loaded) {
        getFBAdvocateOnLoad();
      } else if (remainingTries > 0) {
        setTimeout(() => pollFriendbuy(remainingTries - 1), 200);
      }
    }

    const existingReferral = getCookie('referral');
    if (existingReferral && existingReferral.includes('referrerId')) {
      dispatch({
        type: shareProgramActions.GET_REFERRAL_COOKIE,
        payload: existingReferral,
      });
      return;
    }
    dispatch({
      type: shareProgramActions.API_INITIALIZE,
      payload: {
        checkAffiliate: true,
      },
    });

    // We have a high number because sometimes the Friendbuy API
    // can be very slow to load.
    pollFriendbuy(25);
    
  }, []);
  
  // Referrals and affiliates have to stay separate for builder logic.
  return (
    <ShareProgramContext.Provider
      value={useMemo(() => ({
        referralInfo: state.referralInfo,
        affiliateInfo: state.fetching ? undefined : state.affiliateInfo,
      }), [state])}>
      {children}
    </ShareProgramContext.Provider>
  )
}

ShareProgramProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
