import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';
import css from '@styled-system/css';
import ExternalSVG from './external-svg';
import SVGS from './svgs';

const DefaultSVGWrapper = styled('div')`
  display: flex;

  svg {
    ${(props) =>
      css({
        fill: props.$fill,
        stroke: props.$stroke,
      })}
  }
`;

DefaultSVGWrapper.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
};

/**
 * The SVG component leverages the webpack config
 * found in index.js that runs svg-react-loader on all svgs in
 * the svg folder (`gf-styled/src/svg`)
 *
 * ## Usage
 * ### For core Icon loaded in UI common, pass the id associated:
 *
 * `<SVG id='<icon file name>' />`
 *
 * `<SVG id='checkmark' />`
 *
 * ### For externally hosted svgs:
 *
 * `<SVG svgAsset={contentfulIconObject} />`
 *
 * `<SVG svgAsset={props.globalFooterLogo} />`
 *
 * ### Dimensions
 * The default width/height is 24px/24px. To change this, pass a string
 * as a prop for either prop value. These are applied directly to SVG.
 *
 * #### Unset
 * To unset a width/height - for example, if you wish to make the width
 * responsive to the height - pass a percentage length value
 *
 * ### Classes
 * The `className` prop is passed to `<svg/>` element directly. It is also
 * used for the wrapper class but with `-wrapper` appended.
 */
const SVG = ({
  id,
  className,
  onClick,
  svgAsset,
  width,
  height,
  noWidth,
  noHeight,
  fill,
  stroke,
  SVGWrapper = DefaultSVGWrapper,
}) => {
  const svgClassName = classnames('icon', id, className, { hasClick: onClick });
  const wrapperClassName = classnames(`${className}-wrapper`);

  const svgWidth = noWidth ? undefined : width;
  const svgHeight = noHeight ? undefined : height;

  if (id && id in SVGS) {
    let Icon;
    try {
      Icon = SVGS[id];
      return (
        <SVGWrapper
          className={wrapperClassName}
          onClick={onClick}
          $fill={fill}
          $stroke={stroke}>
          <Icon width={svgWidth} height={svgHeight} className={svgClassName} />
        </SVGWrapper>
      );
    } catch (e) {
      console.error(`Icon with ${id} does not exist.`);
      console.error(e);
    }
  }

  /**
   * Supports contentful file asset, or a url string.
   */
  const url = String(svgAsset?.fields?.file?.url ?? svgAsset);

  return (
    <ExternalSVG
      className={svgClassName}
      Wrapper={SVGWrapper}
      wrapperClass={wrapperClassName}
      onClick={onClick}
      url={url}
      width={svgWidth}
      height={svgHeight}
    />
  );
};

SVG.propTypes = {
  id: PropTypes.string,
  svgAsset: PropTypes.oneOfType([
    PropTypes.shape({
      fields: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
    PropTypes.string,
  ]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  noWidth: PropTypes.bool,
  noHeight: PropTypes.bool,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  SVGWrapper: PropTypes.elementType,
};

SVG.defaultProps = {
  id: null,
  svgAsset: null,
  className: 'default',
  onClick: null,
  width: 24,
  height: 24,
  noWidth: false,
  noHeight: false,
  fill: 'magic.base',
  stroke: 'magic.base',
  SVGWrapper: DefaultSVGWrapper,
};

export default SVG;
