import styled from 'styled-components';
import {
    Heading,
    Paragraph
} from '@glowforge/gf-styled/src/components/typography';

const ArticleWrapper = styled('div')`   
  width: ${props => props.isInCarousel ? "320px" : '100%'};
  max-width: ${props => props.isInCarousel ? '100%' : '320px'};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  justify-content: flex-start;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0.25rem 0.9375rem #00000033;
  background-color: #FFF;
  justify-self: center;
  ${props => props.isInCarousel && `
    margin-left: 20px;
    
    &:first-child {
      margin-left: 0;
    }
  `}

  .blog-article-read-more-btn{
    font-size: 15px;
  }
  
`;

const ImgContainer = styled('div')`
    width: 100%;
    height: auto;
    img,
    picture {
      width: 100%;
      height: auto;
      height: 200px;
      object-fit: cover; 
      object-position: center; 
   }
`
const CategoriesWrapper = styled('div')`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`

const CategoryWrapper = styled('div')`
    display: flex;
`

const ArticleHeading = styled(Heading)`
      font-size: 22px;
      min-height: 53px;
`

const ArticleSumarry = styled(Paragraph)`
    font-size: 15px;
    line-height: 120%;
`

const ContentContainer = styled('div')`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 15px;
    padding: 15px;
`

const ReadMoreButton = styled('button')`
    padding: 18px 6px;
    background-color: #1E93A5;
    color: #fff;
    width: fit-content;
    padding: 0.375rem 1.125rem;
    white-space: nowrap;
    border: 0;
    border-radius: 10em;
    display: flex;
    column-gap: 0.625rem;
    cursor: pointer;
    &:after {
      content: "";
      display: inline-block;
      width: 19px; 
      height: 19px; 
      background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22far%22%20data-icon%3D%22long-arrow-right%22%20class%3D%22svg-inline--fa%20fa-long-arrow-right%20fa-w-14%22%20role%3D%22img%22%20viewBox%3D%220%200%20448%20512%22%20height%3D%2224%22%20width%3D%2224%22%3E%3Cpath%20fill%3D%22white%22%20d%3D%22M295.515%20115.716l-19.626%2019.626c-4.753%204.753-4.675%2012.484.173%2017.14L356.78%20230H12c-6.627%200-12%205.373-12%2012v28c0%206.627%205.373%2012%2012%2012h344.78l-80.717%2077.518c-4.849%204.656-4.927%2012.387-.173%2017.14l19.626%2019.626c4.686%204.686%2012.284%204.686%2016.971%200l131.799-131.799c4.686-4.686%204.686-12.284%200-16.971L312.485%20115.716c-4.686-4.686-12.284-4.686-16.97%200z%22%2F%3E%3C%2Fsvg%3E');
      background-repeat: no-repeat;
      background-position: center;
      align-self: center;
   }

   &:hover {
      background-color: #26B8CE;
      box-shadow: 0 0.625rem 0.9375rem #00000033;
    }
`

const DateAuthorWrapper = styled('div')`
    display: flex;
`

const GreyText = styled(Paragraph)`
    color: #777;
    font-size: 13px;
     ${props => props.isAuthor && `
        display: flex;
        align-items: center;
         &:hover {
            color: inherit;
            &:before {
                color: #777; /* Keep the ::before content color unchanged on hover */
            }
        }
        &:before {
        content: "|";
        display: inline-block;
        margin: 0 10px;
        font-size: 11px;
        }
    `};
    @media ${(props) => props.theme.queries.desktopDown} {
        font-size: 14px;
    }
`
const CardInfoWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
`

const CategoriesText = styled('p')`
    font-size: 13px;
    white-space: break-spaces;
`

export {
    ArticleHeading,
    ArticleSumarry,
    ArticleWrapper,
    ImgContainer,
    ContentContainer,
    ReadMoreButton,
    DateAuthorWrapper,
    GreyText,
    CategoriesWrapper,
    CategoryWrapper,
    CardInfoWrapper,
    CategoriesText
};
