import { useEffect, useRef } from 'react';

/**
 *
 * @param {function} [handler = (e) => {}]
 * @param {*} dependencies
 * @returns
 */
const useOutsideClick = (handler = () => {}, dependencies = [], ref) => {
  // We use a ref to hold onto our handler.
  const callbackRef = useRef(handler);

  /* 
    Update ref if handler changes.
    Because we're using a ref, we don't need to update the EventListener.
  */
  useEffect(() => {
    callbackRef.current = handler;
  }, [handler]);

  useEffect(() => {
    const outsideClickHandler = (e) => {
      // We check if we have an event handler and a target ref set
      // before running event handler.
      if (
        callbackRef.current &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        callbackRef.current(e);
      }
    };

    // is intended to have sideffect
    // eslint-disable-next-line no-unused-expressions
    document?.addEventListener('click', outsideClickHandler);

    return () => {
      // eslint-disable-next-line no-unused-expressions
      document?.removeEventListener('click', outsideClickHandler);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackRef, ref, ...dependencies]);
};

export default useOutsideClick;
