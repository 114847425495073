import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SVG from '../../svg/svg';
import { useOutsideClick } from '../../../hooks';
import VisuallyHidden from '../../visually-hidden/visually-hidden';

import { Nav, NavSection, NavUL } from '../shared/top-nav.styles';

import NavLinks from '../shared/nav-links';
import MobileUserNav from './mobile-user-nav-section';

const NavDropdown = styled('div')`
  /* We want to place the mobile nav items below the Navbar. */
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: -1; // pull this below the dropshadow from the button in the navbar.
  // We are no longer letting the navbar color our background.
  background: ${(props) => props.theme.colors.white};

  // Animate mobile dropdown
  // NB: CSS can't animate height auto, so we provide a % for max height.
  // We want more than 100% so that we don't cut off our padding by accident.
  // Maybe we could use framer motion for this, so we don't have to overshoot.
  height: auto;
  max-height: ${(props) => (props.$isExpanded ? '100vh' : '0')};
  // in case of overflow, allow for scroll. Set 20px bottom padding so has space to scroll
  overflow-y: scroll;
  padding-bottom: ${(props) => (props.$isExpanded ? '20px' : '0')};
  transition: max-height 0.25s ease-in-out, padding 0.25s ease-in-out;
  // TODO: get new box-shadow design tokens (GROW-126).
  box-shadow: 1px 10px 11px -8px rgb(0 31 35 / 15%);
`;

/* We might want to extract this to a reusable wrapper */
const TouchArea = styled('div')`
  min-height: 44px;
  min-width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MobileNav = ({
  buttonSection,
  routes,
  user,
  userFetchState,
  activeLink,
  logoLink,
}) => {
  const { primaryRoutes, userRoutes } = routes;

  const logoHref =
    logoLink || primaryRoutes.find((route) => route.logoLink === true)?.url;

  const [isExpanded, setExpanded] = useState(false);

  const toggleMenu = (e) => {
    e.stopPropagation();
    setExpanded((prev) => !prev)
  };
  const closeMenu = useCallback(() => {
    if (isExpanded) setExpanded(false);
  }, [isExpanded]);

  const ref = useRef(null);
  useOutsideClick(closeMenu, [], ref);

  return (
    <Nav aria-labelledby='nav-mobile-label'>
      <VisuallyHidden id='nav-mobile-label'>Main Menu</VisuallyHidden>
      <NavSection>
        <TouchArea
          as='a'
          href={logoHref}
          tabIndex='0' // for some reason, screen readers aren't picking up this anchor tag.
          aria-label='Home Link'>
          {/* Design has 37px high - unset width */}
          <SVG id='glowforge-icon' height='37px' width={null} />
        </TouchArea>
      </NavSection>

      <NavSection>{buttonSection}</NavSection>

      <NavSection>
        <TouchArea
          aria-label='Show Navbar Links'
          onClick={toggleMenu}
          role='button'
          tabIndex='0'
          aria-controls='mobile-nav-items'>
          {isExpanded ? (
            <SVG className='menuButton' id='close' height='30px' width={null} />
          ) : (
            <SVG className='menuButton' id='menu' height='37px' width={null} />
          )}
        </TouchArea>
        <NavDropdown ref={ref} $isExpanded={isExpanded}>
          <NavUL role='list' aria-hidden={!isExpanded} id='mobile-nav-items'>
            <NavLinks
              activeLink={activeLink}
              routes={primaryRoutes.filter((route) => !route.logoLink)}
              onMobile
              keyboardNav={isExpanded}
            />
            <MobileUserNav
              user={user}
              userFetchState={userFetchState}
              routes={userRoutes}
            />
          </NavUL>
        </NavDropdown>
      </NavSection>
    </Nav>
  );
};

MobileNav.propTypes = {
  activeLink: PropTypes.string,
  buttonSection: PropTypes.node,
  routes: PropTypes.shape({
    primaryRoutes: PropTypes.arrayOf(PropTypes.shape({})),
    userRoutes: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  user: PropTypes.shape({}),
  logoLink: PropTypes.string,
  userFetchState: PropTypes.string,
};

MobileNav.defaultProps = {
  activeLink: '',
  buttonSection: null,
  routes: {
    primaryRoutes: [],
    userRoutes: [],
  },
  user: {},
  userFetchState: 'pending',
  logoLink: '/',
};

export default MobileNav;
