
const artboardConfig = {
    name: 'Glowforge:Magic Canvas',
    inputs: [
        {
            name: 'views',
            type: 'list',
            defaultValue: [
                {
                    backButton: 'Try something else',
                    button: 'Remix'
                },
            ],
            subFields: [
                {
                    name: 'backButton',
                    type: 'string',
                    defaultValue: 'Select new options',
                },
                {
                    name: 'button',
                    type: 'string',
                    defaultValue: 'Show',
                },
                {
                    name: 'content',
                    type: 'uiBlocks',
                    defaultValue: [],
                },
            ]
        },
    ],
    image: 'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fac5a9d1ebe924e71b278c7cca6c8b804'
}

export default artboardConfig;
