import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, useReducedMotion } from 'framer-motion';

import Markdown from '../markdown';

import AnimatedPlus from '../animated/plus';

import {
  AnswerWrapper,
  ButtonWrapper,
  GridContainer,
  HR,
  QuestionWrapper,
} from '../accordion/accordion.styles';

export const QuestionAnswer = ({
  question,
  answer,
  active,
  shouldReduceMotion,
  selectItem,
}) => (
  <React.Fragment key={question}>
    <HR />
    <QuestionWrapper
      onClick={() => selectItem(question)}
      aria-controls={`${question}-answer`}
      aria-expanded={active}
      id={`${question}-question`}
      role='button'>
      {question}
      <AnimatedPlus active={active} />
    </QuestionWrapper>
    <AnimatePresence>
      {active ? (
        <AnswerWrapper
          initial={{ height: 0 }}
          animate={{ height: 'auto' }}
          exit={{ height: 0 }}
          transition={
            shouldReduceMotion
              ? { duration: 0 }
              : {
                  stiffness: 150,
                }
          }
          id={`${question}-answer`}
          aria-labelledby={`${question}-question`}>
          {answer}
        </AnswerWrapper>
      ) : null}
    </AnimatePresence>
    <noscript>
      {/* When script disabled, content is visible */}
      <AnswerWrapper
        id={`${question}-answer`}
        aria-labelledby={`${question}-question`}>
        {answer}
      </AnswerWrapper>
    </noscript>
  </React.Fragment>
);

QuestionAnswer.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  active: PropTypes.bool,
  shouldReduceMotion: PropTypes.bool,
  selectItem: PropTypes.func,
};

QuestionAnswer.defaultProps = {
  active: false,
  shouldReduceMotion: false,
  selectItem: () => {},
}

const FAQ = ({
  items = [],
  selectMultiple = true,
  button,
  backgroundColor,
  className,
  variant,
  openAll,
  ...rest
}) => {
  // openAll is a utility used by pages/b/faq-preview for editing purposes.
  const initialState = openAll ? items.map(({ question }) => question) : [];
  const [selected, setSelected] = useState(initialState);
  const shouldReduceMotion = useReducedMotion();

  const selectItem = useCallback(
    (item) => {
      if (selectMultiple) {
        setSelected((prevItems) => {
          if (prevItems.includes(item)) {
            return prevItems.filter((prevItem) => prevItem !== item);
          }
          return [...prevItems, item];
        });
      } else {
        // We don't want to allow multiple items, so make this one the only selected item.
        setSelected((prevItems) => (prevItems[0] === item ? [] : [item]));
      }
    },
    [items, selectMultiple, selected]
  );

  return (
    <GridContainer
      variant={variant}
      className={`FAQ-Section ${className}`}
      backgroundColor={backgroundColor}
      {...rest}>
      {items.map(({ question, answer: propAnswer }) => {
        const active = selected.includes(question);
        const answer =
          typeof propAnswer === 'string' && propAnswer instanceof String ? (
            <Markdown source={propAnswer} />
          ) : (
            propAnswer
          );
        return (
          <QuestionAnswer
            key={question}
            question={question}
            answer={answer}
            active={active}
            shouldReduceMotion={shouldReduceMotion}
            selectItem={selectItem}
          />
        );
      })}
      {button ? <ButtonWrapper>{button}</ButtonWrapper> : null}
    </GridContainer>
  );
};

FAQ.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    })
  ).isRequired,
  selectMultiple: PropTypes.bool,
  button: PropTypes.node,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  openAll: PropTypes.bool,
};

FAQ.defaultProps = {
  selectMultiple: true,
  button: null,
  backgroundColor: '',
  className: '',
  variant: 'full',
  openAll: false,
};

export default FAQ;
