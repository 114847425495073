import styled from 'styled-components';
import PropTypes from 'prop-types';

const topNavHeight = '56px';

const getHeroHeight = (height, includeNav) => {
  switch (height) {
    case '25vh':
      return `calc(25vh - ${includeNav ? topNavHeight : '0px'})`;
    case '33vh':
      return `calc(33vh - ${includeNav ? topNavHeight : '0px'})`;
    case '50vh':
      return `calc(50vh - ${includeNav ? topNavHeight : '0px'})`;
    case '75vh':
      return `calc(75vh - ${includeNav ? topNavHeight : '0px'})`;
    case '100vh':
      return `calc(100vh - ${includeNav ? topNavHeight : '0px'})`;
    default:
      return `calc(100vh - ${includeNav ? topNavHeight : '0px'})`;
  }
};

const Wrapper = styled('div')`
  position: relative;
  width: 100%;
  height: ${(props) => getHeroHeight(props.heroHeightMobile, props.includeNav)};

  @media ${(props) => props.theme.queries.desktopAndUp} {
    height: ${(props) =>
      getHeroHeight(props.heroHeightDesktop, props.includeNav)};
  }
`;

Wrapper.propTypes = {
  heroHeightDesktop: PropTypes.string,
  heroHeightMobile: PropTypes.string,
  includeNav: PropTypes.bool,
};

Wrapper.defaultProps = {
  heroHeightDesktop: '100vh',
  heroHeightMobile: '100vh',
  includeNav: true,
};

const Background = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* Videos are not supported on mobile, so we're not going to check here. */
  ${(props) =>
    `background-image: url(${
      props.backgroundMobile || props.background
    }?w=800&fm=jpg&fl=progressive&q=70);`}
  background-position: ${(props) => props.backgroundPositionMobile};
  background-repeat: ${(props) => props.backgroundRepeatMobile};
  background-size: ${(props) => props.backgroundSizeMobile};
  overflow: hidden;
  pointer-events: none;
  /* pull behind any siblings. */
  z-index: -2;

  @media ${(props) => props.theme.queries.desktopAndUp} {
    /* Only show background if there's no video */
    ${(props) =>
      props.isVideo
        ? 'background-image: none;'
        : `background-image: url(${props.background}?fm=jpg&fl=progressive&q=70);`}
    background-position: ${(props) => `${props.backgroundPosition}`};
    background-repeat: ${(props) => props.backgroundRepeat};
    background-size: ${(props) => props.backgroundSize};
  }
`;

const Overlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) =>
    props.theme.utils.getHexOpacity(props.theme.colors.black, props.opacity)};
  pointer-events: none;
  z-index: -1;
`;

const Video = styled('video')`
  /* 
    We currently do not load video on mobile.
    We might want to reconsider this as a passed in property, so we can have mobile videos.
  */
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;

  @media ${(props) => props.theme.queries.desktopAndUp} {
    display: block;
  }
`;

const ContentWrapper = styled('div')`
  color: ${(props) => props.theme.colors.white};
  height: 100%;

  section {
    z-index: 1; /* In case of overlay, places it on top */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;

    @media ${(props) => props.theme.queries.desktopAndUp} {
      justify-content: center;
    }

    button {
      align-self: stretch;

      @media ${(props) => props.theme.queries.desktopAndUp} {
        align-self: flex-start;
      }
    }
  }
`;

export { Background, ContentWrapper, Overlay, Video, Wrapper };
