import PropTypes from 'prop-types';
import styled from 'styled-components';
import variant from '@styled-system/variant';

/**
 * @property { HTMLelement } as
 * @property {{ 'primary' | 'ghost' | 'ghost-blue' | 'yellow' }} variant
 */
 const ButtonBaseComponent = styled('button').attrs(props => ({
  className: `gf-styled__button__${props.variant}`
}))`
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: ${props => props.theme.effects.boxShadow.button};
    font-family: "Space Grotesk", sans-serif;
    color: var(--color);
    background-color: var(--background-color);
    border: 0.125rem solid var(--border-color);
    border-radius: 10em;
    padding: 0.375rem 1.125rem;
    text-decoration: unset;
    white-space: nowrap;

    // Temporary. Once SVGs are also using styled components,
    // this should be updated to reflect that pattern and remove
    // the class name.
    > .button-icon-wrapper {
      margin-left: 0.625rem;
      svg { display: flex; }
    }

    @media ${props => props.theme.queries.tabletAndUp} {
      box-shadow: none;
      justify-content: flex-start;

      &:hover {
        color: var(--color);
        box-shadow:${props => props.theme.effects.boxShadow.button};
        background-color: var(--hover-bg-color);
        border-color: var(--hover-border-color);
      }
    }

    @media ${props => props.theme.queries.desktopAndUp} {
      width: fit-content;
    }

  ${props => variant({
    variants: {
      primary: {
        '--color': props.theme.colors.white,
        '--background-color': props.theme.colors.magic.buttonBase,
        '--hover-bg-color': props.theme.colors.magic.base,
        '--border-color': props.theme.colors.magic.buttonBase,
        '--hover-border-color': props.theme.colors.magic.base,
      },
      yellow: {
        '--color': props.theme.colors.black,
        '--background-color': props.theme.colors.sunburst.base,
        '--hover-bg-color': props.theme.colors.sunburst.lighter,
        '--border-color': props.theme.colors.sunburst.base,
        '--hover-border-color': props.theme.colors.sunburst.lighter,
      },
      ghost: {
        '--color': props.theme.colors.white,
        '--background-color': 'transparent',
        '--hover-bg-color': props.theme.colors.magic.base,
        '--border-color': props.theme.colors.white,
        '--hover-border-color': props.theme.colors.magic.base,
      },
      'ghost-blue': {
        '--color': props.theme.colors.magic.base,
        '--background-color': 'transparent',
        '--hover-bg-color': 'transparent',
        '--border-color': props.theme.colors.magic.base,
        '--hover-border-color': props.theme.colors.magic.base,
      }
    }
  })}
`;

ButtonBaseComponent.propTypes = {
  variant: PropTypes.oneOf(['primary', 'ghost', 'ghost-blue', 'yellow']),
};

ButtonBaseComponent.defaultProps = {
  variant: 'primary',
};

export default ButtonBaseComponent;
