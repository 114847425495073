const getCookieValue = (cookie, key, nextKey = null) => {
  const index = cookie.indexOf(key) + key.length;
  if (nextKey) {
    return cookie.substring(index, cookie.indexOf(nextKey));
  }
  
  return cookie.substring(index);
};

const removeCookie = (name) => {
  if (typeof window !== "undefined") {
    document.cookie = `${name}=;expires=Thu, 20 Oct 1994 00:00:00;path=/;domain=.glowforge.com`;
  }
}

const getCookie = (name) => {
  if (typeof window !== "undefined") {
    const decodedCookies = decodeURI(document.cookie);
    const cookies = decodedCookies.split(';');
  
    for (let i = 0; i < cookies.length; i += 1) {
      if (cookies[i].includes(`${name}=`)) {
        return cookies[i].replace(`${name}=`, '');
      }
    }
  }

  return null;
}

/**
 * 
 * @param {string} name 
 * @param {string} value 
 * @param {number} days 
 * Lets cookie be accessible on just dotcom or to all glowforge domains
 * @param {boolean} dotcomOnly
 */
const setCookie = (name, value, days, dotcomOnly = true) => {
  if (typeof window !== "undefined") {
    const info = encodeURIComponent(value);
    const date = new Date();

    date.setTime(date.getTime() + (days*24*60*60*1000));
    const expires = date.toUTCString();
    const domain = dotcomOnly ? '' : 'domain=.glowforge.com;';
    
    document.cookie  = `${name}=${info};expires=${expires};path=/;${domain}`;
 }
}

export {
  getCookie,
  getCookieValue,
  removeCookie,
  setCookie,
 };
