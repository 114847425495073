import React from 'react';
import { CountryCodes, StateCodes } from '../../constants';

export const locationDefault = {
  country: CountryCodes.US,
  state: StateCodes.unknown,
  hasFetched: false,
};

const LocationContext = React.createContext(locationDefault);

export default LocationContext;
