import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import css from '@styled-system/css'
import { useReducedMotion } from 'framer-motion'

import GridContainer from './grid-container'

/* 
  A helper function for setting background position based on incoming string.
  This is a legacy mapping for options in the Contentful Model for background
   position.
  We may want to reconsider and split this out into position and alignment
   props.
*/
const setBackgroundPosition = (position) => {
  switch (position) {
    case 'alignTop': {
      return 'top center'
    }
    case 'alignBottom': {
      return 'bottom center'
    }
    case 'alignRight': {
      return 'right'
    }
    case 'alignRightTop': {
      return 'right top'
    }
    case 'alignLeft': {
      return 'left'
    }
    case 'alignLeftTop': {
      return 'left top'
    }
    case 'alignLeftBottom': {
      return 'left bottom'
    }
    default: {
      return 'center'
    }
  }
}

const Background = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${props => css({
    backgroundColor: props.backgroundColorMobile || 'backgrounds.gray'
  })}
  background-image: ${props => props.backgroundImageMobile
    ? (`url(${props.backgroundImageMobile}?w=800${props.resizeImage ? '&fm=jpg&fl=progressive&q=70' : ''})`) // eslint-disable-line
    : ''};
  background-position: ${
    props => 
    setBackgroundPosition(props.backgroundImagePositionMobile || props.backgroundImagePosition)};
  background-repeat: ${props => props.backgroundRepeatMobile || 'no-repeat'};
  background-size: ${props => props.backgroundSizeMobile || 'auto'};
  overflow: hidden;
  pointer-events: none;
  z-index: -1; // pull behind any siblings.

  @media ${props => props.theme.queries.desktopAndUp} {
    // Only show background-image if there is not a video present.
    background-image: ${props => (!props.videoPresent && props.backgroundImage)
      ? `url(${props.backgroundImage}${props.resizeImage ? '?fm=jpg&fl=progressive&q=70' : ''})`
      : 'none'};
    background-position: ${props => setBackgroundPosition(props.backgroundImagePosition)};
    background-repeat: ${props => props.backgroundRepeat || 'no-repeat'};
    background-size: ${props => props.backgroundSize || 'auto'};
    ${props => css({
      backgroundColor: props.backgroundColor || 'backgrounds.gray'
    })}
  }
`

const Overlay = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${props => 
    props.theme.utils.getHexOpacity(props.theme.colors.black, props.opacity)};
  pointer-events: none;
`

const Video = styled('video')`
  /* 
    We currently do not load video on mobile.
    We might want to reconsider this as a passed in property, so we can have mobile videos.
  */
  display: none; 
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;

  @media ${props => props.theme.queries.desktopAndUp} {
    display: block;
  }
`

/**
 * This component creates a GridComponent with a background-image, pattern,
 *  or video.
 * In addition, it also provides an overlay gradient.
 * 
 * @param {string} backgroundColor - Overrides the default (slate.lightest). 
 * @param {string} backgroundColorMobile - Overrides (slate.lightest).
 * @param {string} backgroundImage - The url for the background image
 * @param {string} backgroundImageMobile - The url for the bg image on mobile
 * @param {string} backgroundImagePosition - The image's positioning
 * @param {string} backgroundImagePositionMobile - same as above, for mobile
 * @param {string} backgroundRepeat - Repeat the image on desktop
 * @param {string} backgroundRepeatMobile - Repeat the image on mobile
 * @param {string} backgroundSize - Corresponds to background-size on desktop
 * @param {string} backgroundSizeMobile - Same as above for mobile
 * @param {number} overlayOpacity - Set the desired overlay opacity
 * @param {boolean} resizeImage - Contentful based image resize control 
 *  NB: this will convert the image to a jpg, and therefore it will lose any
 *  transparency.
 * @param {boolean} showOverlay - Show darkening overlay (default: true)
 * @param {array} videos - An array of video objects retrieved from contentful
 * @returns {React.element}
 */

const GridContainerWithBackground = ({
  backgroundColor,
  backgroundColorMobile,
  backgroundImage,
  backgroundImageMobile,
  backgroundImagePosition,
  backgroundImagePositionMobile,
  backgroundSize,
  backgroundSizeMobile,
  backgroundRepeat,
  backgroundRepeatMobile,
  resizeImage,
  videos = [],
  showOverlay = true,
  overlayOpacity = 25,
  children,
  ...delegated
}) => {
  const shouldReduceMotion = useReducedMotion()

  const [showVideo, setShowVideo] = React.useState(false);

  React.useEffect(() => {
    setShowVideo( Boolean(videos.length) && !shouldReduceMotion )
  }, [videos, shouldReduceMotion])

  return (
  <GridContainer {...delegated}>
    <Background
      backgroundColor={backgroundColor}
      backgroundColorMobile={backgroundColorMobile || backgroundColor}
      backgroundImage={backgroundImage}
      backgroundImageMobile={backgroundImageMobile || backgroundImage}
      backgroundImagePosition={backgroundImagePosition}
      backgroundImagePositionMobile={backgroundImagePositionMobile || backgroundImagePosition}
      backgroundRepeat={backgroundRepeat}
      backgroundRepeatMobile={backgroundRepeatMobile || backgroundRepeat}
      backgroundSize={backgroundSize}
      backgroundSizeMobile={backgroundSizeMobile}
      resizeImage={resizeImage}
      videoPresent={Boolean(videos.length) && !shouldReduceMotion}>
      {showVideo ? (
        <Video playsInline autoPlay loop='loop' muted width='100%' height='100%'>
          {videos.map(video => (
            <source
              key={video.fields.file.contentType}
              src={video.fields.file.url}
              type={video.fields.file.contentType}
            />
          ))}
        </Video> 
        ) : null}
    </Background>
    {showOverlay && <Overlay opacity={overlayOpacity}/>}
    {children}
  </GridContainer>
)
}
GridContainerWithBackground.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundColorMobile: PropTypes.string,
  backgroundImage: PropTypes.string,
  backgroundImageMobile: PropTypes.string,
  backgroundImagePosition: PropTypes.string,
  backgroundImagePositionMobile: PropTypes.string,
  backgroundRepeat: PropTypes.string,
  backgroundRepeatMobile: PropTypes.string,
  backgroundSize: PropTypes.string,
  backgroundSizeMobile: PropTypes.string,
  overlayOpacity: PropTypes.number,
  resizeImage: PropTypes.bool,
  showOverlay: PropTypes.bool,
  // TODO: create PropType for videos array.
  videos: PropTypes.arrayOf(PropTypes.shape({})),
  children: PropTypes.node.isRequired
}

GridContainerWithBackground.defaultProps = {
  backgroundColor: null,
  backgroundColorMobile: null,
  backgroundImage: null,
  backgroundImageMobile: null,
  backgroundImagePosition: null,
  backgroundImagePositionMobile: null,
  backgroundRepeat: null,
  backgroundRepeatMobile: null,
  backgroundSize: null,
  backgroundSizeMobile: null,
  overlayOpacity: null,
  resizeImage: false,
  showOverlay: true,
  // TODO: create PropType for videos array.
  videos: [],
}

export default GridContainerWithBackground
