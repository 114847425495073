import React from 'react';
import PropTypes from 'prop-types';
import { withChildren } from '@builder.io/react/lite';
import { noWrap } from '@builder.io/react/dist/lib/src/functions/no-wrap';

import styled from 'styled-components';

const Columns = styled('div')`
  display: flex;
  justify-content: ${props => props?.justifyContent ?? 'center'};
  align-items: ${props => props?.alignItems ?? 'auto'};
  flex-direction: row;
  flex-wrap: wrap;

  &&& {
    > * {
      margin-left: ${props => props.gridGap}px;
      ${props => props?.desktopDefaultChildWidth ? `
        width: calc(${props?.desktopDefaultChildWidth} - ${props.gridGap ?? 0}px);
      ` : ''}
    }

    > :first-child {
      ${props => props?.gridGap ? `margin-left: 0;` : ``}
    }

    ${props => props.stackColumnsAt !== 'never' ? `
      @media (max-width: ${props.stackColumnsAt !== 'tablet' ? 639 : 999}px) {
        flex-direction: ${props.reverseColumnsWhenStacked ? 'column-reverse' : 'column'};
        align-items: stretch;

        > * {
          margin-left: 0;
          margin-bottom: ${props?.stackedGridGap || props?.gridGap || 0}px;
          width: ${props?.mobileDefaultChildWidth ?? '100%'};
        }
      }
    ` : ''}

  }
`;
export const DynamicColumns = (props) => {
  const { children, ...restProps } = props;

  return (
    <Columns {...restProps}>
      {children}
    </Columns>
  );
};

DynamicColumns.propTypes = {
  children: PropTypes.node,
}

DynamicColumns.defaultProps = {
  children: null,
};

export default noWrap(withChildren(DynamicColumns));
