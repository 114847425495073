import React from 'react';
import BlogCard from '../../blog-articles-ref/blog-article-card';

import {
  ResultsContainer,
  ResultsTitle,
  SearchDescription,
  FoundArticlesWrapper,
  BlogArticlesWrapper,
  ArticlesWrapper,
} from '../blog-articles-filtered.styles';
import LoadMoreButton from './load-more-button';
const ArticlesFound = ({ articles, query, hasMore, loadMore, isLoading, foundResultsTitle }) => {
  return (
    <ResultsContainer isFound={true}>
      {foundResultsTitle && <ResultsTitle className='results-title'>{foundResultsTitle}</ResultsTitle>}
      {query && <SearchDescription className='search-query-string'>{query}</SearchDescription>}
      <FoundArticlesWrapper>
        <ArticlesWrapper>
          <BlogArticlesWrapper className='blog-articles-wrapper'>
            {articles?.map((article) => (
              <BlogCard key={article?.data?.slug} article={article} />
            ))}
          </BlogArticlesWrapper>
          <LoadMoreButton hasMore={hasMore} loadMore={loadMore} isLoading={isLoading} />
        </ArticlesWrapper>
      </FoundArticlesWrapper>
    </ResultsContainer>
  );
};

export default ArticlesFound;
