import React, { useState, useCallback, useEffect } from 'react';
import SearchArticles from './components/search-articles';
import { fetchFilteredArticles } from '../util/fetchFilteredArticles';
import AuthorCategoryFiltered from './components/author-category-filtered';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

const BlogArticlesFiltered = ({ attributes, ...props }) => {
  const serverFiltered = props?.builderState?.content?.data?.serverFiltered?.articlesToSet || [];
  const serverAddedCount = serverFiltered?.length > 0 ? 2 : 1;
  const serverHasMore = props?.builderState?.content?.data?.serverFiltered?.hasMore || false;
  const modelId = props?.builderState?.content?.id;

  const ARTICLES_PER_PAGE = 8;
  const typeList = props?.typeList;

  const { q } = useRouter().query;
  const [filteredArticles, setFilteredArticles] = useState(serverFiltered);
  const [addedCount, setAddedCount] = useState(serverAddedCount);
  const [hasMore, setHasMore] = useState(serverHasMore);
  const [isLoading, setIsLoading] = useState(false);

  const loadMore = useCallback(async () => {
    setIsLoading(true);
    const articles = await fetchFilteredArticles(
      typeList,
      modelId,
      addedCount,
      ARTICLES_PER_PAGE,
      q,
    );
    const articlesToSet = articles.slice(0, ARTICLES_PER_PAGE);
    setFilteredArticles((state) => [...state, ...articlesToSet]);
    setHasMore(articles.length > ARTICLES_PER_PAGE);
    setAddedCount((state) => state + 1);
    setIsLoading(false);
  }, [hasMore, addedCount, typeList]);

  useEffect(() => {
    setFilteredArticles(serverFiltered);
    setAddedCount(serverFiltered?.length > 0 ? 2 : 1);
    setHasMore(serverHasMore);
  }, [q]);

  return (
    <>
      {typeList == 'search' ? (
        <SearchArticles
          filteredArticles={filteredArticles}
          loadMore={loadMore}
          hasMore={hasMore}
          isLoading={isLoading}
          props={props}
          query={q}
        />
      ) : (
        <AuthorCategoryFiltered
          filteredArticles={filteredArticles}
          loadMore={loadMore}
          hasMore={hasMore}
          isLoading={isLoading}
          props={props}
        />
      )}
    </>
  );
};

BlogArticlesFiltered.propTypes = {
  modelId: PropTypes.string,
  filteredArticles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      descriptionDesktop: PropTypes.string,
      descriptionMobile: PropTypes.string,
      image: PropTypes.string,
    }),
  ),
};

BlogArticlesFiltered.defaultProps = {
  modelId: '',
  filteredArticles: [],
};

export default BlogArticlesFiltered;
