import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PlaceholderImage = ({ className, alt, ...others }) => (
  // eslint-disable-next-line jsx-a11y/alt-text
  <div className={`${className} placeholder-image`} {...others} >
    <span className='hidden'>{alt}</span>
  </div>
);

PlaceholderImage.propTypes = {
  className: PropTypes.string,
  alt: PropTypes.string,
};

PlaceholderImage.defaultProps = {
  className: '',
  alt: '',
};

// Loading CSS courtesey of react-loading-skeleton
export default styled(PlaceholderImage)`
  @keyframes loading-skeleton {
    100% {
        transform: translateX(100%);
    }
  }

  --base-color: #ebebeb;
  --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block; /* Enable animation */

  background-color: var(--base-color);

  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;

  position: relative;
  overflow: hidden;
  z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */
  

  &:after {
      content: ' ';
      display: var(--pseudo-element-display);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      background-repeat: no-repeat;
      background-image: linear-gradient(
          90deg,
          var(--base-color),
          var(--highlight-color),
          var(--base-color)
      );
      transform: translateX(-100%);

      animation-name: loading-skeleton;
      animation-direction: var(--animation-direction);
      animation-duration: var(--animation-duration);
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
  }
`;
