import { gtmEvent, segmentTrack } from './analytics';

const formEvents = {
    view: {
        segmentEvent: 'Form Viewed',
        datalayerEvent: 'Form Viewed',
    },
    submit: {
        segmentEvent: 'Form Completed',
        datalayerEvent: 'Form Completed',
    },
    focus: {
        segmentEvent: 'Form Started',
        datalayerEvent: 'Form Started',
    },
    dismiss: {
        segmentEvent: 'Form Dismissed',
        datalayerEvent: 'Form Dismissed',
    }
}


/**
 * Send datalayer and segment events.
 *
 * @param   {mixed}  events Use {} for the default event names.
 * @param   {object}  data
 * @return  {void}
 */

// The events should use the following boilerplate:
// {
//    segmentEvent: 'Event name',
//    datalayerEvent: 'Event name'
// }
const sendTrackingEvents = (events = {}, data = {}) => {
    if (Object.keys(data).length === 0) return;

    const { datalayerEvent, segmentEvent } = events;

    if (datalayerEvent) gtmEvent(datalayerEvent, data)
    if (segmentEvent) segmentTrack(segmentEvent, data)
}

const handleIntersection = (entry, observer, events, data) => {
    if (entry.isIntersecting) {
        sendTrackingEvents(Object.keys(events).length ? events : { ...formEvents.view }, data);
        observer.unobserve(entry.target);
    }
}

const trackFormView = (target, events, data) => {
    if (!target) return;
    const watchFirstView = new IntersectionObserver(([entry], observer) => {
        handleIntersection(entry, observer, events, data)
    }, {
        threshold: 0.5 // when half of the element is visible
    });
    watchFirstView.observe(target);
}

const trackFormStart = (element, events, data) => {
    element?.addEventListener('focusin', (event) => {
        event.preventDefault();
        sendTrackingEvents(Object.keys(events).length ? events : { ...formEvents.focus }, data)
    }, { once: true });
}


const trackFormSubmit = (events, data) => {
    sendTrackingEvents(Object.keys(events).length ? events : { ...formEvents.submit }, data)
}

const trackFormDismiss = (events, data) => {
    sendTrackingEvents(Object.keys(events).length ? events : { ...formEvents.dismiss }, data)
}

export {
    trackFormView,
    trackFormSubmit,
    trackFormStart,
    trackFormDismiss
}