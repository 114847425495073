// @ts-check
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence } from 'framer-motion';
import { Image as BuilderImage } from '@builder.io/react';
import {
  PillButtonWrapper,
  PillButton,
  ImageFlexContainer,
  MultiImageWrapper,
  MultiPicture,
  LeftWrapper,
  RightWrapper,
  SidePicture,
  RightContainer,
} from './explore-materials-secondary.styles';

import {
  Wrapper,
  SelectMaterialsDesktop,
  SelectMaterialsMobile,
} from './explore-materials.styles';

/**
 * @typedef {object} Materials
 * @property {string} id Unique id for image
 * @property {string} title Title of the material
 * @property {object} images images array (see gf-styled model)
 */

/**
 * @typedef {object} CtaProps
 * @property {string} id
 * @property {string} internalName
 * @property {string} size
 * @property {string} text
 * @property {string} type
 * @property {string} url
 */

/**
 * @typedef {object} Props
 * @property {Materials[]} materials - array of material objects
 * @property {string} title
 * @property {string} text
 * @property {CtaProps} ctaProps
 */

const ExploreMaterialsSecondary = ({
  materials = [],
  attributes = {},
  isBuilder,
  selectMaterialsDesktop,
  selectMaterialsMobile,
}) => {
  const [selectedMaterial, setSelectedMaterial] = useState(
    materials.length > 0 && materials[0],
  );
  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const sectionRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsSectionVisible((state) => (!state ? entry.isIntersecting : true));
    });
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  const images = selectedMaterial?.images;
  const firstImage = images[0]?.image;
  const middleImages = images?.slice(1, images?.length - 1);
  const lastImage = images[images.length - 1]?.image;
  return (
    <Wrapper
      variant='full'
      isBuilder={isBuilder}
      {...attributes}
      ref={sectionRef}>
      <PillButtonWrapper className='explore-pills-container'>
        {materials.map((material) => (
          <PillButton
            key={material.id}
            active={material.id === selectedMaterial.id}
            onClick={() => setSelectedMaterial(material)}>
            {isBuilder ? material.id : material.title}
          </PillButton>
        ))}
      </PillButtonWrapper>
      {selectMaterialsDesktop && (
        <SelectMaterialsDesktop>
          {selectMaterialsDesktop}
        </SelectMaterialsDesktop>
      )}
      {selectMaterialsMobile && (
        <SelectMaterialsMobile>{selectMaterialsMobile}</SelectMaterialsMobile>
      )}
      <ImageFlexContainer>
        {isBuilder && (
          <>
            <LeftWrapper
              isSectionVisible={isSectionVisible}
              className='explore-left-container'>
              <SidePicture>
                <BuilderImage
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                  }}
                  lazy='false'
                  image={firstImage}
                />
              </SidePicture>
            </LeftWrapper>
            {images?.length >= 3 && (
              <MultiImageWrapper
                isBuilder={isBuilder}
                isSectionVisible={isSectionVisible}
                selectMaterialsDesktop={selectMaterialsDesktop}
                imagesCount={middleImages.length}
                className='explore-middle-container'>
                <AnimatePresence>
                  {middleImages.map((image, index) => {
                    const key = `${selectedMaterial?.id}_${image.image}_${
                      index + 1
                    }`;
                    return (
                      <MultiPicture
                        key={key}
                        className={`multipicture-${index}`}
                        index={index}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}>
                        <BuilderImage
                          style={{
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                          }}
                          lazy='false'
                          image={image?.image}
                        />
                      </MultiPicture>
                    );
                  })}
                </AnimatePresence>
              </MultiImageWrapper>
            )}
            <RightContainer>
              <RightWrapper
                isSectionVisible={isSectionVisible}
                className='explore-right-container'>
                <SidePicture>
                  <BuilderImage
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'cover',
                    }}
                    lazy='false'
                    image={lastImage}
                  />
                </SidePicture>
              </RightWrapper>
            </RightContainer>
          </>
        )}
      </ImageFlexContainer>
    </Wrapper>
  );
};

ExploreMaterialsSecondary.propTypes = {
  children: PropTypes.node,
  attributes: PropTypes.shape({}),
  ctaButton: PropTypes.shape({}),
  isBuilder: PropTypes.bool,
  selectMaterialsDesktop: PropTypes.string,
  selectMaterialsMobile: PropTypes.string,
  materials: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      images: PropTypes.arrayOf(
        // There's more here, but it's ridiculous to repeat them everywhere
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          default: PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            file: PropTypes.shape({
              url: PropTypes.string,
            }),
          }),
        }),
      ),
    }),
  ),
};

ExploreMaterialsSecondary.defaultProps = {
  children: null,
  attributes: {},
  ctaButton: null,
  isBuilder: false,
  materials: [],
  selectMaterialsDesktop: '',
  selectMaterialsMobile: '',
};

export default ExploreMaterialsSecondary;
