export const blogSearch = (e, inputValue) => {
    if (e.type === 'keydown' && e.key !== 'Enter') {
        return;
    }
    const formattedValue = inputValue.replace(/ /g, '+');
    if (formattedValue?.length > 0) {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            event: 'searchBarValue',
            value: inputValue,
        });
    }
    return formattedValue
}