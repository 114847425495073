const artboardOptionsConfig = {
    name: "Magic Canvas Options",
    noWrap: true,
    requiresParent: {
        message: 'This component can be used only in Magic Canvas components',
        query: {
            'component.name': { $in: ['Glowforge:Magic Canvas'] }
        }
    },
    inputs: [
        {
            name: 'category',
            type: 'string',
            defaultValue: 'phrase',
            enum: ['phrase', 'art-style', 'loader', 'result'],
            helperText: 'The "loader" selects random image from the variants.',
        },
        {
            name: 'variants',
            type: 'list',
            defaultValue: [
                { text: 'Text' },
            ],
            subFields: [
                {
                    name: 'relations',
                    type: 'list',
                    subFields: [
                        {
                            name: 'images',
                            type: 'list',
                            subFields: [
                                {
                                    name: 'image',
                                    type: 'file',
                                    allowedFileTypes: ['jpeg', 'jpg', 'png', 'webp', 'gif'],
                                }
                            ],
                            defaultValue: [],
                        },
                    ],
                    defaultValue: [],
                    helperText: 'Use it in the "result" category',
                },
                {
                    name: 'text',
                    type: 'string',
                    defaultValue: 'Text',
                    // eslint-disable-next-line max-len
                    helperText: 'Not visible in the "loader" and "result" categories. Used for the image alt text.',
                },
                {
                    name: 'image',
                    type: 'file',
                    allowedFileTypes: ['jpeg', 'jpg', 'png', 'webp', 'gif'],
                }
            ],
            // eslint-disable-next-line max-len
            helperText: 'Structure for the "result" category: Variant = phrase, Relation = style, Relation images = all possible combinations for the selected phrase <=> style.',
        }
    ],
    image: "https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F26c23efebae64ce5a56a3e34606a8a83"
};

export default artboardOptionsConfig;