import styled, { css } from 'styled-components'

/* Create our own stacking context and root for our
absolutely positioned pop over */
const PopoverAnchorWrapper = styled('div')`
  position: relative;
`

const PAD = '12px'

const PopoverBody = styled('div')`
  position: absolute;
  box-shadow: 0 0 6px #00000029;
  background-color: white;
  border-radius: 6px;
  width: fit-content;

  ${props => {
    /* Place the Popover box according to position and alignment */
    switch (props.position) {
      case 'top': {
        return css`
          bottom: calc(100% + ${PAD});
          margin-bottom: ${props.arrowSize}px;
          ${props.align === 'start' ? 'left' : 'right' }: -${PAD};
        `
      }
      case 'right': {
        return css`
          left: calc(100% + ${PAD});
          ${props.align === 'start' ? 'top' : 'bottom' }: -${PAD};
          margin-left: ${props.arrowSize}px;
        `
      }
      case 'left': {
        return css`
          right: calc(100% + ${PAD});
          ${props.align === 'start' ? 'top' : 'bottom' }: -${PAD};
          margin-right: ${props.arrowSize}px;
        `
      }
      case 'bottom':
      default: {
        return css`
          top: calc(100% + ${PAD});
          margin-top: ${props.arrowSize}px;
          ${props.align === 'start' ? 'left': 'right'}: 0;
        `
      }
    }
  }}

  /* Arrow placement */
  &::after {
    /* 
      This :after creates a rectangle and then clips a triangle out of it. 
      We use the Pythagorean theorem to create the proper rectangle for our desired triangle size.
    */

    content: '';
    width: ${props => Math.sqrt(props.arrowSize**2 + props.arrowSize**2)}px;
    height: ${props => Math.sqrt(props.arrowSize**2 + props.arrowSize**2)}px;
    clip-path: polygon(-20px -20px, calc(100% + 20px) -20px, calc(100% + 20px) calc(100% + 20px));
    
    background-color: white;
    position: absolute;
    /* black at 29% opacity; TODO: create through theme.util */
    box-shadow: 0 0 6px #00000029;
    
    ${props => {
      /* 
        Place the Popover arrow according to position and alignment
        We need to rotate the :after, because we are dealing with a clipped
        rectangle.
      */
      switch(props.position) {
        case 'top': {
          return css`
            // this calc currently works around 8px; magic numbers!
            top: calc(100% - ${props.arrowSize}px + 1px); 
            transform: rotate(135deg);
            ${props.align === 'start' ? 'left' : 'right' }: ${props.arrowSize * 2}px;
          `
        }
        case 'right': {
          return css`
            // this calc currently works around 8px; magic numbers!
            right: calc(100% - ${props.arrowSize}px + 1px); 
            transform: rotate(-135deg);
            ${props.align === 'start' ? 'top' : 'bottom' }: ${props.arrowSize * 2}px;
          `
        }
        case 'left': {
          return css`
            // this calc currently works around 8px; magic numbers!
            left: calc(100% - ${props.arrowSize}px + 1px); 
            transform: rotate(45deg);
            ${props.align === 'start' ? 'top' : 'bottom' }: ${props.arrowSize * 2}px;
          `
        }
        case 'bottom':
        default: {
          return css`
            // this calc currently works around 8px; magic numbers!
            bottom: calc(100% - ${props.arrowSize}px + 1px); 
            transform: rotate(-45deg);
            ${props.align === 'start' ? 'left' : 'right' }: ${props.arrowSize * 2}px;
          `
        }
      }
    }}
  }
`

export {
  PopoverBody,
  PopoverAnchorWrapper
}