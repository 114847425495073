import styled from 'styled-components';
import { m } from 'framer-motion';

// eslint-disable-next-line import/no-named-default
import { default as LazyImage } from '@glowforge/gf-styled/src/components/contentful-views/image';
import GridContainer from '@glowforge/gf-styled/src/components/grid-container/grid-container';
import {
  Heading,
  Paragraph,
  getTypographyVariant,
} from '@glowforge/gf-styled/src/components/typography';

const Wrapper = styled(GridContainer)`
  ${(props) => (props.isBuilder ? '' : 'padding-top: 44px;')}
  ${(props) => (props.isBuilder ? '' : 'padding-bottom: 32px;')}
  color: ${(props) => props.theme.colors.slate.base};

  @media ${(props) => props.theme.queries.desktopAndUp} {
    ${(props) => (props.isBuilder ? '' : 'padding-top: 96px;')}
    ${(props) => (props.isBuilder ? '' : 'padding-bottom: 64px;')}
  }
`;

const Title = styled(Heading)`
  grid-column: main-start / main-end;
  justify-self: center;
  text-align: center;
  padding-bottom: 24px;
`;

const Text = styled(Paragraph)`
  grid-column: main-start / main-end;
  justify-self: center;
  text-align: center;
  padding-bottom: 32px;

  @media ${(props) => props.theme.queries.desktopAndUp} {
    grid-column: col-start 4 / col-end 9;
  }
`;

const PillButtonWrapper = styled('div')`
  grid-column: full-start / full-end;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  white-space: nowrap; /* Don't wrap buttons. */
  padding-bottom: 36px;

  @media ${(props) => props.theme.queries.desktopAndUp} {
    ${(props) =>
    props.isBuilder
      ? `grid-column: full-start / full-end;`
      : `grid-column: main-start / main-end;`}
    padding-bottom: 54px;
    justify-content: flex-start;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const PillButton = styled('button')`
  flex: 1;
  background: ${(props) =>
    // no idea where this color came from or why it isn't in design system.
    props.active ? props.theme.colors.magic.base : 'transparent'};
  color: ${(props) => (props.active ? 'white' : props.theme.colors.slate.base)};
  padding: 0.375rem 1.125rem;
  border: 1px solid #e5e5e5;
  border-radius: 10em;
  transition: background 0.2s ease-in-out;
  margin-left: ${(props) => props.theme.gutters.mobile}px;
  ${getTypographyVariant('paragraph')}
  --font-family: 'Space Grotesk';
  --font-weight: 500;
  cursor: pointer;

  &:last-of-type {
    margin-right: ${(props) => props.theme.gutters.mobile}px;
  }

  @media ${(props) => props.theme.queries.desktopAndUp} {
    margin-left: 12px;
    &:first-of-type {
      margin-left: 0;
    }
  }

  &:hover {
    background: ${(props) => props.theme.colors.magic.buttonBase};
  }
`;

const ImageWrapper = styled('div')`
  grid-column: main-start / main-end;
  display: grid;
  grid-gap: 16px;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-template-areas:
    'img0 img1'
    'img2 img3';
  padding-bottom: 42px;
  justify-content: center;

  img,
  picture {
    ${(props) => (props.isBuilder ? 'display: inline;' : '')}
    ${(props) => (props.isBuilder ? 'width: 100%;' : '')}
    ${(props) => (props.isBuilder ? 'height: 100%;' : '')}
  }

  @media ${(props) => props.theme.queries.desktopAndUp} {
    ${(props) => props.isBuilder ? `grid-column: full-start / full-end;` : 'grid-column: main-start / main-end;'}
    margin-top: ${(props => props.selectMaterialsDesktop ? '30px' : '0')};
    ${(props) =>
    props.isBuilder ? 'padding-left: 20px; padding-right: 20px;' : null}
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      'img0 img1 img3'
      'img0 img2 img3';
    padding-bottom: 64px;
    justify-content: unset;
    justify-items: center;
  }
`;

const Image = styled(LazyImage)`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const Picture = styled(m.div)`
  grid-area: ${(props) => `img${props.index}`};
  width: 100%;
  aspect-ratio: 1/1;
  display: inline-block;
  opacity: 0;

  @media ${(props) => props.theme.queries.desktopAndUp} {
    display: block;
    height: ${(props) =>
    props.index === 0 || props.index === 3 ? '381px' : '183px'};
  }
`;

const ButtonWrapper = styled('div')`
  grid-column: main-start / main-end;
  display: flex;
  justify-content: center;
`;


const SelectMaterialsDesktop = styled('div')`
  margin: 0 auto;
  font-size: 14px;
  font-family: 'Space Grotesk', sans-serif;
  @media ${(props) => props.theme.queries.desktopDown} {
    display: none;
  }
`;

const SelectMaterialsMobile = styled('div')`
  display: none;
  @media ${(props) => props.theme.queries.desktopDown} {
    display: block;
    margin: 0 auto 20px auto;
    font-size: 14px;
    font-family: 'Space Grotesk', sans-serif;
  }
`;

export {
  Wrapper,
  Title,
  Text,
  PillButtonWrapper,
  PillButton,
  ImageWrapper,
  Image,
  Picture,
  ButtonWrapper,
  SelectMaterialsDesktop,
  SelectMaterialsMobile
};
