import { CountryCodes } from '../constants';
import {
  readRequestLocationheaders
} from '../pages/api/geolocation'
import {fetchLocation} from '../context/LocationContext/LocationProvider'


/**
 * Fetches country code from Cloud-front headers or from our API (that uses cloudfront)
 * @param {Object} context The context object from getInitialProps
 * @returns {Promise<Object>} Returns a promise to get you the country code
 */
export default async function fetchCountryCode(context = null) {
  let countryCode = CountryCodes.US; // Just the default
  try {
    // If we're server side, we can get this from the headers, otherwise, we gotta make a network call.
    if(context && context?.req) {
      ({country: countryCode} = readRequestLocationheaders(context?.req))
    } else {
      ({country: countryCode} = await fetchLocation());
    }
    return countryCode
  } catch(e) {
    console.error(e)
    return countryCode
  }
}
