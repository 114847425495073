import React from 'react';
import PropTypes from 'prop-types';

import HR from '../../hr';
import { NavLink } from './top-nav.styles';
import NavDropdown from './nav-dropdown';

const NavLinks = ({
  activeLink = '',
  keyboardNav = true,
  routes = [],
  onMobile = false,
}) => (
    <>
      {routes.map((link) =>
        link.mobileOnly && !onMobile ? null : (
          <li key={link.url} className={link?.className}>
            {/* We modify tabIndex here to hide when not expanded in mobile. */}
            {link?.subnav?.value?.data ? (
              <NavDropdown {...{ link, activeLink }} />
            ) : (
              <NavLink
                {...link.url ? { href: link.url } : {}}
                tabIndex={keyboardNav ? 0 : -1}
                $active={link.url === activeLink}>
                {link.text}
              </NavLink>
            )}
            {onMobile ? <HR /> : null}
          </li>
        )
      )}
    </>
);

NavLinks.propTypes = {
  activeLink: PropTypes.string,
  /* Whether this NavLink is rendering via <MobileNav /> */
  onMobile: PropTypes.bool,
  /* Array of links to populate */
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
      active: PropTypes.bool,
      disabled: PropTypes.bool,
      mobileOnly: PropTypes.bool,
      openInNewTab: PropTypes.bool,
      className: PropTypes.string,
    })
  ),
  /**
   * Whether the links should be navigable by keyboard.
   * Used to "hide" unexpanded mobile dropdown
   * */
  keyboardNav: PropTypes.bool,
};

NavLinks.defaultProps = {
  activeLink: '',
  onMobile: false,
  keyboardNav: true,
  routes: [],
};

export default NavLinks;
