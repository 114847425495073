export default {
    name: 'Glowforge:BlogArticlesList',
    friendlyName: 'Blog Articles List',
    image: 'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F455a168713f7411ca2210a0282b5f2a6?quality=60&width=200&height=200',
    noWrap: true,
    inputs: [
        {
            name: 'articles',
            friendlyName: 'Articles',
            helperText:
                `These are references to Blog-article Sections.
          To add a new Article, create a new Blog Article - https://builder.io/content?model=843ffc1198a64b21a5aa2e636592ea32`,
            type: 'list',
            subFields: [
                {
                    name: 'article',
                    type: 'reference',
                    model: 'blog-article',
                },
            ],
        },
    ],
};
