/**
 * Widget model defines access to our Contentful Custom Widget model
 * https://app.contentful.com/spaces/ljtyf78xujn2/content_types/customWidget/fields
 *
 * If you need to access the raw data on a custom widget section add 
 * a method here
 */
import get from 'lodash/get'
import reduce from 'lodash/reduce'
import PropTypes from 'prop-types';
import { getContentType } from './page';

/**
 * Get all text strings as an object by their dev ids
 * @param  {Object} widget Custom Widget Contentful object
 * @return {Object of strings} only the strings from the contentful object
 */
export const getStringsForWidget = (widget = {}) => reduce(widget.textStrings, (all, string) => {
  if (getContentType(string) === 'basicTextString') {
     // eslint-disable-next-line no-param-reassign
    all[get(string.fields, 'id')] = get(string.fields, 'text');
  }
  return all;
}, {});

/**
 * Get all markdown strings object by their dev ids
 * @param  {Object} widget Custom Widget Contentful object
 * @return {Object of strings} only the markdown sections from the
 *  contentful object
 */
export const getMarkdownStringsForWidget = (widget = {}) => (
  reduce(widget.textStrings, (all, string) => {
    if (getContentType(string) === 'textString') {
       // eslint-disable-next-line no-param-reassign
      all[get(string.fields, 'id')] = get(string.fields, 'text');
    }
    return all;
  }, {})
);

/**
 * Get all images strings object by their internal content id
 * @param  {Object} widget Custom Widget Contentful object
 * @return {Object{
 *    src: url
 *    alt: decription of the image
 *    title: title of the image
 * }} all images from the contentful object
 */
export const getImagesForWidget = (widget = {}) => reduce(widget.images, (all, image) => {
  // eslint-disable-next-line no-param-reassign
  all[get(image, 'sys.id')] = {
    src: get(image, 'fields.file.url'),
    alt: get(image, 'fields.description'),
    title: get(image, 'fields.title'),
  };
  return all;
}, {});

export const widgetPropType = {
  textStrings: PropTypes.arrayOf(PropTypes.shape({
    fields: PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
    }),
  })),
  sections: PropTypes.arrayOf(PropTypes.shape({
    fields: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
    }),
  })),
};

export const widgetDefaultProps = {
  textStrings: [],
  sections: [],
};

/**
 * Widget model to make working with raw widget data simpleStrings
 *
 * Wrap any raw widget data in this before sharing it around so we
 * are not tempted to access it directly
 */
export class WidgetModel {
  /**
   * @param {object} widgetData  Custom Widget Contentful object
   */
  constructor(widgetData) {
    this.widgetData = widgetData;
  }

  /**
   * @return  {Object of strings} only the strings from the contentful object
   */
  get textStrings() {
    if (!this.textStringsInternal) {
      this.textStringsInternal = getStringsForWidget(this.widgetData.fields);
    }

    return this.textStringsInternal;
  }

  /**
   * @return {Object of strings} only the markdown sections from the
   *  contentful object
   */
  get markdownStrings() {
    if (!this.markdownStringsInternal) {
      this.markdownStringsInternal = getMarkdownStringsForWidget(this.widgetData.fields);
    }

    return this.markdownStringsInternal;
  }

  /**
   * @return {Object{
   *    src: url
   *    alt: decription of the image
   *    title: title of the image
   * }} all images from the contentful object
   */
  get images() {
    if (!this.imagesInternal) {
      this.imagesInternal = getImagesForWidget(this.widgetData.fields);
    }

    return this.imagesInternal;
  }
}
