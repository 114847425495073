import BlogCard from '../../blog-articles-ref/blog-article-card';
import {
  BlogArticlesWrapper,
  ArticlesWrapper,
  ResultsTitle,
} from '.././blog-articles-filtered.styles';
import LoadMoreButton from './load-more-button';
const AuthorCategoryFiltered = ({
  filteredArticles,
  loadMore,
  hasMore,
  isLoading,
  props: { notFoundTitle }
}) => {
  return filteredArticles?.length > 0 ? (
    <ArticlesWrapper>
      <BlogArticlesWrapper className='blog-articles-wrapper'>
        {filteredArticles?.map((article) => (
          <BlogCard article={article} key={article.data?.slug} />
        ))}
      </BlogArticlesWrapper>
      <LoadMoreButton
        hasMore={hasMore}
        loadMore={loadMore}
        isLoading={isLoading}
      />
    </ArticlesWrapper>
  ): (
    notFoundTitle && <ResultsTitle notFound={true}>{notFoundTitle}</ResultsTitle>
  )
};

export default AuthorCategoryFiltered;
