import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Script from 'next/script';
import {
  trackFormSubmit,
  trackFormStart,
  trackFormView,
} from '@glowforge/gf-styled/src/util/form-tracking';

import { HubspotFormStyles } from './hubspot-form.styles';
import EditorError from '../util/editor-error';

// form like https://app.hubspot.com/forms/8614495/62ad494c-c121-427f-a452-c72bccbf49e1/
const formRegex = /hubspot.com\/forms\/(.+)/;
function parseFormLink(formLink) {
  const regexResult = formRegex.exec(formLink);
  const [link, path] = Array.isArray(regexResult) ? regexResult : [null, null];
  const [portalId, formId] = path?.split('/') ?? [null, null];
  return { link, portalId, formId };
}

function createForm({ portalId, formId, id }) {
  if (window?.hbspt) {
    const trackingData = {
      form_id: formId,
      portal_id: portalId,
      form_name: 'hubspot-form',
      form_service: 'hubspot',
      delivery_method: 'embed',
    };

    window?.hbspt?.forms?.create({
      portalId,
      formId,
      target: `#${id}`,
      cssClass: 'gf-hubspot-form',
      submitButtonClass: 'gf-hubspot-form-submit',
      onFormReady(form) {
        const formData = Object.fromEntries(new FormData(form));
        trackFormView(
          form,
          {},
          {
            ...trackingData,
            num_fields: Object.keys(formData).length,
          }
        );

        trackFormStart(
          form,
          {},
          {
            ...trackingData,
            num_fields: Object.keys(formData).length,
          }
        );
      },
      onFormSubmit(form) {
        const formData = Object.fromEntries(new FormData(form));
        trackFormSubmit(
          {},
          {
            ...formData,
            ...trackingData,
            num_fields: Object.keys(formData).length,
          }
        );
      },
    });
  } else {
    setTimeout(() => createForm({ portalId, formId, id }), 1);
  }
}

export const HubspotForm = ({ formLink, builderBlock, buttonType }) => {
  const { id } = builderBlock;
  const { portalId, formId } = parseFormLink(formLink);
  useEffect(() => {
    if (portalId && formId) {
      createForm({ portalId, formId, id });
    }
    // eslint-disable-next-line
  }, [portalId, formId]);
  return (
    <>
      <Script src='//js.hsforms.net/forms/v2.js' strategy='afterInteractive' />
      {!portalId || !formId ? (
        <EditorError>
          <p>Could not parse portalId and/or formId from form url</p>
        </EditorError>
      ) : null}
      <HubspotFormStyles id={id} buttonType={buttonType} />
    </>
  );
};

HubspotForm.propTypes = {
  formLink: PropTypes.string,
  builderBlock: PropTypes.shape({
    id: PropTypes.string,
  }),
  buttonType: PropTypes.string,
};

HubspotForm.defaultProps = {
  formLink: '',
  builderBlock: {
    id: '',
  },
  buttonType: 'default',
};

export default HubspotForm;
