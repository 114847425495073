import React, { useState } from 'react';
import SVG from '@glowforge/gf-styled/src/components/svg/svg';
import { useRouter } from 'next/router';
import {
  ResultsContainer,
  NothingFoundWrapper,
  ResultsTitle,
  SearchDescription,
  SearchWrapper,
  SearchInput,
  SearchIconButton,
} from '../blog-articles-filtered.styles';
import { blogSearch } from '../../util/blogSearch';
const ArticlesNotFound = ({ notFoundResultsTitle, notFoundSubtitle }) => {
  const [inputValue, setInputValue] = useState('');
  const router = useRouter();
  const handleSubmit = (e) => {
    const formattedValue = blogSearch(e, inputValue);
    if(formattedValue?.length > 0){
      router.push(`/blog/search?q=${formattedValue}`);
    }
  };
  return (
    <ResultsContainer isFound={false}>
      <NothingFoundWrapper className='nothing-found-wrapper'>
        {notFoundResultsTitle && (
          <ResultsTitle className='nothing-found-title'>
            {notFoundResultsTitle}
          </ResultsTitle>
        )}
        {notFoundSubtitle && (
          <SearchDescription className='nothing-found-description'>
            {notFoundSubtitle}
          </SearchDescription>
        )}
        <SearchWrapper className='nothing-found-search-wrapper'>
          <SearchInput
            className='nothing-found-search-input'
            type='text'
            placeholder='Search...'
            id='search-text'
            name='search-text'
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleSubmit}
          />
          <SearchIconButton onClick={handleSubmit}>
            <SVG
              id='blog-search'
              fill='#bebebe'
              stroke='none'
              width={20}
              height={20}
            />
          </SearchIconButton>
        </SearchWrapper>
      </NothingFoundWrapper>
    </ResultsContainer>
  );
};

export default ArticlesNotFound;
