import React from 'react';
import isFunction from 'lodash/isFunction';
import Markdown from '../../markdown';

/**
 * Substitutes components in to a text string replacing {{macro|value}} with a 
 * React render function. Same as stringSubstitution, but with components.
 *
 * Use Cases:
 * - render React components in place of strings.
 * - render a *bold* string in place of the macro text
 * - give the macro text a color
 * @param {string} text - text that may or may not contain 
 *  {{macro|value}} entries
 * @param {object} components - Mapping of macros to functional components.
 *                              Fn is passed macro or macro value
 * @param {object} [options] - Options for the substitution.
 * @param {bool} [options.markdown] - Use markdown for the text fragments. 
 *  Default is true.
 * @param {string} [options.markdownWrapperElement] - Element used to 
 *  wrap markdown.
 * @param {object} [options.remarkableOptions] - Options passed to remarkable.
 */
export default function componentSubstitution(text, components, options = {
  markdown: true,
  remarkableOptions: {},
}) {
  if (typeof text !== 'string') {
    return '';
  }

  // Regex to extract macros.
  const split = text.split(/{{([^{}]+)}}/g);
  return split.map((macro, index) => {
    if (macro.length < 1) {
      return null;
    }

    const [macroKey, macroValue] = macro.split('|');
    const render = components[macroKey];
    const value = macroValue || macroKey;

    let fragment;
    if (isFunction(render)) {
      fragment = render(value);
    } else {
      fragment = options.markdown
        ? (
          <Markdown
            element={options.markdownWrapperElement}
            options={{ html: true, breaks: true, ...options.remarkableOptions }}
            source={macro}
          />
        )
        : macro;
    }

    // eslint-disable-next-line react/no-array-index-key
    return <React.Fragment key={`${index}–${macro}`}>{fragment}</React.Fragment>;
  });
}
