import PropTypes from 'prop-types';
import { clientOnly, FakeWindow } from '../../util';

const windowType = PropTypes.shape({
  localStorage: PropTypes.shape({
    getItem: PropTypes.func,
    setItem: PropTypes.func,
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
});

const defaultWindow = clientOnly(() => window) || new FakeWindow();

export { windowType, defaultWindow };
