import React from 'react';
import PropTypes from 'prop-types';

import SVG from '../../svg';

import {
  NavLink,
  SVGWrapper,
  UserLinksWrapper,
} from './desktop-user-nav.styles';

const UserPopover = ({ activeLink, routes = [] }) => {
  const signedInRoutes = routes?.filter((route) => route?.type === 'signed-in');

  return routes?.length > 0 ? (
    <UserLinksWrapper>
      <ul>
        {signedInRoutes?.map((route) => (
          <li key={route?.url} className={route?.className}>
            <NavLink href={route?.url} active={activeLink === route?.url}>
              {route?.icon ? (
                <SVG
                  id={route.icon}
                  $stroke='inherit'
                  $fill='inherit'
                  height='16px'
                  width='16px'
                  SVGWrapper={SVGWrapper}
                />
              ) : null}
              {route?.text}
            </NavLink>
          </li>
        ))}
      </ul>
    </UserLinksWrapper>
  ) : null;
};

UserPopover.propTypes = {
  activeLink: PropTypes.string,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
      type: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};

UserPopover.defaultProps = {
  activeLink: '',
  routes: [],
};

export default UserPopover;
