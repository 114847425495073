import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ArtboardOption from '@glowforge/gf-styled/src/components/artboard-option';
import { ArtboardContext } from '../artboard/artboard';

const Image = styled('img')`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  &.loader,
  &.result {
    max-width: 450px;
    height: 100%;
    max-height: 450px;
    margin: 0 auto;
    border-radius: 19px;
    border: 1px solid ${(props) => props.theme.colors.slate.lightest};
  }
`;

const OptionPicker = styled('fieldset')`
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  width: 100%;
  border: 0;

  &.art-style {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${(props) => props.theme.queries.tabletAndUp} {
    grid-template-columns: repeat(auto-fit, minmax(141px, 1fr));
    &.art-style {
      grid-template-columns: repeat(auto-fit, minmax(141px, 1fr));
    }
  }
`;

const ArtboardOptions = ({ attributes, builderBlock, variants, category }) => {
  const data = useContext(ArtboardContext);

  if (!variants?.length || !data?.form) return null;

  const phrase = +data.form.phrase || 0;
  const style = +data.form['art-style'] || 0;

  switch (category) {
    case 'loader': {
      const randomOption = Math.floor(Math.random() * variants.length) + 0;
      const option = variants?.[randomOption];
      const { image, text } = option;
      return image ? (
        <Image
          className='loader'
          src={image}
          width={200}
          height={200}
          alt={text || 'Magic Canvas Loading Image'}
        />
      ) : (
        'Image is missing'
      );
    }
    case 'result': {
      const variant = variants?.length ? variants[phrase] : {};
      const relation = variant?.relations?.[style];
      let current = relation?.images?.[+data.index];
      if (!current) {
        current = relation?.images?.[0];
        data.index = 0;
      }
      return current?.image ? (
        <Image
          className='result'
          src={current?.image}
          width={200}
          height={200}
          alt={variant?.text || 'Magic Canvas Result'}
        />
      ) : (
        'Image is missing'
      );
    }
    default: {
      return (
        <OptionPicker {...attributes} className={category}>
          {variants.map((option, index) => {
            const id = `${index}_${builderBlock.id}`;
            const optionProps = {
              index,
              id,
              category,
              style,
              phrase,
              ...option,
            };

            return <ArtboardOption key={id} {...optionProps} />;
          })}
        </OptionPicker>
      );
    }
  }
};

ArtboardOptions.propTypes = {
  builderBlock: PropTypes.shape({
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.any),
      PropTypes.elementType,
    ]),
    id: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  attributes: PropTypes.object,
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      relations: PropTypes.arrayOf(
        PropTypes.shape({
          images: PropTypes.arrayOf(PropTypes.shape({})),
        })
      ),
    })
  ),
  category: PropTypes.string,
};

ArtboardOptions.defaultProps = {
  attributes: {},
  category: 'phrase',
  variants: [],
};

export default ArtboardOptions;
