import React from 'react';
import PropTypes from 'prop-types';
import { withChildren } from '@builder.io/react/lite';
import styled from 'styled-components';

// todo: port over horizontal scroll
import HorizontalScroll from '@glowforge/gf-styled/src/components/horizontal-scroll';
// eslint-disable-next-line max-len
import { ScrollWrapper } from '@glowforge/gf-styled/src/components/horizontal-scroll/horizontal-scroll.styles';
import SliderComponent from '@glowforge/gf-styled/src/components/slider/slider';

const CarouselComponent = styled(HorizontalScroll)`
  padding: 0;

  ${ScrollWrapper} {
    padding: 20px;
  }

  /* This ensures that lazy loaded images load correctly inside of the carousel */
  picture {
    min-height: 1px;
  }

  // Remove stroke from arrows that is added by default
  // svg behavior
  & svg {
    stroke: ${(props) => props.theme.colors.white};
  }
`;

const ChildWrapper = styled('div')`
  display: flex;
`;

export const Carousel = ({
  children,
  scrollStepMultiply,
  hideNavigationArrowsMobile,
  isBlog,
  activateAdvancedOptions,
  options,
  slides,
  builderBlock,
}) => {
  if (activateAdvancedOptions === false && children) {
    return (
      <CarouselComponent
        scrollStepMultiply={scrollStepMultiply}
        hideNavigationArrowsMobile={hideNavigationArrowsMobile}
        isBlog={isBlog}
        >
        {React.Children.map(children, (child, index) => (
          <ChildWrapper className='childWrapper' key={child?.key || index}>{child}</ChildWrapper>
        ))}
      </CarouselComponent>
    );
  }
  return (
    <SliderComponent id={builderBlock.id} slides={slides} {...{ options }} />
  );
};

Carousel.propTypes = {
  builderBlock: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
  scrollStepMultiply: PropTypes.number,
  hideNavigationArrowsMobile: PropTypes.bool,
  isBlog: PropTypes.bool,
  activateAdvancedOptions: PropTypes.bool,
  options: PropTypes.shape({}),
  slides: PropTypes.arrayOf(PropTypes.shape({}))
};

Carousel.defaultProps = {
  children: null,
  scrollStepMultiply: 1,
  hideNavigationArrowsMobile: true,
  isBlog: false,
  activateAdvancedOptions: false,
  options: {},
  slides: [],
};

export const BuilderCarousel = withChildren(Carousel);
export default BuilderCarousel;
