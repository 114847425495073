import React, { useState, useEffect } from 'react';
import getConfig from 'next/config';
import PropTypes from 'prop-types';
import { StateCodes } from '../../constants';
import LocationContext, { locationDefault } from './LocationContext';
import { clientOnly } from '../../util';

const {
  publicRuntimeConfig: { GF_DOTCOM_API_ENDPOINT, GF_DOTCOM_COUNTRY_OVERRIDE },
} = getConfig();

/**
 * @typedef GeolocationResult
 * @property {string} state state code
 * @property {string} country country code
 *
 * @returns {Promise<GeolocationResult>}
 */
export function fetchLocation() {
  return fetch(`${GF_DOTCOM_API_ENDPOINT}/api/geolocation`, {
    credentials: 'same-origin',
  }).then((response) => {
    // For local testing purposes
    if (GF_DOTCOM_COUNTRY_OVERRIDE) {
      return {
        country: GF_DOTCOM_COUNTRY_OVERRIDE,
        state: StateCodes.unknown,
      };
    }
    return response.json();
  });
}

/**
 * Helper function to call analytics with location data
 * @param {string} country
 * @param {string} state
 */
function identifyLocation(country, state) {
  clientOnly(() => {
    window.dataLayer.push({
      country,
      state,
    });
  });
}

/**
 * Fetched location data clientside and provides it to child context.
 * @param {object} props
 * @param {React.ReactNode} props.children
 */
const LocationProvider = ({ children }) => {
  const [{ country, state, hasFetched }, setLocation] =
    useState(locationDefault);

  // try to fetch location
  useEffect(() => {
    async function callFetchLocation() {
      try {
        const { country: newCountry, state: newState } = await fetchLocation();
        setLocation({
          country: newCountry,
          state: newState,
          hasFetched: true,
        });
        identifyLocation(newCountry, newState);
      } catch (e) {
        console.log('Failed to fetch location', e);
        setLocation({ country, state, hasFetched: true });
      }
    }
    callFetchLocation();
  }, []);

  return (
    <LocationContext.Provider
      value={{
        country,
        state,
        hasFetched,
      }}>
      {children}
    </LocationContext.Provider>
  );
};

LocationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LocationProvider;
