import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { m, AnimatePresence } from 'framer-motion';

const swipeConfidenceThreshold = 1000;
const swipePower = (offset, velocity) => Math.abs(offset) * velocity;

const DefaultSlideWrapper = ({ children, ...rest }) => (
  <m.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    {...rest}>
    {children}
  </m.div>
);

DefaultSlideWrapper.propTypes = {
  children: PropTypes.node,
};

DefaultSlideWrapper.defaultProps = {
  children: null,
};

const Carousel = ({
  activeIndex,
  goPrev,
  goNext,
  setLength,
  resetDelay,
  Wrapper,
  SlideWrapper,
  children,
}) => {
  const childrenArray = React.Children.toArray(children);

  useEffect(() => {
    // Let useCarousel hook know how many pages there will be.
    setLength(childrenArray.length);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenArray]);

  return (
    <Wrapper
      style={{
        // We define these as inline styles so passed in
        // Wrappers won't override them.
        display: 'grid',
        touchAction: 'pan-y', // needed to pick up onPanEnd
      }}
      as={m.div}
      transition={{ duration: 3 }}
      // eslint-disable-next-line react/jsx-no-bind
      onPanEnd={(_, { offset, velocity }) => {
        // User is probably attempting to scroll the window. Don't do anything.
        if (Math.abs(velocity.y) > Math.abs(velocity.x)) return;

        const swipe = swipePower(offset.x, velocity.x);
        if (swipe > swipeConfidenceThreshold) {
          goPrev();
        } else if (swipe < -swipeConfidenceThreshold) {
          goNext();
        }
        resetDelay();
      }}>
      <AnimatePresence initial={false}>
        <SlideWrapper
          key={activeIndex}
          style={{
            // We define these as inline styles so passed
            // in Wrappers won't override them.
            gridColumn: '1',
            gridRow: '1',
          }}>
          {childrenArray[activeIndex]}
        </SlideWrapper>
      </AnimatePresence>
    </Wrapper>
  );
};

Carousel.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  goPrev: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
  setLength: PropTypes.func.isRequired,
  Wrapper: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.elementType,
    PropTypes.func
  ]), // Neither element nor node are working here for some reason.
  SlideWrapper: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func
  ]), // Neither element nor node are working here for some reason.
  children: PropTypes.node,
  resetDelay: PropTypes.func,
};

Carousel.defaultProps = {
  Wrapper: m.div,
  children: null,
  resetDelay: () => {},
  SlideWrapper: DefaultSlideWrapper,
};

export default Carousel;
