/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const RetailMapContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const MapContainer = styled('div')`
  width: 100%;
  position: relative;
  overflow: hidden;
  iframe {
    width: 100%;
    height: 100%;
    min-height: ${(props) => props.minHeightMobile};
    @media ${(props) => props.theme.queries.desktopAndUp} {
      min-height: ${(props) => props.minHeight};
    }
    border: none;
  }
`;

const Select = styled('select')`
  font-family: 'Exo 2', sans-serif;
  font-size: 1rem;
  line-height: 1.8rem;
  padding: 0.3rem;
  border: 3px solid transparent;
  border-radius: 5px;
  color: ${(props) => props.theme.colors.magic.base};
  &:focus {
    border-color: ${(props) => props.theme.colors.magic.base};
  }
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%2326B8CE'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat;
  background-size: 12px;
  background-position: calc(100% - 0.3rem) 70%;
  background-color: ${(props) => props.theme.colors.white};
  background-repeat: no-repeat;
  cursor: pointer;
`;

function RetailMap({ iframeHeight, mobileIframeHeight, locations }) {
  const [currentLocation, setCurrentLocation] = useState(locations?.[0]);

  const changeHandler = (event) => {
    event.preventDefault();
    const { selectedIndex } = event.target;
    setCurrentLocation(locations[selectedIndex]);
  };

  if (locations.length === 0 || !currentLocation?.iframe) return null;

  const { iframe } = currentLocation;

  return (
    <RetailMapContainer>
      <Select name='retailers' onChange={changeHandler}>
        {locations.map(({ title }) => (
          <option key={title}>{title}</option>
        ))}
      </Select>
      <MapContainer
        minHeight={iframeHeight}
        minHeightMobile={mobileIframeHeight}
        dangerouslySetInnerHTML={{ __html: iframe }}
      />
    </RetailMapContainer>
  );
}

RetailMap.propTypes = {
  iframeHeight: PropTypes.string,
  mobileIframeHeight: PropTypes.string,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      iframe: PropTypes.string,
    })
  ),
};
RetailMap.defaultProps = {
  iframeHeight: '60vh',
  mobileIframeHeight: '60vh',
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      title: '',
      iframe: '',
    })
  ),
};

export default RetailMap;
