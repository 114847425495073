/*
  Global gutters for Rebranding
  They are provided in pixels as raw numbers so consumers can convert them to any unit.
*/

const GUTTERS = {
  desktop: 42,
  mobile: 24
}

export default GUTTERS