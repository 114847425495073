import {
  AuthorHeroContainer,
  HeroTitle,
  HeroDescription,
  AuthorHeroWrapper,
  AuthorImageWrapper,
  AuthorDescriptionWrapper,
} from '../blog-hero.styles';
import { Overlay } from '../../hero-banner/hero-banner.styles';

import { Image as BuilderImage } from '@builder.io/react';

const BlogHeroAuthor = ({ props }) => {
  let { name, description, image } = props?.builderState?.content?.data || {};
  const {
    showOverlay,
    backgroundPositionDesktop,
    backgroundPositionMobile,
    backgroundImageDesktop,
    backgroundImageMobile,
    textColor,
    overlayOpacityLevel,
  } = props;
  return (
    name && (
      <AuthorHeroContainer
        backgroundImageDesktop={backgroundImageDesktop}
        backgroundImageMobile={backgroundImageMobile}
        showOverlay={showOverlay}
        backgroundPositionDesktop={backgroundPositionDesktop}
        backgroundPositionMobile={backgroundPositionMobile}
        isDesc={!!description}>
        <HeroTitle
          textColor={textColor}
          isAuthor={true}
          className='blog-hero-title'>
          {name}
        </HeroTitle>

        <AuthorHeroWrapper isImage={!!image} isDesc={!!description}>
          {image && (
            <AuthorImageWrapper>
              <BuilderImage
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                }}
                image={image}
              />
            </AuthorImageWrapper>
          )}
          <AuthorDescriptionWrapper>
            {description && (
              <HeroDescription isAuthor={true} isImage={!!image}>
                {description}
              </HeroDescription>
            )}
          </AuthorDescriptionWrapper>
        </AuthorHeroWrapper>

        {showOverlay && <Overlay opacity={overlayOpacityLevel} />}
      </AuthorHeroContainer>
    )
  );
};

export default BlogHeroAuthor;
