import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const DotContainer = styled('div')`
  display: flex;
`;

const Dot = styled('button')`
  content: '';
  width: 12px;
  height: 12px;
  border: 1px solid ${props => props.theme.colors.magic.base};
  border-radius: 50%;
  background-color: ${props => props.active ? props.theme.colors.magic.base : 'transparent'};
  transition: background-color 0.25s ease-in-out;
  padding: 0;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;

const DotTapArea = styled('div')`
  height: 32px;
  width: 32px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PaginationDots = ({
  length,
  activeIndex,
  setActiveIndex,
  resetDelay
}) => {
  const [dotsArray, setDotsArray] = useState([]);

  useEffect(() => {
    // Update array when the number of children changes.
    setDotsArray(new Array(length).fill('_'));
  }, [length]);

  return (
    <DotContainer>
      {dotsArray.length > 1 ? (
        dotsArray.map((_, index) => (
          <DotTapArea
            key={`bullet_${index}`}
          >
            <Dot
              active={index === activeIndex}
              onClick={() => {
                setActiveIndex(index);
                resetDelay();
              }}
              aria-label={`Go To Slide ${index + 1}`}
            />
          </DotTapArea>
        ))
      ) : null}
    </DotContainer>
  )
};

PaginationDots.propTypes = {
  length: PropTypes.number,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,
  resetDelay: PropTypes.func,
}

export default PaginationDots;
