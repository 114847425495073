import cors from 'cors';
import getConfig from 'next/config';
import initMiddleware from '../../util/init-middleware';
import { CloudFrontHeaders, CountryCodes, StateCodes } from '../../constants';

const {
  publicRuntimeConfig: {
    GF_DOTCOM_COUNTRY_OVERRIDE
  }
} = getConfig()


// Initialize the cors middleware
const corsMiddleware = initMiddleware(
  // You can read more about the available options here: https://github.com/expressjs/cors#configuration-options
  cors({
    // match any subdomain of glowforge.com, must be https
    origin: /https:\/\/.*(glowforge\.com)/,
    // Only allow requests with GET and OPTIONS
    methods: ['GET', 'OPTIONS'],
  })
);

export function readRequestLocationheaders(req) {
  // For testing with local dev country overrides
  if(GF_DOTCOM_COUNTRY_OVERRIDE) {
    return {
      country: GF_DOTCOM_COUNTRY_OVERRIDE,
      state: StateCodes.unknown
    }
  }
  return {
    country: req?.headers[CloudFrontHeaders.COUNTRY_CODE] ?? CountryCodes.US,
    state: req?.headers[CloudFrontHeaders.STATE_CODE] ?? StateCodes.unknown
  }
}

export default async function handler(req, res) {
  try {
    /**
     * This will not reject if cors fails.
     * It will just not set the access-control-allow-origin header
     * and the browser will reject the request based on cors.
     */
    await corsMiddleware(req, res);
    res.status(200).json(readRequestLocationheaders(req));
  } catch (e) {
    console.error(e);
    res.status(500)
  }
}
