import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  createContext,
} from 'react';
import { withChildren, BuilderBlocks, Builder } from '@builder.io/react/lite';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@glowforge/gf-styled/src/components/button';
import SVG from '@glowforge/gf-styled/src/components/svg/svg';

export const ArtboardContext = createContext({});

const ArtboardContainer = styled('form')`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  scroll-margin-top: 70px;

  .views {
    margin-bottom: 1rem;
    display: flex;
    gap: 1rem;
  }

  button {
    cursor: pointer;
    max-width: 245px;
    width: 100%;
    justify-content: center;
    display: inline-flex;
    font-weight: bold;
    flex-direction: row-reverse;
    .button-icon-wrapper {
      margin: 0 10px 0 0;
    }
    svg {
      stroke: white;
      fill: ${(props) => props.theme.colors.slate.lightest};
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    &.first-view {
      margin-bottom: 2rem;
      border-radius: 18px;
      border-width: 1px;
      border-color: #979797;
      color: ${(props) => props.theme.colors.slate.lighter};
      width: fit-content;
      padding-left: 5px;
    }
    &[type='submit'] {
      margin: 2rem auto 0;
    }
  }
`;

const Artboard = ({ views, builderBlock }) => {
  const [activeView, setActiveView] = useState(0);
  const element = useRef(null);
  const data = useRef({
    form: {},
    index: 0,
  });

  const scrollToElement = (entries) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        element.current?.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });
      }
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (activeView !== 1 || Builder.isEditing) return;
      setActiveView((prevView) => prevView + 1);
    }, 3000);

    if (Object.keys(data.current?.form).length) {
      const section = element?.current;
      const observer = new IntersectionObserver(scrollToElement);
      if (section) observer.observe(section);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [activeView]);

  const triggerViewChange = useCallback(() => {
    if (!views[activeView + 1]) {
      setActiveView((prevView) => prevView - 1);
    } else {
      setActiveView((prevView) => prevView + 1);
    }
  }, [activeView, views]);

  if (!views?.length) return null;

  const currentView = views?.[activeView];

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (activeView === 0) {
      data.current.form =
        Object.fromEntries(new FormData(event.target)) || data.current.form;
    }
    if (activeView === 2) {
      data.current.index += 1;
    }
    triggerViewChange();
  };

  return (
    <ArtboardContext.Provider value={data.current}>
      <ArtboardContainer onSubmit={handleFormSubmit} ref={element}>
        {Builder.isEditing ? (
          <div className='views'>
            {views.map((view, index) => (
              <Button
                key={view + index}
                type='button'
                variant={activeView === index ? 'primary' : 'ghost-blue'}
                onClick={() => setActiveView(index)}>
                View {index + 1}
              </Button>
            ))}
          </div>
        ) : null}
        {currentView?.backButton && (
          <Button
            type='button'
            className='button first-view'
            variant='ghost-blue'
            icon={<SVG id='arrow-left' noWidth />}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => setActiveView(0)}>
            {currentView?.backButton}
          </Button>
        )}
        <BuilderBlocks
          parentElementId={builderBlock.id}
          dataPath={`component.options.views.${activeView}.content`}
          blocks={currentView?.content}
        />
        {currentView?.button && (
          <Button type='submit' className='button' disabled={activeView === 1}>
            {currentView?.button}
          </Button>
        )}
      </ArtboardContainer>
    </ArtboardContext.Provider>
  );
};

Artboard.propTypes = {
  builderBlock: PropTypes.shape({
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.any),
      PropTypes.elementType,
    ]),
    id: PropTypes.string,
  }).isRequired,
  views: PropTypes.arrayOf(PropTypes.shape({})),
};

Artboard.defaultProps = {
  views: [],
};

export default withChildren(Artboard);
