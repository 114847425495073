import PropTypes from 'prop-types';
import React from 'react';
import { clientOnly } from '../../../util';
import useFeatureFlags from '../../../hooks/use-feature-flags';
import PageviewsAndTime from './pageviews-and-time';

const track = ({ name, minutes, pages }) => ({ time, pageviews }) => {
  clientOnly(() => {
    if (window.analytics) {
      window.analytics.track(name, {
        name,
        minutes,
        pages,
        time,
        pageviews,
      });
    }
  });
};

const PageviewsAndTimeAnalytics = ({ reset }) => {
  /*
   * https://app.launchdarkly.com/gf-dotcom/test/features/backflip-spider
   *
   * backflipSpider contains a list of specs for setting up PageviewsAndTime
   * components, e.g.
   * {"data": [
   *   {name: "EV6M10P", minutes: 6, pages: 10},
   *   {name: "foo", minutes: 1, pages: 5}
   * ]}
   */
  const { 'backflip-spider': backflipSpider } = useFeatureFlags();
  const specs = (backflipSpider && backflipSpider.data) || [];
  const url = clientOnly(() => window.location.href) || '';
  return (
    <>
      {specs.map(({ name, minutes, pages }) => (
        <PageviewsAndTime
          key={name}
          name={name}
          minutes={minutes}
          pages={pages}
          url={url}
          callback={track({ name, minutes, pages })}
          reset={reset} />
      ))}
    </>
  );
};
PageviewsAndTimeAnalytics.propTypes = {
  reset: PropTypes.bool,
};
PageviewsAndTimeAnalytics.defaultProps = {
  reset: false,
};

export default PageviewsAndTimeAnalytics;
