const Text = {
  '@type': '@builder.io/sdk:Element',
  children: [
    {
      component: {
        name: 'Glowforge:Text',
        options: {
          text: 'You can edit the content!',
        },
      },
    },
  ],
};

const accordionConfig = {
  name: 'Glowforge:Accordion',
  inputs: [
    {
      name: 'items',
      type: 'list',
      defaultValue: [
        {
          label: "Add your item title here",
          content: [Text],
        },
      ],
      subFields: [
        {
          name: 'label',
          type: 'string'
        },
        {
          name: 'content',
          type: 'uiBlocks',
          defaultValue: [],
        },
      ],
    },
    {
      name: 'openFirst',
      type: 'boolean',
      helperText: 'Show the content of the first item',
    },
    {
        name: 'selectMultiple',
        type: 'boolean',
        defaultValue: true,
        helperText: 'If true, allows multiple items to be open at once.',
    }
  ],
  image:
    'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fc0ab924f37694747a995072101d81978',
};

export default accordionConfig;
