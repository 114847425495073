import { join as pathJoin } from 'path';
import get from 'lodash/get';
import getConfig from 'next/config';
import { getCookie as getCookieUtil } from './cookies';

const {
  publicRuntimeConfig: {
    GF_DOTCOM_GF_API_ENDPOINT,
    GF_DOTCOM_SUB_COOKIE_NAME,
  }
} = getConfig();

function breakdownUrlForError(url) {
  let urlParams = {};
  let finalUrl = url;
  try {
    const parsedUrl = new URL(url, window.location);
    urlParams = Object.fromEntries(parsedUrl.searchParams.entries());
    parsedUrl.search = '';
    finalUrl = parsedUrl.toString();
  } catch (e) {
    // we fall back to finalUrl being the url if we can't parse it for
    // some reason
  }
  return [urlParams, finalUrl];
}

/**
 * Service errors are used when making API calls to Glowforge service.
 */
export class ServiceError extends Error {
  constructor(message, url, options, response) {
    const [urlParams, finalUrl] = breakdownUrlForError(url);
    super(`${message} ${finalUrl} responseCode: ${!response ? 'none' : response.code}`);
    this.options = options;
    this.response = response;
    this.reportAttributes = {
      ...urlParams,
      ...options,
    };
  }
}

function checkServiceError(response, url, context = {}) {
  if (!response.ok) {
    const { status, statusText } = response;
    const message = `${statusText || 'Service Error'} (${status || 500})`;
    throw new ServiceError(message, url, context, response);
  }
  return response;
}

export const reloader = {
  reload() {
    window.location.reload();
  },
};

// Make callers of `fetchApi` not have to remember leading slash or not
export function makeApiUrl(...args) {
  const base = GF_DOTCOM_GF_API_ENDPOINT;
  // pathJoin will ensure there's at most one '/' between args
  return args.length === 0 ? base : `${base}${pathJoin('/', ...args)}`;
}

function makeServiceUrl(url) {
  const isHttp = url.startsWith('http://') || url.startsWith('https://');
  return isHttp ? url : makeApiUrl(url);
}

function getCookie(ctx) {
  if (typeof document !== 'undefined') return document.cookie;
  return get(ctx, 'req.headers.cookie', '');
};

export function fetchWithoutToken(url, options = {}) {
  return fetch(makeServiceUrl(url), options);
}

/**
 * The `fetchApi` and `resetToken` functions are defined using a
 * self-executing function to keep the authorization token and its expiration
 * time inaccessible to code outside this scope. It helps ensure these sensitve
 * values can only be updated by the functions defined within this scope, and
 * ensures their integrity to the rest of gf-web.
 *
 * **DO NOT** expose these variables to the outer scope directly.
 * buildFetchContext is exposed here for server side usage
 * from a server context always build a new fetch context do not
 * use the global context, always create the new context at runtime
 */
export function buildFetchContext(context) {
  /**
   * @type {string} HTTP 'Authorization' header value
   */
  let authorization = null;
  /**
   * @type {number} When the authorization header expires, in milliseconds since
   *                the epoch.
   */
  let expiresAt = 0;

  /**
   * @type {Promise<*>} The promise for resetting the token. Will only be
   *                    non-null while the token is refreshing, and will ensure
   *                    the token is updated only once no matter how many
   *                    concurrent network calls need it at the same time.
   */
  let refreshPromise = null;

  /**
   * @type {object} Default fetch settings that can be overridden by callers
   */
  const defaultFetchOptions = {
    mode: 'cors', // By default, allow cross-origin requests
  };

  /**
   * @type {object} Fetch settings that cannot be overridden by callers
   */
  const requiredFetchOptions = {
    credentials: 'omit', // Send no cookies. Rely on authorization header.
  };


  /**
   * Updates the `authorization` and `expiresAt` variables, based on the
   * response from user-service's auth-token API. Relies on cookies
   * (specifically, the refresh token provided by user-service at login) to
   * validate the request for the authentication token.
   *
   * @async
   * @function refreshToken
   */
  const refreshToken = Object.freeze(async () => {
    const url = makeServiceUrl('/accounts/v1/auth_token');

    const cookie = getCookie(context);
    const headers = cookie ? { cookie } : {};
    // This needs to handle both client side and server side requests
    // Client side 'credentials: 'include' is sufficient, server side the cookie
    // needs to be explicitly extracted and included in the request
    const fetchOptions = { credentials: 'include', headers };

    const response = checkServiceError(await fetch(url, fetchOptions), url);
    const { token, expires } = await response.json();
    authorization = `Bearer ${token}`;
    expiresAt = expires * 1000; // "expires" is in seconds
    return expiresAt;
  });

  /**
   * Immediately marks the auth token as being expired, which will force a
   * refresh the next time an API call is made. It *does not* refresh the token
   * with this call.
   *
   * @function resetServiceApiToken
   */
  const reset = Object.freeze(() => {
    expiresAt = 0;
  });

  function getContent(response) {
    const { status, headers } = response;
    if (status === 204) {
      // 204 = no content
      return null;
    }
    if (!headers) {
      return response.json(); // Only unit tests lack headers. Assume json.
    }
    const contentType = headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return response.json();
    }
    return response.text();
  }

  const getSubscriptionToken = () => {
    // Fail if call is server side with no window/document
    if (typeof window === "undefined") return null;
    // Fail early for if we don't have the gf_sub cookie
    if (!document.cookie.includes('gf_sub')) {
      return null;
    }

    return getCookieUtil(GF_DOTCOM_SUB_COOKIE_NAME);
  };

  /**
   * Fetch the specified URL using token-based auth. The fetch does *not*
   * include cookies in the request; instead, auth credentials are passed using
   * the `Authorization` header, and any available subscription token is passed
   * using the `x-gf-sub` header.
   *
   * @async
   * @function fetchApi
   * @param {string|URL} url - The path or URL to fetch.
   * @param {Object} [options={}] - Options for constructing the fetch call
   * @param {*} options.anonymous - Set to true to make the call unauthenticated
   * @param {*} options.body - The body to add to the request
   * @param {string} options.method - The HTTP method to use. Defaults to 'POST'
   *        if a `body` is specified in the options; otherwise, 'GET'
   * @param {Object} options.headers - Any HTTP headers to add to the request
   * @returns {Promise<string|object|array>} The parsed JSON payload (if the
   *        response's content type is 'application/json'); otherwise, the text
   */
  const fetchWithToken = Object.freeze((url, options = {}) => {
    const { headers: defaultHeaders, includeAuth = true, ...restOptions } = options;
    const { body } = options;

    // Default to 'POST' method if there's a body; 'GET' otherwise. This can be
    // overridden in restOptions
    const method = body ? 'POST' : 'GET';

    // Only add a content-type if one isn't specified and there's a 'body'
    const headers = new Headers(defaultHeaders);
    if (!headers.get('content-type') && typeof body === 'string') {
      headers.set('content-type', 'application/json; charset=utf-8');
    }

    const attemptFetch = async (attempts = 0) => {
      if (includeAuth) {
        if (Date.now() > expiresAt) {
          // Multiple API calls might be triggered in a small enough time window
          // to make it into this clause before the first check completes the
          refreshPromise ||= refreshToken();

          try {
            // The promise will resolve once there's a new refresh token with an
            // updated expiration time.
            await refreshPromise;
          } finally {
            // Clear the promise now that there's a new token + expiration time,
            // so that when it expires, a new auth token can be acquired.
            refreshPromise = null;
          }
        }

        // Ensure the current authorization headers are applied.
        headers.set('authorization', authorization);
        headers.set('x-gf-sub', getSubscriptionToken());
      }

      const response = await fetch(makeServiceUrl(url), {
          ...defaultFetchOptions,
          method,
          headers: Object.fromEntries(headers.entries()), // for testing purposes
          ...restOptions,
          ...requiredFetchOptions,
        });

      // Allow one retry if the response failed because it was unauthorized.
      if (response.status === 401 && attempts === 0) {
        // Reset the auth token so the next attempt is sure to fetch a new one.
        reset();
        return attemptFetch(attempts + 1);
      }

      // `checkServiceError` throws if the response is an error.
      return getContent(checkServiceError(response, url, { ...options, attempts }));
    };
    return attemptFetch();
  });

  return { fetchWithToken, reset };
}

export const { fetchWithToken: fetchApi, reset: resetToken } = Object.freeze(buildFetchContext());
