import React, { useEffect } from 'react';

const CookieAnalytics = () => {
  useEffect( () => {
    if(!window.analytics) return;
    window.analytics.track('Client Cookie Byte Length',
    {
      cookieByteLength: document.cookie.length,
      url: window.location.href.toString().split(window.location.host)[1]
    })
  }, [])

  // FYI, Not the best pattern for adding analytics, but just following what's
  // here for the moment.  We should consider refactoring all of this code to
  // just be hooks that we load into the _app.jsx somehow.
  // eslint-disable-next-line
  return <></>;
}

export default CookieAnalytics