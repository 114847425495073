import { allowedColors } from '@glowforge/gf-styled/src/theme/constants/colors';

export default {
  name: 'Glowforge:Card',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F98b19e97a3b349d48beea02e899c3afc?quality=60&width=200&height=200',
  inputs: [
    {
      name: 'variant',
      type: 'string',
      enum: ['primary', 'hoverOnly'],
      defaultValue: 'primary',
    },
    {
      name: 'overflowHidden',
      type: 'boolean',
      defaultValue: true,
    },
    {
      friendlyName: 'Background Color',
      name: 'backgroundColor',
      type: 'string',
      enum: allowedColors,
      defaultValue: 'white',
    },
    {
      name: 'newWindow',
      type: 'boolean',
      defaultValue: false,
    },
    {
      friendlyName: 'Pointer on hover?',
      name: 'hoverPointer',
      type: 'boolean',
      defaultValue: true,
    }
  ],
  defaultStyles: {
    'text-decoration': 'none',
    color: 'black',
    width: '400px',
    'max-width': '100%',
  },
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: {
        name: 'Image',
        options: {
          image:
            'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fb38cbf61e22148aaad438fbf569e7829?width=500',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          lazy: false,
          fitContent: true,
        },
      },
      responsiveStyles: {
        large: {
          position: 'relative',
          minHeight: '20px',
          minWidth: '20px',
          overflow: 'hidden',
        },
      },
    },
    {
      '@type': '@builder.io/sdk:Element',
      children: [
        {
          component: {
            name: 'Glowforge:Text',
            options: {
              text: 'You can edit the contents of this example!',
            },
          },
        },
      ],
      responsiveStyles: {
        large: {
          position: 'relative',
          height: 'auto',
          paddingBottom: '20px',
          paddingTop: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
        },
      },
    },
  ],
};
