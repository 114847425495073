import styled from 'styled-components'

/* 
  The `VisuallyHidden` component allows us to signal intent to screen reader users,
  without breaking visual flow for sighted users.

  An example usage of this would be to add this component to a link that only uses an icon.

  <-- The following is not valid gf-styled code, serving as an example only -->
  <a href='#'>
    <VisuallyHidden>Settings</VisuallyHidden>
    <Icon id='cog'/>
  </a>

  For more information see: https://www.joshwcomeau.com/snippets/react-components/visually-hidden/
*/

const VisuallyHidden = styled('span')`
  &&&& {
    display: inline-block;
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1;
    width: 1;
    margin: -1;
    padding: 0;
    border: 0;
  }
`;

export default VisuallyHidden
