import { allowedColors } from "@glowforge/gf-styled/src/theme/constants/colors";

export const backgroundPositions = [
  'center',
  'top center',
  'bottom center',
  'right',
  'right top',
  'right bottom',
  'left',
  'left top',
  'left bottom',
];

export const backgroundSizes = ['auto', 'cover', 'contain'];

export const heroHeights = ['100vh', '75vh', '50vh', '33vh', '25vh'];

export const backgroundRepeats = [
  'no-repeat',
  'repeat',
  'repeat-x',
  'repeat-y',
  'initial',
  'inherit',
];

export default {
  name: 'Hero Banner - V2',
  friendlyName: 'Hero Banner - V2',
  // shows options when editing children
  broadcast: true,
  noWrap: true,
  defaultStyles: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  image:
    'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fb082775cbfbb4ff595fb9a5fc5dc45c1?quality=60&width=200&height=200',
  inputs: [
    {
      name: 'youtube',
      type: 'object',
      subFields: [
        {
          name: 'desktop',
          type: 'url',
          helperText: "Leave empty to use the video settings below.",
        },
        {
          name: 'mobile',
          type: 'url',
          helperText: "Leave empty to use the video settings below.",
        },
        {
          name: 'isVerticalVideo',
          type: 'boolean',
          defaultValue: true,
        }
      ],
    },
    {
      name: 'background',
      helperText: 'Select background image.',
      type: 'file',
      allowedFileTypes: ['png', 'jpg'],
      showIf: (options) => !options.get('isVideo'),
    },
    {
      name: 'background',
      helperText: 'Select background video.',
      allowedFileTypes: ['mp4'],
      type: 'file',
      showIf: (options) => options.get('isVideo'),
    },
    {
      name: 'isVideo',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'backgroundMobile',
      helperText:
        'Select background image.',
      type: 'file',
      allowedFileTypes: ['png', 'jpg'],
      showIf: (options) => !options.get('isVideoMobile'),
    },
    {
      name: 'backgroundMobile',
      helperText: 'Select background video.',
      allowedFileTypes: ['mp4'],
      type: 'file',
      showIf: (options) => options.get('isVideoMobile'),
    },
    {
      name: 'isVideoMobile',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'heroHeightDesktop',
      type: 'string',
      defaultValue: heroHeights[1],
      enum: heroHeights,
    },
    {
      name: 'heroHeightMobile',
      type: 'string',
      defaultValue: heroHeights[0],
      enum: heroHeights,
    },
    {
      name: 'textColor',
      // eslint-disable-next-line max-len
      helperText: 'default color for text inside HeroBanner. Can be overridden by individual components.',
      type: 'string',
      defaultValue: 'white',
      enum: allowedColors,
    },
    {
      name: 'backgroundPosition',
      helperText: 'This only works for images, not videos.',
      type: 'string',
      defaultValue: backgroundPositions[0],
      enum: backgroundPositions,
    },
    {
      name: 'backgroundPositionMobile',
      type: 'string',
      defaultValue: backgroundPositions[0],
      enum: backgroundPositions,
    },
    {
      name: 'backgroundSize',
      type: 'string',
      defaultValue: backgroundSizes[1],
      enum: backgroundSizes,
    },
    {
      name: 'backgroundSizeMobile',
      type: 'string',
      defaultValue: backgroundSizes[1],
      enum: backgroundSizes,
    },
    {
      name: 'backgroundRepeat',
      type: 'string',
      defaultValue: backgroundRepeats[0],
      enum: backgroundRepeats,
    },
    {
      name: 'backgroundRepeatMobile',
      type: 'string',
      defaultValue: backgroundRepeats[0],
      enum: backgroundRepeats,
    },
    {
      name: 'showOverlay',
      type: 'boolean',
      defaultValue: true,
    },
  ],
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      '@version': 2,
      component: {
        name: 'Glowforge:Section',
        options: {
          maxWidth: 1200,
          backgroundColor: 'backgrounds.none',
        },
      },
      responsiveStyles: {
        large: {
          height: '100%',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '20px',
          paddingBottom: '20px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        },
        medium: {
          paddingTop: '70px',
        },
      },
      children: [
        {
          '@type': '@builder.io/sdk:Element',
          responsiveStyles: {
            large: {
              marginBottom: 'auto',
              marginTop: 'auto',
            },
          },
          children: [
            {
              '@type': '@builder.io/sdk:Element',
              component: {
                name: 'Glowforge:Text',
                options: {
                  variant: 'title',
                  text: 'Heading',
                },
              },
              responsiveStyles: {
                large: {
                  paddingBottom: '20px',
                },
              },
            },
            {
              '@type': '@builder.io/sdk:Element',
              component: {
                name: 'Glowforge:Text',
                options: {
                  variant: 'subheading',
                  text: 'Subheading',
                },
              },
              responsiveStyles: {
                large: {
                  paddingBottom: '20px',
                },
              },
            },
            {
              '@type': '@builder.io/sdk:Element',
              component: {
                name: 'Glowforge:Button',
                options: {
                  text: 'Click Me',
                  variant: 'primary',
                },
              },
              responsiveStyles: {
                large: {
                  marginRight: 'auto',
                },
              },
            },
          ],
        },
      ],
    },
  ],
};
