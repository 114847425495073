import { Builder, builder } from '@builder.io/react';

builder.init('69fac0673d2644f0a0a962e1cb3045ef');

/** Sane fallbacks if data fetching fails for some reason */
const defaults = {
  navItems: [
    {
      text: 'Products',
      url: '/b/products',
    },
    {
      text: 'Experiences',
      url: '/business/home',
    },
    {
      text: 'Learn',
      url: '/learn/learn-discover',
    },
    {
      text: 'Support',
      url: '/b/support',
    },
    {
      url: 'Contact Us',
      text: '/b/sales',
    },
  ],
  buttonSection: [
    {
      '@type': '@builder.io/sdk:Element',
      '@version': 2,
      id: 'builder-4dca09944e5241c880b438f124be2200',
      component: {
        name: 'Glowforge:Button',
        options: {
          variant: 'primary',
          text: 'Buy Glowforge',
          actionButton: false,
          link: '/products#meet-the-family',
          openLinkInNewTab: false,
          addIcon: true,
          chooseCommonIcon:
            'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fb08ad2d1eee44230b8f3675858105e12',
          iconSize: 24,
          noMobileBoxShadow: true,
        },
      },
    },
  ],
  logoLink: '/',
};

/**
 * Trim response data. Helps with page bloat
 * @param {Object} data Builder.io data returned from builder.get('navbar')
 * @returns {Object} simplified object with only rendering data for nav.
 */
export function cleanNavData(data = {}) {
  return {
    navItems: data?.navItems ?? defaults.navItems,
    // legacy compatibility with desktop-user-nav-popover.jsx.
    // current implementation only uses userNavItems when a user is signed in
    // based on diff builder.io entries for signed out/signe in nav
    userNavItems:
      data?.userNavItems?.map((route) => ({
        ...route,
        type: 'signed-in',
      })) ?? [],
    logoLink: data?.logoLink ?? defaults.logoLink,
    buttonSection:
      data?.buttonSection?.value?.data?.blocks ?? defaults.buttonSection,
    previewUserLoggedIn: data?.previewUserLoggedIn,
  };
}

// eslint-disable-next-line import/prefer-default-export
export async function getNavbar(opts = {}) {
  const { loggedIn = false, hasMachine = false } = opts;
  const links = await builder
    .get('navbar', {
      includeRefs: true,
      preview: Builder.isPreviewing || Builder.isEditing,
      // You can use options for queries, sorting, and targeting here
      // https://github.com/BuilderIO/builder/blob/main/packages/core/docs/interfaces/GetContentOptions.md
      userAttributes: Builder.isEditing
        ? undefined
        : {
            loggedIn,
            hasMachine,
          },
    })
    .promise();

  return cleanNavData(links?.data);
}
