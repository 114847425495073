import styled from 'styled-components'

export const Wrapper = styled('div')`
  position: relative;
  padding: 0;

  @media ${props => props.theme.queries.desktopAndUp} {
    padding: 0 2rem;
  }
`

export const ScrollWrapper = styled('div')`
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: column;
  overflow-x: scroll;
  scroll-behavior: smooth;
  padding: 1rem 2rem;
  // Safari mobile, allows horizontal scrolling to be smooth
  -webkit-overflow-scrolling: touch;

  // scrollbar-width is at risk of not making it as an official rule
  // https://github.com/w3c/csswg-drafts/issues/1958
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
  /* stylelint-enable */

  // Chrome/Safari
  &::-webkit-scrollbar {
    display: none;
  }

  // This provides extra padding on mobile at end of scrollable content
  &::after {
    display: block;
    content: "";
    width: 1px;
    height: 100%;

    @media ${props => props.theme.queries.desktopAndUp} {
      display: none;
    }
  }
`

export const ArrowWrapper = styled('div')`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  height: 100%;
  left: ${props => props.back ? 0 : ''};
  right: ${props => props.back ? '' : 0};
  transition: all 0.2s ease-out;

  opacity: ${props => props.isVisible ? 1 : 0};
  visibility: ${props => props.isVisible ? 'visible' : 'hidden'};

  @media ${props => props.theme.queries.desktopDown} {
      display: ${props => props.hideNavigationArrowsMobile && 'none'};
  }
  svg {
    transform: scale(1.5) ${props => props.back ? 'rotate(180deg)' : ''};
    fill: ${props => props.theme.colors.white};
    stroke: ${props => props.theme.colors.white};
  }
`

export const ArrowButton = styled('button')`
  height: 64px;
  width: 64px;
  background-color: ${props => 
    props.theme.utils.getHexOpacity(props.theme.colors.black, 50)};
  border-radius: 50%;
  border: none; // remove user-agent styling
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media ${props => props.theme.queries.desktopDown} {
    width: 40px;
    height: 40px;

    & svg{
      width: 12px;
      height: 12px;
      margin: ${(props) => (props.direction === 'forward' ? '0 0 0 2px' : '0 2px 0 0')};
    }
  }
`