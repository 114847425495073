import get from 'lodash/get'
import map from 'lodash/map'
import find from 'lodash/find'
import filter from 'lodash/filter'
import reduce from 'lodash/reduce'
import PropTypes from 'prop-types';
import { contentfulMetadataPropType } from './page-metadata';

/**
 * TODO: https://glowforge.atlassian.net/browse/STORE-2854 Wrangle Contentful Terminology
 *  - Merge naming for ContentFields and Sections, they both refer to a list of
 *    Contentful Entries, or, Widgets.
 *  - Consider making a class like models/widget.js
 */

/**
 * The Model for a contentful Page.
 * https://app.contentful.com/spaces/ljtyf78xujn2/content_types/page/fields
 */
export const pagePropType = {
  id: PropTypes.string,
  internalName: PropTypes.string,
  metaDescription: PropTypes.string,
  pageTitleText: PropTypes.string, // Deprecated, use pageMetadata instead.
  pageType: PropTypes.string,
  previewText: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.object),
  slug: PropTypes.string,
  title: PropTypes.string,
  unorderedShortText: PropTypes.arrayOf(PropTypes.object),
  pageMetadata: PropTypes.shape(contentfulMetadataPropType),
};

export const pageStringsPropType = PropTypes.objectOf(PropTypes.string);

/**
 * Helpers for retrieving content from an object that adheres to the
 *  'pagePropType' model.
 */

export const getContentType = object => get(object, 'sys.contentType.sys.id');

/**
 * Gets text strings for a page.  basicTextString is non-markdown text.
 * @param {Object} page See pagePropType
 */
export const getStringsForPage = (page = {}) => (
  reduce(page.unorderedShortText, (all, string) => {
    if (getContentType(string) === 'basicTextString') {
      // eslint-disable-next-line no-param-reassign
      all[get(string.fields, 'id')] = get(string.fields, 'text');
    }
    return all;
  }, {})
);

/**
 * Gets markdown text strings for a page.  textString is markdown text.
 * @param {Object} page See pagePropType
 */
export const getMarkdownStringsForPage = (page = {}) => (
  reduce(page.unorderedShortText, (all, string) => {
    if (getContentType(string) === 'textString') {
      // eslint-disable-next-line no-param-reassign
      all[get(string.fields, 'id')] = get(string.fields, 'text');
    }
    return all;
  }, {})
);

/**
 * Load a specific section from a page.
 * We require type because IDs are only unique to a type
 * @param {Object} page See pagePropType
 * @param {String} id
 * @param {String} type
 */
export const getSectionForPageByIdAndType = (page, id, type) => (
  get(find(page.sections, {
    fields: { id },
    sys: {
      contentType: {
        sys: {
          id: type,
        },
      },
    },
  }), 'fields')
);

/**
 * From a list of widgets/content, filter by type. This returns an object with
 * 'fields' and 'sys' properties. Useful for mapping content to widgets with
 * 'getWidget(obj)' (See ui-common/components/contentful-views/sections.jsx)
 * @param {Array} contentList Array of Widgets/Content, a Contentful list
 * @param {String} type The type of the content model to retrieve from
 *  Contentful
 */
export const getContentByType = (contentList, type) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  filter(contentList, { sys: { contentType: { sys: { id: type } } } });

/**
 * This does the same thing as getContentByType, but for a Page
 * @param {Object} page A mixture of Contentful Data and React props.
 * @param {String} type The type of the content model to retrieve from
 *  Contentful
 */
export const getSectionsForPageByType = (page, type) => getContentByType(page.sections, type);

/**
 * From a list of widgets/content, filter by type and extract the 'fields'
 *  property.
 * @param {Array} contentList Array of Widgets/Content, a Contentful list
 * @param {String} type The type of the content model to retrieve from
 *  Contentful
 */
export const getContentFieldsByType = 
  (contentList, type) => map(getContentByType(contentList, type), 'fields');
