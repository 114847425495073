/**
 * Route Constants
 * File to hold constants related to routing.
 */
export default {
  PAGE_ERRORS: {
    PAGE_NOT_FOUND: 'PAGE_NOT_FOUND',
    NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
    FORBIDDEN: 'FORBIDDEN',
  },
};
