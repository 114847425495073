import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getTypographyVariant } from '../../typography/Typography';

import Link from '../../link';

export const Nav = styled('nav')`
  width: 100%;
  display: ${props => props.desktopOnly ? 'none' : 'flex'};
  flex-direction: row;
  grid-column: main-start / main-end;
  justify-content: space-between;

  @media ${props => props.theme.queries.navbarBreakpoint} {
    display: ${props => props.desktopOnly ? 'flex' : 'none'};
  }
`

export const NavUL = styled('ul')`
  display: flex;
  flex-direction: column;
  padding: 0 ${24 / 16}rem ${24 / 16}rem;

  @media ${props => props.theme.queries.navbarBreakpoint} {
    padding: 0;
    flex-direction: row;
    align-items: center;
  }

  /* Give the li items a bit of breathing room. */
  li {
    padding-top: 12px;

    @media ${props => props.theme.queries.navbarBreakpoint} {
      padding-top: 0;
      padding-left: 32px;
    }
  }
`

NavUL.propTypes = {
  expandMobileMenu: PropTypes.bool,
}

export const NavHR = styled('hr')`
  border: 1px solid ${props => props.theme.colors.slate.lightest};
`

export const NavSection = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const DesktopSection = styled('div')`
  display: none;
  @media ${props => props.theme.queries.navbarBreakpoint} {
    display: block;
  }
`

export const MobileSection = styled('div')`
  display: block;
  @media ${props => props.theme.queries.navbarBreakpoint} {
    display: none;
  }
`

export const NavLink = styled(Link)`
  position: relative;
  --font-weight: 500;
  color: ${props => props.$active ? props.theme.colors.magic.base : props.theme.colors.black};
  fill: ${props => props.$active ? props.theme.colors.magic.base : ''};
  @media ${(props) => props.theme.queries.navbarBreakpointDown} {
    ${getTypographyVariant('subheading')}
    /* make link stretch full-width to increase click area */
    display: block;
  }

  &:hover {
    fill: ${props => props.theme.colors.magic.base};
  }
`;

export const DropdownIcon = styled("div")`
  border: none;
  background: transparent;
  padding: 0;
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;

  @media ${props => props.theme.queries.navbarBreakpointDown} {
    width: 100%;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    padding-top: 5px;
    align-items: flex-start;
    height: 100%;
  }
  svg {
    transition: all .2s ease;
    @media ${props => props.theme.queries.navbarBreakpointDown} {
      width: 15px;
      height: 15px;
    }
  }
`

export const Dropdown = styled("div")`

  @media ${props => props.theme.queries.navbarBreakpointDown} {
    width: 100%;
    max-height: 0;
    overflow: hidden;
  }

  @media ${props => props.theme.queries.navbarBreakpoint} {
    position: absolute;
    width: max-content;
    min-width: 200px;
    top: 100%;
    pointer-events: none;
  }

`

export const DropdownItems = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 10px 5px 10px;
  min-width: max-content;

  @media ${props => props.theme.queries.navbarBreakpointDown} {
    a {
      font-size: 18px;
    }
  }

  @media ${props => props.theme.queries.navbarBreakpoint} {
    gap: 5px;
    padding: 0 15px 15px 15px;
    margin: 0;
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0 0.9rem 0.9375rem #00000033;
    margin-top: 15px;
    opacity: 0;
    transform: translateY(-1.5rem);
    transition: transform .2s ease, opacity .1s ease;
  }
`

export const DropdownContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  line-height: normal;
  position: relative;

  // Mobile dropdowns based on click.
  @media ${props => props.theme.queries.navbarBreakpointDown} {
    position: relative;
    .main-link{
      &[href]{
        z-index: 1;
      }
      .icon{
        display: none;
      }
    }
    ${(props) =>
      props.active
        ? ` svg {
                  transform: rotate(180deg);
              }
              .dropdown-wrapper{
                  max-height: 100%;
              }
            `
        : ''
    }
  }

  // Mobile dropdowns based on hover.
  @media ${props => props.theme.queries.navbarBreakpoint} {
    .main-link + .icon{
      display: none;
    }
    &:hover {
      .icon {
        svg{
          fill: ${props => props.theme.colors.magic.base};
          stroke: ${props => props.theme.colors.magic.base};
          transform: rotate(180deg);
        }
      }
      .main-link {
        color: ${props => props.theme.colors.magic.base};
      }
      .dropdown-wrapper {
        pointer-events: all;
        .dropdown-items{
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

`
