/**
 * MetaTag Helper class.  Creates an array of tags for 
 *  React-Helmet to inject in to the page.
 * react-isomorphic-render injects meta, link, and 
 *  title tags in to the head on the server side.
 * It does not inject script tags. See:
 * https://github.com/catamphetamine/react-website/blob/11/source/page-server/html.js#L57
 */
export default class MetaTags {
  constructor() {
    this.tags = [];
  }

  push(meta) {
    if (meta.content) {
      this.tags.push(meta);
    }
    return this;
  }

  pushAll(metaArray) {
    metaArray.forEach((meta) => this.push.bind(this, meta))
    return this;
  }

  name(name, content) { return this.push({ name, content }); }

  property(property, content) { return this.push({ property, content }); }

  twitter(name, content) { return this.name(`twitter:${name}`, content); }

  openGraph(name, content) { return this.property(`og:${name}`, content); }

  // Deprecated - Facebook now reads OpenGraph metadata tags
  facebook(name, content) { return this.property(`fb:${name}`, content); }

  // Deprecated - 
  // Pinterest now reads (slightly non-standard) OpenGraph metadata tags
  pinterest(name, content) { return this.name(`p:${name}`, content); }

  // The following helpers are designed to 
  // support the Page Metadata content type in Contentful
  title(value) { return this.twitter('title', value).openGraph('title', value); }

  description(value) {
    return this
      .name('description', value)
      .twitter('description', value)
      .openGraph('description', value);
  }

  image(value) { return this.twitter('image', value).openGraph('image', value); }
}
