/* eslint-disable max-len */
const idConfig = {
  name: 'dataTestId',
  type: 'string',
  helperText: 'For Dev Use - Adds an id to the top level of a custom component for easy testability',
};

const addIdAttribute = id => id ? { 'data-test-id': id } : null;

export {
  addIdAttribute,
  idConfig,
};
