import styled from 'styled-components';
import { m } from 'framer-motion';
import {
  getTypographyVariant,
} from '@glowforge/gf-styled/src/components/typography';




const PillButtonWrapper = styled('div')`
  padding: 0 20px 50px 20px;
  grid-column: full-start / full-end;
  display: flex;
  flex-direction: row;
  justify-content: center;
  white-space: nowrap; /* Don't wrap buttons. */
  padding-bottom: 34px;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 20px;
  @media ${(props) => props.theme.queries.desktopDown} {
    justify-content: flex-start;
    overflow-x: auto;
    flex-wrap: nowrap;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const PillButton = styled('button')`
  max-width: 100px;
  min-width: 100px;
  min-height: 34px !important;
  font-size: 16px !important;
  padding: 0;
  color: black;
  letter-spacing: -0.8px !important;
  line-height: 6.3px;
  flex: 1;
  border: 2px solid rgb(229, 229, 229,1);
  border-radius: 160px;
  background: ${(props) =>
    // no idea where this color came from or why it isn't in design system.
    props.active ? 'rgba(39,184,206,1)' : 'rgb(229, 229, 229,1)'};
  color: ${(props) =>
    props.active ? '#fff ' : props.theme.colors.slate.base};


  transition: background 0.2s ease-in-out;
  ${getTypographyVariant('paragraph')}
  --font-family: 'Space Grotesk', sans-serif;
  --font-weight: 700;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.magic.buttonBase};
    color: #fff;
  }
  @media ${(props) => props.theme.queries.desktopDown} {
    background: ${(props) =>
    // no idea where this color came from or why it isn't in design system.
    props.active ? 'rgba(39,184,206,1)' : 'transparent'};
`;

const ImageFlexContainer = styled('div')`
  display: flex;
  position: relative;
  max-width: 100%;
`;

const LeftWrapper = styled('div')`
    position: absolute;
    width: 12%;
    height: 100%;
    transition: all 1s ease;
    opacity: ${(props) => (props.isSectionVisible ? '1' : '0')};
    transition-delay: 0.1s;
    transform: translateX(${(props) => (props.isSectionVisible ? '0' : '-100%')});
    @media ${(props) => props.theme.queries.desktopDown} {
  display: none;
`;

const RightContainer = styled('div')`
    position: absolute;
    overflow: hidden;
    z-index: -1;
    width: 100%;
    min-height: 1200px;
    height: 100%;
    @media ${(props) => props.theme.queries.desktopDown} {
      display: none;
    }
`

const RightWrapper = styled('div')`
    position: absolute;
    right:0;
    width: 10%;
    height: 100%;
    transition: all 1s ease;
    opacity: ${(props) => (props.isSectionVisible ? '1' : '0')};
    transform: translateX(${(props) => (props.isSectionVisible ? 0 : '100%')});
    transition-delay: 0.3s;
    @media ${(props) => props.theme.queries.desktopDown} {
      display: none;
    }
`;

const getWidth = (imagesCount) => {
  if (imagesCount >= 7) return '60%';
  else if (imagesCount >= 5) return '50%';
  return '35%';
};

const getGridTemplateAreas = (imagesCount) => {
  if (imagesCount >= 7) {
    return `'img0 img1 img2 img3'
            'img4 img5 img6 img7'`;
  }
  else if (imagesCount >= 5) {
    return `'img0 img1 img2'
            'img3 img4 img5'`;
  }
  else {
    return `'img0 img1'
            'img2 img3'`
  }
}


const MultiImageWrapper = styled('div')`
margin: 0 auto;
margin-top: ${(props => props.selectMaterialsDesktop ? '30px' : '50px')};
transform: translateY(${(props) => (props.isSectionVisible ? 0 : "15%")});
opacity: ${(props) => (props.isSectionVisible ? '1' : '0')};
transition: all 1s ease;
width: ${(props) => getWidth(props.imagesCount)};
grid-column: main-start / main-end;
display: grid;
grid-gap: 10px;
grid-auto-columns: 1fr;
grid-auto-flow: column;
grid-template-areas: ${(props) => getGridTemplateAreas(props.imagesCount)};
padding-bottom: 42px;
justify-content: center;

img,
picture {
  ${(props) => (props.isBuilder ? 'display: block;' : '')}
  ${(props) => (props.isBuilder ? 'width: 100%;' : '')}
  ${(props) => (props.isBuilder ? 'height: 100%;' : '')}
}

@media ${(props) => props.theme.queries.desktopAndUp} {
  ${(props) =>
  props.isBuilder
    ? `grid-column: full-start / full-end;`
    : 'grid-column: main-start / main-end;'}

  ${(props) =>
  props.isBuilder ? 'padding-left: 20px; padding-right: 20px;' : null}
  justify-content: unset;
  justify-items: center;
  grid-template-areas: ${(props) => getGridTemplateAreas(props.imagesCount)};
}
@media ${(props) => props.theme.queries.desktopDown} {
  width: 100%;
  padding: 0 20px;
  grid-gap: 10px;
  margin-top: 0;
  grid-template-areas: ${getGridTemplateAreas(4)};
}
`;

const SidePicture = styled('div')`
width: 100%;
height: 100%;
`;

const MultiPicture = styled(m.div)`
grid-area: ${(props) => `img${props.index}`};
width: 100%;
aspect-ratio: 1/1;
display: inline-block;
opacity: 0;
border-radius: 5px;
overflow: hidden;

@media ${(props) => props.theme.queries.desktopAndUp} {
  display: block;
}
@media ${(props) => props.theme.queries.desktopDown} {
    &.multipicture-4,
    &.multipicture-5,
    &.multipicture-6,
    &.multipicture-7,
    &.multipicture-8 {
      display: none;
    }
}
`;

const ButtonWrapper = styled('div')`
  grid-column: main-start / main-end;
  display: flex;
  justify-content: center;
`;

export {
  PillButtonWrapper,
  PillButton,
  ButtonWrapper,
  ImageFlexContainer,
  MultiImageWrapper,
  MultiPicture,
  LeftWrapper,
  RightWrapper,
  SidePicture,
  RightContainer,
};
