import React from 'react';
import PropTypes from 'prop-types';
import { Img } from 'react-image';
import PlaceholderImage from '../placeholder-image';

/**
 * Render an image media type from Contentful.
 *
 * If passed `lazy` will render it as lazy loaded.
 *
 * It utilizes the Contentful Image API to retrieve a low quality
 * placeholder image.
 * This prevents content from jumping around when users scroll and
 * images have not yet loaded.
 * The low quality image also is Server Side Rendered.
 */
// eslint-disable-next-line react/prefer-stateless-function
class Image extends React.PureComponent {
  render() {
    const { className, height, lazy, media, width, imageProps, quality, src, placeholder, alt } =
      this.props;

    const queryPropsMap = {
      ...(width ? { w: width } : {}),
      ...(height ? { h: height } : {}),
      // If a quality is provided, convert to a progressive jpg
      ...(quality ? { q: quality, fm: 'jpg', fl: 'progressive' } : {}),
    };

    const queryProps = String(new URLSearchParams(queryPropsMap));

    let source = src;

    if (typeof media === 'string') {
      source = `${media}?${queryProps}`;
    } else if (media?.fields?.file?.url) {
      source = `${media.fields.file.url}?${queryProps}`;
    }

    const altText = media?.fields?.file?.title || alt || ''

     const loader = placeholder || (
      <PlaceholderImage
        className={className}
        alt={altText}
        width={width}
        height={height}
      />
    );

    const isSvg = /.svg/.test(source);

    if (lazy) {
      return (
        <img
          loading='lazy'
          className={className}
          src={source}
          alt={altText}
        />
      );
    }
    return (
      <Img
        className={className}
        src={source}
        loader={loader}
        alt={altText}
        decode={!isSvg}
        {...imageProps}
      />
    );
  }
}

export const imagePropTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  placeholder: PropTypes.elementType,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lazy: PropTypes.bool,
  media: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      fields: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
          title: PropTypes.string,
        }),
      }),
      sys: PropTypes.shape({}),
    }),
  ]),
  quality: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imageProps: PropTypes.shape({}),
};

export const imageDefaultProps = {
  className: '',
  src: '',
  alt: '',
  placeholder: null,
  height: null,
  lazy: true,
  media: {},
  quality: null,
  width: null,
  imageProps: {},
};

Image.propTypes = imagePropTypes;
Image.defaultProps = imageDefaultProps;

export default Image;
