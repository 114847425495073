import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ModalPriorityContext from './ModalPriorityContext';

/**
 * Allows components to specify modal priority globally. This allows us to avoid
 * conflicts between different modals, for example we don't want to show the
 * signup modal and the referral modal at the same time.
 * @param {object} props
 * @param {React.ReactNode} props.children
 */
const ModalPriorityProvider = ({ children }) => {
  // Need to use a ref for modal state to avoid closing over stale state in
  // consumer callbacks
  const modalsRef = useRef({});
  const [modals, setModals] = useState({});

  /**
   * Registers a modal globally. If a higher priority modal already exists, the
   * modal will not open. If the modal has a higher priority than existing
   * modals, those modals will be closed
   * @param {string} id Modal ID
   * @param {number} priority Modal priority
   */
  const openModal = useCallback((id, priority = 0) => {
    const existingModalArray = Object.values(modalsRef.current);
    const newModal = { id, priority };
    const newModals = { [newModal.id]: newModal };

    for (let i = 0; i < existingModalArray.length; i += 1) {
      const existingModal = existingModalArray[i];
      if (existingModal.priority > newModal.priority) {
        return;
      }
      if (existingModal.priority === newModal.priority) {
        newModals[existingModal.id] = existingModal;
      }
    }
    modalsRef.current = newModals;
    setModals(newModals);
  }, [modalsRef, setModals]);

  /**
   * Remove modal from global registry
   * @param {Remove} id Modal ID
   */
  const closeModal = useCallback((id) => {
    const newModals = { ...modalsRef.current };
    delete newModals[id];
    modalsRef.current = newModals;
    setModals(newModals);
  }, [modalsRef, setModals]);

  /**
   * Check whether a modal with the given ID is open
   * @param {string} id Modal ID
   */
  const isModalOpen = useCallback((id) => !!modals[id], [modals]);

  return (
    <ModalPriorityContext.Provider value={{
      modals,
      isModalOpen,
      openModal,
      closeModal,
    }}>
      {children}
    </ModalPriorityContext.Provider>
  );
};

ModalPriorityProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalPriorityProvider;
