import styled, { css } from 'styled-components'
import variant from '@styled-system/variant'


/**
 * @typedef {'title'|'heading'|'subheading'|'header4'|'caption'|'paragraph'} TypographyVariant
 * 
 * 
 * @param {TypographyVariant} variant Typography variant
 * @returns 
 */
 export const getTypographyVariant = (variant) => css`
 ${(props) => props.theme.typography.baseCSS}
 ${(props) => props.theme.typography.variants({ variant })}
`

/**
 * These Components represent the typographic elements in the gf-styled design system.
 * They are all individually created components, rather than composed (via styled(BASE_COMPONENT))
 * The reason for this is so that way consumers can utilize the `as` prop, instead of `forwardAs`
 * This will reduce cognitive load in deploying these.
 * 
 * Each typographic Component has access to the following props:
 * @property {TypographyVariant} variant -
 *  Defines the css font variants for a given Component.
 * @property {HTMLElement} as - changes the semantic DOM node that this component renders.
 */

const BaseTypographyComponent = styled('div').attrs(props => ({
  className: `gf_styled__${props.variant}`
}))`
  ${props => props.theme.typography.baseCSS}
  ${variant({
    scale: 'typography'
  })}
`

const Title = styled(BaseTypographyComponent)`
`
Title.defaultProps = {
  variant: 'title',
}

const Heading = styled(BaseTypographyComponent)`
`
Heading.defaultProps = {
  variant: 'heading',
}

const Subheading = styled(BaseTypographyComponent)`
`
Subheading.defaultProps = {
  variant: 'subheading',
}

const Header4 = styled(BaseTypographyComponent)`
`
Header4.defaultProps = {
  variant: 'header4',
}

const Caption = styled(BaseTypographyComponent)`
`
Caption.defaultProps = {
  variant: 'caption',
}

const Paragraph = styled(BaseTypographyComponent)`
`
Paragraph.defaultProps = {
  variant: 'paragraph',
}

export {
  BaseTypographyComponent,
  Title,
  Heading,
  Subheading,
  Header4,
  Caption,
  Paragraph,
};
