import React from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';

import HR from '../../hr';
import { NavLink } from '../shared/top-nav.styles';

const {
  publicRuntimeConfig: { GF_DOTCOM_USER_SERVICE_ENDPOINT },
} = getConfig();

const MobileUserNavSection = ({
  user = {},
  userFetchState = 'pending',
  routes = [],
  activeLink,
}) => {
  const noUser = Object.keys(user).length === 0;
  const isPending = userFetchState === 'pending';

  const signedInRoutes = routes?.filter((route) => route?.type === 'signed-in');

  return noUser || isPending ? (
    <li>
      <NavLink
        $isPending={isPending}
        href={`${GF_DOTCOM_USER_SERVICE_ENDPOINT}/users/sign_in`}>
        Sign In
      </NavLink>
    </li>
  ) : (
    signedInRoutes?.map((route) => (
      <li key={route?.url}>
        <NavLink href={route?.url} active={activeLink === route?.url}>
          {route?.text}
        </NavLink>
        <HR />
      </li>
    ))
  );
};

MobileUserNavSection.propTypes = {
  activeLink: PropTypes.string,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
      type: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  user: PropTypes.shape({}),
  userFetchState: PropTypes.string,
};

MobileUserNavSection.defaultProps = {
  routes: [],
  user: {},
  userFetchState: 'pending',
  activeLink: '',
};

export default MobileUserNavSection;
