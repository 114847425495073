import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import css from '@styled-system/css';

const SVGWrapper = styled('div')`
  display: flex;
  margin-left: ${(props) => (props.$standalone ? '0' : '0.625rem')};

  svg {
    ${(props) =>
      css({
        fill: props.fill,
        stroke: props.stroke,
      })}
  }
`;

function SVGIcon({ height, width, url, standalone }) {
  const [svgString, setSvgString] = useState('');
  useEffect(() => {
    fetch(url)
      .then((response) => response.text())
      .then((svgData) => {
        let parsedSvgString;
        try {
          const svg = new DOMParser().parseFromString(
            svgData,
            'text/xml'
          ).documentElement;
          svg.setAttribute('height', height);
          svg.setAttribute('width', width);
          parsedSvgString = new XMLSerializer().serializeToString(svg);
        } catch (e) {
          parsedSvgString = '';
        }
        setSvgString(parsedSvgString);
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  }, [url, height, width]);

  return (
    <SVGWrapper
      $standalone={standalone}
      dangerouslySetInnerHTML={{ __html: svgString }}
    />
  );
}

SVGIcon.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  url: PropTypes.string.isRequired,
  standalone: PropTypes.bool,
};

SVGIcon.defaultProps = {
  width: 24,
  height: 24,
  standalone: true,
};

export default SVGIcon;
