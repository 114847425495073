// @ts-check
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Image as BuilderImage } from '@builder.io/react';
import { AnimatePresence } from 'framer-motion';
import ButtonLinkWidget from '@glowforge/gf-styled/src/contentful-widgets/button-link';

import {
  Wrapper,
  Title,
  Text,
  PillButtonWrapper,
  PillButton,
  ImageWrapper,
  Image,
  Picture,
  ButtonWrapper,
  SelectMaterialsDesktop,
  SelectMaterialsMobile,
} from './explore-materials.styles';
/**
 * @typedef {object} Materials
 * @property {string} id Unique id for image
 * @property {string} title Title of the material
 * @property {object} images images array (see gf-styled model)
 */

/**
 * @typedef {object} CtaProps
 * @property {string} id
 * @property {string} internalName
 * @property {string} size
 * @property {string} text
 * @property {string} type
 * @property {string} url
 */

/**
 * @typedef {object} Props
 * @property {Materials[]} materials - array of material objects
 * @property {string} title
 * @property {string} text
 * @property {CtaProps} ctaProps
 */

const ExploreMaterials = ({
  title,
  text,
  materials = [],
  ctaButton,
  attributes = {},
  isBuilder,
  selectMaterialsDesktop,
  selectMaterialsMobile,
}) => {
  const [selectedMaterial, setSelectedMaterial] = useState(
    materials.length > 0 && materials[0],
  );

  return (
    <Wrapper variant='full' isBuilder={isBuilder} {...attributes}>
      {title ? <Title>{title}</Title> : null}
      {text ? <Text>{text}</Text> : null}
     
      <PillButtonWrapper isBuilder={isBuilder}>
        {materials.map((material) => (
          <PillButton
            key={material.id}
            active={material.id === selectedMaterial.id}
            onClick={() => setSelectedMaterial(material)}>
            {isBuilder ? material.id : material.title}
          </PillButton>
        ))}
      </PillButtonWrapper>
      {selectMaterialsDesktop && (
          <SelectMaterialsDesktop>
            {selectMaterialsDesktop}
          </SelectMaterialsDesktop>
        )}
        {selectMaterialsMobile && (
          <SelectMaterialsMobile>{selectMaterialsMobile}</SelectMaterialsMobile>
        )}
      <ImageWrapper isBuilder={isBuilder} selectMaterialsDesktop={selectMaterialsDesktop}>
        <AnimatePresence>
          {selectedMaterial?.images?.map((image, index) => {
            const key = `${selectedMaterial.id}_${image.fields?.file?.url}_${index}`;
            if (isBuilder) {
              return (
                <Picture
                  key={key}
                  index={index}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}>
                  <BuilderImage
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'cover',
                    }}
                    image={image.image}
                    height={389}
                    lazy='false'
                  />
                </Picture>
              );
            }
            return (
              <Picture
                key={key}
                index={index}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <Image media={image} height={389} quality={70} />
              </Picture>
            );
          })}
        </AnimatePresence>
      </ImageWrapper>

      <ButtonWrapper>
        <ButtonLinkWidget model={ctaButton} />
      </ButtonWrapper>
    </Wrapper>
  );
};

ExploreMaterials.propTypes = {
  attributes: PropTypes.shape({}),
  ctaButton: PropTypes.shape({}),
  isBuilder: PropTypes.bool,
  materials: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      images: PropTypes.arrayOf(
        // There's more here, but it's ridiculous to repeat them everywhere
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          default: PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            file: PropTypes.shape({
              url: PropTypes.string,
            }),
          }),
        }),
      ),
    }),
  ),
  text: PropTypes.string,
  title: PropTypes.string,
};

ExploreMaterials.defaultProps = {
  attributes: {},
  ctaButton: null,
  isBuilder: false,
  materials: [],
  text: null,
  title: null,
};

export default ExploreMaterials;
