import PropTypes from 'prop-types';
import BlogHeroCategory from './components/blog-hero-category';
import BlogHeroArticle from './components/blog-hero-article';
import BlogHeroAuthor from './components/blog-hero-author';
import BlogHeroMain from './components/blog-hero-main';

const BlogHero = ({ attributes, ...props }) => {
  const { page, blogRefs } = props;
  switch (page) {
    case 'article':
      return <BlogHeroArticle {...attributes} props={props}  />;
    case 'author':
      return <BlogHeroAuthor {...attributes} props={props} />;
    case 'category':
      return <BlogHeroCategory {...attributes} props={props} />;
    case 'main':
      return blogRefs?.length > 0 && <BlogHeroMain {...attributes} blogRefs={blogRefs} />;
  }
};

BlogHero.propTypes = {
  attributes: PropTypes.shape({}),
  page: PropTypes.string,
  blogRefs: PropTypes.array
};

BlogHero.defaultProps = {
  attributes: {},
  page: '',
  blogRefs: []
};

export default BlogHero;
