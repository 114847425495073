import { fetchApi } from "../util/fetch-api";

/**
/**
 * Attempts to fetch the current user from gfcore.
 * Sends a request to the '/gfcore/users/current' endpoint.
 * @returns {Promise<Object>} The user data or null if not authenticated.
 */
export async function fetchCoreUser() {
  try {
    return await fetchApi('/gfcore/users/current');
  } catch (error) {
    console.error('Error fetching current user:', error);
    return null;
  }
}

/**
 * Posts to the '/gfcore/users/accept_terms_of_service' 
 * endpoint to accept terms of service.
 * @param {number} minorVersion - The minor version of the 
 * terms of service being accepted.
 * @returns {Promise<Object>} The response data or null if the request fails.
 */
export async function acceptTermsOfService(minorVersion) {
  try {
    return await fetchApi('/gfcore/users/accept_terms_of_service', {
      method: 'POST',
      body: JSON.stringify({ minor_version: minorVersion }),
      headers: {
        'Content-Type': 'application/json'
      }
    });
  } catch (error) {
    console.error('Error accepting terms of service:', error);
    return null;
  }
}

