import React from 'react';

export const fetchState = {
  PENDING: 'pending',
  COMPLETE: 'complete',
  ERROR: 'error',
};

const UserContext = React.createContext({
  user: {},
  userFetchState: fetchState.PENDING,
});

export default UserContext;
