import styled from 'styled-components';
import {
  LinkBaseStyles,
  linkVariants,
} from '@glowforge/gf-styled/src/components/link/link-base';

// eslint-disable-next-line import/prefer-default-export
export const HubspotFormStyles = styled('div')`
  /* 
    Set up our base typographic CSS for our text elements.
    These will receive the CSS variables defined individually below.
  */
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  a,
  li {
    ${(props) => props.theme.typography.baseCSS}
  }
  h1 {
    ${(props) => props.theme.typography.title}
    --letter-spacing-desktop: initial;
    --letter-spacing: initial;
  }
  h2 {
    ${(props) => props.theme.typography.heading}
  }
  h3 {
    ${(props) => props.theme.typography.subheading}
  }
  h4 {
    ${(props) => props.theme.typography.header4}
  }
  h5 {
    ${(props) => props.theme.typography.caption}
  }
  p,
  li {
    ${(props) => props.theme.typography.paragraph}
    margin-top: 20px
  }
  a {
    ${LinkBaseStyles}
    ${(props) => linkVariants(props)({ variant: 'body' })}
  }

  input {
    display: inline-block;
    text-align: start;
    margin: 10px 0 20px;
    border: solid 1px ${(props) => props.theme.colors.magic.dark};
    padding: 10px 10px;
    width: 100%;
    color: #000;
    background: #fff;
    border-radius: 4px;
  }

  ul.hs-error-msgs {
    color: red;
    padding: 0px 0px;
    list-style: none;
    list-style-type: none;
  }

  .gf-hubspot-form-submit {
    font-family: 'Space Grotesk', sans-serif;
    color: var(--color);
    background-color: var(--background-color);
    border: 0.125rem solid var(--border-color);
    border-radius: 10em;
    padding: 0.375rem 1.125rem;
    text-decoration: unset;
    white-space: nowrap;
    width: auto;
    cursor: pointer;

    --color: ${(props) => props.theme.colors.white};
    --background-color: ${(props) => props.theme.colors.magic.buttonBase};
    --hover-bg-color: ${(props) => props.theme.colors.magic.base};
    --border-color: ${(props) => props.theme.colors.magic.buttonBase};
    --hover-border-color: ${(props) => props.theme.colors.magic.base};

    ${(props) =>
      props.buttonType === 'edu'
        ? `
      --background-color: ${props.theme.colors.midnight.mid};
      --border-color: ${props.theme.colors.midnight.mid};
      --hover-bg-color: ${props.theme.colors.midnight.base};
      --hover-border-color: ${props.theme.colors.midnight.base};
    `
        : ''}

    &:hover {
      box-shadow: ${(props) => props.theme.effects.boxShadow.button};
    }
  }

  .hs-input[type='radio'] {
    width: auto;
    margin-right: 10px;
  }

  li.hs-form-radio {
    list-style: none;
    margin-top: 4px;
  }

  .hs-fieldtype-textarea textarea {
    display: inline-block;
    text-align: start;
    margin: 10px 0 20px;
    border: 1px solid rgb(10, 48, 54);
    padding: 10px 10px;
    width: 100%;
    color: #000;
    background: #fff;
    border-radius: 4px;
  }
`;
