import { useEffect } from 'react'

/**
 * A hook to handle when a user presses escape.
 * It uses a passed-in dependency array to determine when to add/remove its event listener.
 * 
 * 
 * @param {function} [handler = (e) => {}] - Callback function executed when a user presses escape.
 * @param {array} [dependencies = []] - Depency array for updating when to add/remove event listener.
 * @returns {void}
*/

const useEscapeHandler = (handler = (e) => {}, dependencies = []) => {
  const escapeHandler = (e) => {
    if (e.code === 'Escape') {
      handler()
    }
  }

  useEffect(() => {
    document?.addEventListener('keyup', escapeHandler)

    return () => {
      document?.removeEventListener('keyup', escapeHandler)
    }
  }, dependencies)
}

export default useEscapeHandler
