import React from 'react';

/**
 * BuilderModalContext allows the custom Modal component to pass down callbacks
 * to open and close itself. This allows Buttons (soon, more), to use open and
 * close the correct modal.
 */
const BuilderModalContext = React.createContext({
  // eslint-disable-next-line no-unused-vars
  openModal: () => { },
  // eslint-disable-next-line no-unused-vars
  closeModal: () => { },
});

export default BuilderModalContext;
