import React from 'react';
import PropTypes from 'prop-types';
import { Remarkable } from 'remarkable';
import styled from 'styled-components';
import { LinkBaseStyles, linkVariants } from './link/link-base';

const Markdown = ({ source, className, options, element, ...rest }) => {
  // Don't try to render if no text passed in
  // Also breaks if it receives not a string it seems
  if (!source) {
    return null;
  }

  if (typeof source !== 'string') {
    console.error('Markdown passed is not string', source);
    return null;
  }

  const remarkable = new Remarkable('default', options);
  const html = remarkable.render(source);
  return React.createElement(element, {
    className: `markdown gf_styled__markdown ${className}`,
    dangerouslySetInnerHTML: { __html: html },
    ...rest,
  });
};

// todo: need to pass these props to StyledMarkdown
// might only be possible via Typescript
// https://styled-components.com/docs/api#using-custom-props
Markdown.propTypes = {
  source: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.object, // eslint-disable-line
  element: PropTypes.elementType,
};

Markdown.defaultProps = {
  source: null,
  className: '',
  options: { html: true },
  element: 'div',
};

const StyledMarkdown = styled(Markdown)`
  line-height: ${props => props.$noPadding ? 'initial' : '1.3rem'};
  overflow-wrap: break-word;

  ${(props) => (props.$textAlign ? `text-align: ${props.$textAlign};` : '')};
  ${(props) => (props.$color ? `color: ${props.$color};` : '')};

  p {
    padding: ${props => props.$noPadding ? '0' : '0.5rem 0'};
  }

  strong {
    font-family: 'Exo 2', sans-serif;
    font-weight: 600;
  }

  ol,
  ul {
    margin-left: 2rem;
    margin-top: 0.5rem;

    > li {
      list-style-position: outside;
      padding-bottom: ${props => props.$noPadding ? '0' : '0.5rem'};

    }
  }

  ul {
    > li {
      list-style-type: disc;
      margin-left: 1.5rem;
    }
  }

  ol {
    > li {
      list-style-type: decimal;
    }
  }

  /** Allow inline images (icons) to be aligned correctly in the text **/
  p,
  h1,
  h2,
  h3,
  h4,
  p > strong {
    > img {
      vertical-align: sub;
    }
  }

  a {
    ${LinkBaseStyles}
    ${(props) => linkVariants(props)({ variant: 'body' })}
  }
`;

export default StyledMarkdown;
