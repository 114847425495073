import React, { useState, useCallback, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { withChildren, BuilderBlocks } from '@builder.io/react/lite';

// eslint-disable-next-line import/no-extraneous-dependencies
import { AnimatePresence, useReducedMotion } from 'framer-motion';

import AnimatedPlus from '@glowforge/gf-styled/src/components/animated/plus';
import {
  AnswerWrapper,
  GridContainer,
  HR,
  QuestionWrapper,
} from '@glowforge/gf-styled/src/components/accordion/accordion.styles';

const Accordion = ({
  builderBlock,
  items,
  openFirst = false,
  selectMultiple = false,
}) => {
  const shouldReduceMotion = useReducedMotion();
  const [active, setActive] = useState(new Set(openFirst ? [0] : []));

  const toggleActive = useCallback(
    (idx) => {
      setActive((previousActive) => {
        if (previousActive.has(idx)) {
          active.delete(idx);
        } else {
          if (selectMultiple === false) {
            active.clear();
          }
          active.add(idx);
        }
        return new Set(active);
      });
    },
    [selectMultiple, active]
  );


  useEffect(() => {
    setActive(new Set(openFirst ? [0] : []))
  }, [openFirst])

  if (items?.length === 0) {
    return 'Add accordion items';
  }

  return (
    <GridContainer>
      {items.map(({ label = '', content }, idx) => (
        <Fragment key={label}>
          <HR />
          <QuestionWrapper onClick={() => toggleActive(idx)}>
            {label}
            <AnimatedPlus active={active.has(idx)} />
          </QuestionWrapper>
          <AnimatePresence>
            {active.has(idx) && (
              <AnswerWrapper
                initial={{ height: 0 }}
                animate={{ height: 'auto' }}
                exit={{ height: 0 }}
                transition={
                  shouldReduceMotion
                    ? { duration: 0 }
                    : {
                        stiffness: 150,
                      }
                }>
                <BuilderBlocks
                  parentElementId={builderBlock?.id}
                  blocks={content}
                />
              </AnswerWrapper>
            )}
          </AnimatePresence>
        </Fragment>
      ))}
    </GridContainer>
  );
};

Accordion.propTypes = {
  builderBlock: PropTypes.shape({
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.any),
      PropTypes.elementType,
    ]),
    id: PropTypes.string,
  }).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  openFirst: PropTypes.bool,
  selectMultiple: PropTypes.bool,
};

Accordion.defaultProps = {
  openFirst: false,
  selectMultiple: false,
};

export default withChildren(Accordion);
