import React from 'react';
import PropTypes from 'prop-types';
import FAQ from '@glowforge/gf-styled/src/components/faq';
import { BuilderBlocks } from '@builder.io/react/lite';

const FAQWrapper = ({ attributes, ...props }) => {
  // map question/answer to FAQ items
  const items = props.items.map(({ questionAnswer }) => ({
      question: questionAnswer?.value?.data?.question,
      answer: <BuilderBlocks blocks={questionAnswer?.value?.data?.blocks} />,
    }));
  

  return (
    <FAQ
      {...props}
      {...attributes}
      items={items}
      variant='body'
      // Should be wrapped in Section component to give background color
      backgroundColor='backgrounds.none'
    />
  );
};

FAQWrapper.propTypes = {
  attributes: PropTypes.shape({}),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
    })
  ),
  selectMultiple: PropTypes.bool,
};

FAQWrapper.defaultProps = {
  attributes: {},
  items: [],
  selectMultiple: false,
};

export default FAQWrapper;
