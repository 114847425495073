export const grabUtmParams = () => {
  const params = new URLSearchParams(window.location.search);
  const UTMs = {};
  params.forEach( (value, key ) => {
    if( key.startsWith('utm_') ) {
      UTMs[key] = value;
    }
  });
  return UTMs;
}


/**
 * Send an event to Google Analytics
 * @param {string} event
 */
export const track = (event, additionalData = {}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event, ...additionalData });
};

/**
 * Wrapper around Google Analytics tracker.send() API
 * https://developers.google.com/analytics/devguides/collection/analyticsjs/tracker-object-reference#send
 * @param  {...any} args
 */
export const gaTrackerSend = (...args) => {
  if (typeof window?.ga?.getAll === 'function') {
    const tracker = window.ga.getAll()[0];
    if (tracker) {
      tracker.send(...args);
    }
  }
};
