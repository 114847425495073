import Router from 'next/router';
import React, { useCallback, useEffect } from 'react';
import { clientOnly } from '../../util';
import { gaTrackerSend } from '../../util/analytics';

const PageAnalytics = () => {
  const trackSegmentPage = () => {
    clientOnly(() => {
      if (!window.analytics) return;
      window.analytics.page('', '', {});
    });
  };
  // Can send custom dimensions and metrics through ga
  // https://developers.google.com/analytics/devguides/collection/analyticsjs/custom-dims-mets
  const trackGaPage = () => {
    clientOnly(() => {
      // Wait for document title to update
      setTimeout(() => {
        gaTrackerSend('pageview', window.location.pathname, document.title, {});
      });
    });
  };

  const onRouteChangeComplete = useCallback(() => {
    trackSegmentPage();
    trackGaPage();
  }, []);

  /* Track page when it loads. */
  useEffect(() => {
    trackSegmentPage();
  }, []);

  /* Track pages as we browse. */
  useEffect(() => {
    const trackPageOnRouteChangeComplete = [
      'routeChangeComplete',
      onRouteChangeComplete,
    ];
    Router.events.on(...trackPageOnRouteChangeComplete);
    return () => {
      Router.events.off(...trackPageOnRouteChangeComplete);
    };
  }, []);
  return <></>;
};

export default PageAnalytics;
