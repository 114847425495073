import PropTypes from 'prop-types';

export const stringSubstitutionsPropType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.object),
  PropTypes.object,
]);

export const customWidgetsPropType = PropTypes.shape({
  component: PropTypes.element,
  props: PropTypes.object,
});

export default {
  stringSubstitutions: stringSubstitutionsPropType,
};
