/**
 * @param {Function|any} fn
 * if function, is called and returned when isClient is true.
 * if any, is returned when isClient is true.
 * @returns {Boolean|Function|any}
 */
export default function clientOnly(fn) {
  const isClient = typeof window !== 'undefined';
  switch (typeof fn) {
    case 'undefined':
      return isClient;
    case 'function':
      return isClient && fn();
    default:
      return isClient && fn;
  }
}
