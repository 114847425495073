import React from 'react';
import PropTypes from 'prop-types';
import { Option, Image } from './artboard-option.styles';

export default function ArtboardOption({
  index,
  id,
  category,
  image,
  text,
  phrase,
  style,
}) {
  return (
    <Option>
      <input
        type='radio'
        name={category}
        id={id}
        defaultChecked={
          category === 'phrase' ? index === phrase : index === style
        }
        value={index}
      />
      <label htmlFor={id}>
        {image ? (
          <Image
            src={image}
            width={140}
            height={130}
            alt={text}
            loading='lazy'
          />
        ) : null}
        <span>{text}</span>
      </label>
    </Option>
  );
}

ArtboardOption.propTypes = {
  id: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  image: PropTypes.string,
  text: PropTypes.string,
  index: PropTypes.number.isRequired,
  phrase: PropTypes.number.isRequired,
  style: PropTypes.number.isRequired,
};

ArtboardOption.defaultProps = {
  image: '',
  text: '',
};
