export const backgroundPositions = [
  'center',
  'top center',
  'bottom center',
  'right',
  'right top',
  'right bottom',
  'left',
  'left top',
  'left bottom',
];

export const backgroundSizes = ['auto', 'cover', 'contain'];

export const heroHeights = ['100vh', '75vh', '50vh', '33vh', '25vh'];

export const backgroundRepeats = [
  'no-repeat',
  'repeat',
  'repeat-x',
  'repeat-y',
  'initial',
  'inherit',
];

export default {
  name: 'Hero Banner',
  // deprecated in favor of v2. V2 does not use CSS to style children.
  hideFromInsertMenu: true,
  // shows options when editing children
  broadcast: true,
  image:
    'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fb082775cbfbb4ff595fb9a5fc5dc45c1?quality=60&width=200&height=200',
  inputs: [
    {
      name: 'background',
      helperText: 'Select background image or video.',
      type: 'file',
      allowedFileTypes: ['mp4', 'webm', 'png', 'jpg'],
    },
    {
      name: 'isVideo',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'backgroundMobile',
      helperText:
        'Select background image. Video backgrounds not currently supported for mobile.',
      type: 'file',
      allowedFileTypes: ['png', 'jpg'],
    },
    {
      name: 'heroHeightDesktop',
      type: 'string',
      defaultValue: heroHeights[1],
      enum: heroHeights,
    },
    {
      name: 'heroHeightMobile',
      type: 'string',
      defaultValue: heroHeights[0],
      enum: heroHeights,
    },
    {
      name: 'backgroundPosition',
      helperText: 'This only works for images, not videos.',
      type: 'string',
      defaultValue: backgroundPositions[0],
      enum: backgroundPositions,
    },
    {
      name: 'backgroundPositionMobile',
      type: 'string',
      defaultValue: backgroundPositions[0],
      enum: backgroundPositions,
    },
    {
      name: 'backgroundSize',
      type: 'string',
      defaultValue: backgroundSizes[1],
      enum: backgroundSizes,
    },
    {
      name: 'backgroundSizeMobile',
      type: 'string',
      defaultValue: backgroundSizes[1],
      enum: backgroundSizes,
    },
    {
      name: 'backgroundRepeat',
      type: 'string',
      defaultValue: backgroundRepeats[0],
      enum: backgroundRepeats,
    },
    {
      name: 'backgroundRepeatMobile',
      type: 'string',
      defaultValue: backgroundRepeats[0],
      enum: backgroundRepeats,
    },
    {
      name: 'showOverlay',
      type: 'boolean',
      defaultValue: true,
    },
  ],
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      '@version': 2,
      component: {
        name: 'Glowforge:Section',
        options: {
          maxWidth: 1200,
          backgroundColor: 'backgrounds.none',
        },
      },
      responsiveStyles: {
        large: {
          height: '100%',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '20px',
          paddingBottom: '20px',
        },
        medium: {
          paddingTop: '70px',
        },
      },
      children: [
        {
          '@type': '@builder.io/sdk:Element',
          component: {
            name: 'Box',
          },
          responsiveStyles: {
            large: {
              marginBottom: 'auto',
              marginTop: 'auto',
            },
          },
          children: [
            {
              '@type': '@builder.io/sdk:Element',
              component: {
                name: 'Glowforge:Text',
                options: {
                  variant: 'title',
                  text: 'Heading',
                },
              },
              responsiveStyles: {
                large: {
                  paddingBottom: '20px',
                },
              },
            },
            {
              '@type': '@builder.io/sdk:Element',
              component: {
                name: 'Glowforge:Text',
                options: {
                  variant: 'subheading',
                  text: 'Subheading',
                },
              },
              responsiveStyles: {
                large: {
                  paddingBottom: '20px',
                },
              },
            },
            {
              '@type': '@builder.io/sdk:Element',
              component: {
                name: 'Glowforge:Button',
                options: {
                  text: 'Click Me',
                  variant: 'primary',
                },
              },
              responsiveStyles: {
                large: {
                  marginRight: 'auto',
                },
              },
            },
          ],
        },
      ],
    },
  ],
};
