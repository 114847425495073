import isArray from 'lodash/isArray'
import reduce from 'lodash/reduce'
import set from 'lodash/set'
import mapKeys from 'lodash/mapKeys'
import trim from 'lodash/trim'
import toString from 'lodash/toString'
import isEmpty from 'lodash/isEmpty'

function normalizeSubstitutionHash(substitutions) {
  if (isArray(substitutions)) {
    // eslint-disable-next-line no-param-reassign
    substitutions = reduce(
      substitutions,
      (acc, elem) => set(acc, elem.name, elem.value),
      {},
    );
  }
  return mapKeys(substitutions, (_value, key) => trim(key));
}

/**
 * Substitutes strings into a text string replacing {{macro}} the given text
 * string with the appropriate value {macro: 'value'} from simpleStrings. This
 * method accepts both the preferred form of simpleStrings {macro: value, ...}
 * and the legacy form [{name: 'macro', value: 'value'}, ...]
 *
 * Note: Leave the flexible option enabled in user-facing cases. This way, 
 * they will see "Hello , how's your Glowforge?" instead of 
 * "Hello {{userFirstName}}, how's your Glowforge?"
 *
 * @param  {string} text                - text that may or may not contain 
 * {{name}} entries
 * @param  {object of strings}          - name value pairs to replace in 
 * passed string
 *                                        simple hash {macro: value}
 * @param  {Object} options             - Additional options to control behavior
 * @param  {boolean} options.flexible   - (default: true) Prefer true. False 
 *  preserveslegacy behavior and will be removed in a future
 *  version. When true, use empty string instead of
 *  the original macro text for unspecified macros.
 * @return {string}                     - input text with {{name}} replaced by 
 *  value from simple strings hash
 */
export default function stringSubstitution(text, simpleStrings, { flexible = true } = {}) {
  if (isEmpty(text)) return '';
  // eslint-disable-next-line no-param-reassign
  simpleStrings = simpleStrings || {};

  const substitutionHash = normalizeSubstitutionHash(simpleStrings);

  return text.replace(/{{([^{}]+)}}/g, (original, name) => {
    const defaultValue = flexible ? '' : original;

    const value = toString(substitutionHash[trim(name)]);
    return isEmpty(value) ? defaultValue : value;
  });
}
