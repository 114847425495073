import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const IframeWrapper = styled('iframe')`
  position: absolute;
  top: 0;
  z-index: 0;
  border: none;
  pointer-events: none;
`;

function YoutubeVideo({ url }) {
  const urlParams = new URL(url);
  const searchParams = new URLSearchParams(urlParams.search);
  const youtubeId = searchParams.get('v');

  if (!youtubeId) {
    return 'The entered URL is not a valid Youtube Url';
  }
  return (
    <IframeWrapper
      width='100%'
      height='100%'
      src={`https://www.youtube.com/embed/${youtubeId}?playlist=${youtubeId}&autoplay=1&mute=1&controls=0&loop=1&showinfo=0`}
      title='YouTube video player'
      allow='accelerometer;autoplay;encrypted-media;gyroscope;'
      allowFullScreen
    />
  );
}

YoutubeVideo.propTypes = {
  url: PropTypes.string.isRequired,
};

export default YoutubeVideo;
