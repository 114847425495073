import React from 'react';
import PropTypes from 'prop-types';

import { BuilderBlocks } from '@builder.io/react/lite';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Swiper, SwiperSlide } from 'swiper/react';

// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Autoplay,
  EffectCoverflow,
  EffectCards,
  EffectFlip,
  Navigation,
  Pagination,
  Controller,
} from 'swiper';

import SVG from '../svg';

import sliderProperties from './slider-properties';

import {
  MediaSliderContainer,
  SliderPagination,
  NavigationButton,
} from './slider.styles';

export default function MediaSlider({ id, slides, options, setCurrentSlide }) {
  const { toggleActiveCardContent, pagination, navigation } = options;
  const sliderAttributes = sliderProperties(id, options);

  return (
    <MediaSliderContainer className='media-slider'>
      {navigation === true ? (
        <NavigationButton
          className='slider-button-prev'
          aria-label='Move Carousel Backward'>
          <SVG id='chevron-light' />
        </NavigationButton>
      ) : null}
      <Swiper
        onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
        modules={[
          EffectCoverflow,
          EffectFlip,
          EffectCards,
          Autoplay,
          Navigation,
          Pagination,
          Controller,
        ]}
        {...sliderAttributes}>
        {slides.map(({ delay = 0, content = '' }, index) => (
          <SwiperSlide
            key={id + (content?.[0]?.id || index)}
            className={toggleActiveCardContent ? 'no-content-slide' : null}
            data-swiper-autoplay={delay * 1000}>
            <BuilderBlocks parentElementId={id} blocks={content} />
          </SwiperSlide>
        ))}

        {pagination === true ? (
          <SliderPagination className='slider-pagination' />
        ) : null}
      </Swiper>
      {navigation === true ? (
        <NavigationButton
          className='slider-button-next'
          aria-label='Move Carousel Forward'>
          <SVG id='chevron-light' />
        </NavigationButton>
      ) : null}
    </MediaSliderContainer>
  );
}

MediaSlider.propTypes = {
  id: PropTypes.string.isRequired,
  slides: PropTypes.arrayOf(PropTypes.shape({})),
  options: PropTypes.shape({
    navigation: PropTypes.bool,
    pagination: PropTypes.bool,
    toggleActiveCardContent: PropTypes.bool,
  }),
  setCurrentSlide: PropTypes.func,
};

MediaSlider.defaultProps = {
  slides: [],
  options: PropTypes.shape({
    navigation: false,
    pagination: false,
    toggleActiveCardContent: true,
  }),
  setCurrentSlide: () => {},
};
