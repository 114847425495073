import { useEffect, useMemo, useState } from 'react';

const pack = (value) => JSON.stringify({ blob: value });

const unpack = (blob) => JSON.parse(blob).blob;

/*
 * Persists calls to setState to localStorage, and initialize state from
 * localStorage when available.
 *
 * Example:
 *
 *   const MyComponent = () => {
 *     const [foo, setFoo] = useLocalStorage("FOO", 0);
 *
 *     useEffect(() => {
 *       const increment = f => f + 1;
 *       setFoo(increment);
 *       setFoo(increment);
 *       setFoo(increment);
 *     }, [setFoo]);
 *
 *     useEffect(() => {
 *       console.log(foo); // 0; 3
 *       console.log(
 *         localStorage.getItem("FOO")); // '{"blob": 0}'; '{"blob": 3}'
 *     }, [foo]);
 *
 *     return <p>{foo}</p>; // <p>0</p>; <p>3</p>
 *   };
 */
const useLocalStorage = (
  key,
  initialValue,
  reset = false,
  getStore = () => {
    // attempting to access localstorage in the builder.io iframe
    // is causing this to throw an error
    try {
      return typeof localStorage !== 'undefined' && localStorage
    } catch (e) {
      console.error(e);
      return false; 
    }
  } 
) => {
  // only compute store value once. Local storage is either available or not.
  const store = useMemo(() => getStore(), []);
  const isStoreAvailable = Boolean(store);

  const initialBlob = useMemo(
    () => (store && store.getItem(key)) || pack(initialValue),
    [initialValue, key, isStoreAvailable],
  );

  const initialState = useMemo(() => unpack(initialBlob), [initialBlob]);

  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (store) store.setItem(key, pack(state));
  }, [key, store, state]);

  useEffect(() => {
    if (reset) setState(initialValue);
  }, [initialValue, reset, store]);

  return [state, setState];
};

export default useLocalStorage;
