import { getCLS, getFID, getLCP } from 'web-vitals';

/**
 * This file is mostly copied from https://github.com/GoogleChromeLabs/web-vitals-codelab/blob/6bafea9d27effc9554e0c426699aa6ecdf4b863e/diagnostics.html#L47:L127
 * The difference is that this is a module, rather than an inline script.
 * It exports a named function, trackCoreWebVitals, that should be called
 * clientside. Requires a gtag function to exist.
 */

function getSelector(node, maxLen = 100) {
  let sel = '';
  try {
    while (node && node.nodeType !== 9) {
      const part = node.id
        ? `#${node.id}`
        : node.nodeName.toLowerCase() +
          (node.className && node.className.length
            ? `.${Array.from(node.classList.values()).join('.')}`
            : '');
      if (sel.length + part.length > maxLen - 1) {
        return sel || part;
      }
      sel = sel ? `${part}>${sel}` : part;
      if (node.id) {
        break;
      }
      // eslint-disable-next-line no-param-reassign
      node = node.parentNode;
    }
  } catch (err) {
    // Do nothing...
  }
  return sel;
}

function getLargestLayoutShiftEntry(entries) {
  return entries.reduce((a, b) => (a && a.value > b.value ? a : b));
}

function getLargestLayoutShiftSource(sources) {
  return sources.reduce((a, b) =>
    a.node &&
    a.previousRect.width * a.previousRect.height >
      b.previousRect.width * b.previousRect.height
      ? a
      : b
  );
}

function wasFIDBeforeDCL(fidEntry) {
  const navEntry = performance.getEntriesByType('navigation')[0];
  return navEntry && fidEntry.startTime < navEntry.domContentLoadedEventStart;
}

function getDebugInfo(name, entries = []) {
  // In some cases there won't be any entries (e.g. if CLS is 0,
  // or for LCP after a bfcache restore), so we have to check first.
  if (entries.length) {
    if (name === 'LCP') {
      const lastEntry = entries[entries.length - 1];
      return {
        debug_target: getSelector(lastEntry.element),
        event_time: lastEntry.startTime,
      };
    }
    if (name === 'FID') {
      const firstEntry = entries[0];
      return {
        debug_target: getSelector(firstEntry.target),
        debug_event: firstEntry.name,
        debug_timing: wasFIDBeforeDCL(firstEntry) ? 'pre_dcl' : 'post_dcl',
        event_time: firstEntry.startTime,
      };
    }
    if (name === 'CLS') {
      const largestEntry = getLargestLayoutShiftEntry(entries);
      if (largestEntry && largestEntry.sources && largestEntry.sources.length) {
        const largestSource = getLargestLayoutShiftSource(largestEntry.sources);
        if (largestSource) {
          return {
            debug_target: getSelector(largestSource.node),
            event_time: largestEntry.startTime,
          };
        }
      }
    }
  }
  // Return default/empty params in case there are no entries.
  return {
    debug_target: '(not set)',
  };
}

export function sendToGoogleAnalytics({ name, delta, value, id, entries }) {
  if (typeof gtag === 'undefined') {
    /* Gtag is set up in _document.jsx on the window */
    return;
  }
  // eslint-disable-next-line no-undef
  gtag('event', name, {
    // Built-in params:
    value: delta, // Use `delta` so the value can be summed.
    // Custom params:
    metric_id: id, // Needed to aggregate events.
    metric_value: value, // Value for querying in BQ
    metric_delta: delta, // Delta for querying in BQ
    // Send the returned values from getDebugInfo() as custom parameters
    ...getDebugInfo(name, entries),
  });
}

export function trackCoreWebVitals() {
  getLCP(sendToGoogleAnalytics);
  getFID(sendToGoogleAnalytics);
  getCLS(sendToGoogleAnalytics);
}
