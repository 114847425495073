import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { m, useReducedMotion } from 'framer-motion';

const SVG = styled(m.svg)`
  stroke-linecap: round;
  stroke-width: 2px;
  stroke: ${(props) => props.theme.colors.magic.base};
  vertical-align: top;
`;

const AnimatedPlus = ({ active }) => {
  const shouldReduceMotion = useReducedMotion();

  const animateRotate = {
    inactive: {
      rotate: shouldReduceMotion ? -90 : 0,
    },
    active: {
      rotate: -90,
    },
  };

  const animatePlus = {
    hidden: {
      opacity: 0,
      pathLength: shouldReduceMotion ? 1 : 0,
    },
    visible: {
      opacity: 1,
      pathLength: 1,
    },
  };

  return (
    <SVG
      width='15px'
      height='15px'
      viewBox='0 0 15 15'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      variants={animateRotate}
      initial='inactive'
      animate={active ? 'active' : 'inactive'}>
      <m.path
        d='M1,7.5L14,7.5'
        variants={animatePlus}
        initial='visible'
        animate={active ? 'hidden' : 'visible'}
      />
      <path d='M7.5,1L7.5,14' />
    </SVG>
  );
};

AnimatedPlus.propTypes = {
  active: PropTypes.bool,
};

AnimatedPlus.defaultProps = {
  active: false,
};

export default AnimatedPlus;
