import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import variant from '@styled-system/variant';

export const linkVariants = (props) =>
  variant({
    variants: {
      body: {
        '--color': props.theme.colors.magic.base,
        '--hover-color': props.theme.colors.magic.base,
        '--underline': 'none',
        '--hover-underline': 'underline',
      },
      defaut: {
        // copy of nav
        '--color': props.theme.colors.slate.base,
        '--active-color': props.theme.colors.magic.base,
        '--hover-color': props.theme.colors.magic.base,
        '--underline': 'none',
        '--hover-underline': 'none',
      },
      nav: {
        '--color': props.theme.colors.slate.base,
        '--active-color': props.theme.colors.magic.base,
        '--hover-color': props.theme.colors.magic.base,
        '--underline': 'none',
        '--hover-underline': 'none',
      },
      legal: {
        '--color': props.theme.colors.slate.lightest,
        '--hover-color': props.theme.colors.magic.base,
        '--underline': 'underline',
        '--hover-underline': 'underline',
      },
      white: {
        '--color': props.theme.colors.white,
        '--hover-color': props.theme.colors.magic.base,
        '--underline': 'underline',
        '--hover-underline': 'underline',
      },
    },
  });

export const LinkBaseStyles = css`
  color: ${(props) =>
    props.$isActive ? `var(--active-color)` : `var(--color)`};
  font-family: 'Space Grotesk', sans-serif;
  font-weight: var(--font-weight);
  letter-spacing: var(--letter-spacing);
  display: inline;
  text-decoration: var(--underline);

  --font-weight: 500;
  --letter-spacing: -0.4px;

  &:hover {
    color: var(--hover-color);
    text-decoration: var(--hover-underline);
  }

  // Temporary. Once SVGs are also using styled components,
  // this should be updated to reflect that pattern and remove
  // the class name.
  > .button-icon-wrapper {
    margin-left: 0.625rem;
    svg {
      display: flex;
    }
  }

  ${linkVariants}
`;

/**
 * @property {{ 'body' | 'nav' | 'legal' }} variant
 */
const LinkBaseComponent = styled('a').attrs((props) => ({
  className: `gf-styled__link__${props.variant}`,
}))`
  ${LinkBaseStyles}
`;

LinkBaseComponent.propTypes = {
  $isActive: PropTypes.bool,
  variant: PropTypes.oneOf(['body', 'nav', 'legal', 'white', 'default']),
};

LinkBaseComponent.defaultProps = {
  $isActive: false,
  variant: 'nav',
};

export default LinkBaseComponent;
