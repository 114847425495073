import React from 'react';
import PropTypes from 'prop-types';
import { withChildren } from '@builder.io/react/lite';
import {
  Background,
  FloatQuote,
  TextContainer,
  TextContentWrapper,
} from './quote-card.styles';

/**
 * Probably not the best way to do this, but was quick.
 */
const backgroundPatterns = {
  'magic dots': 'https://images.ctfassets.net/ljtyf78xujn2/4JrKZf5vVLJR8Uup7jwNjM/0db67c3ec98c8f9454a8ae30141bb963/magic-dots.png',
  'wood': 'https://images.ctfassets.net/ljtyf78xujn2/3yP9UoQ7nVhYwhOC3pAtfc/365c25246bc10bce4ce8bb13a8c66120/wood-seamless-pattern-1-quality_1_.png',
  'honeycomb': 'https://images.ctfassets.net/ljtyf78xujn2/5rpgfgz04R4sal1Co992Ar/de427ac1b57f399d0041961e14f5a0ab/honeycomb-seamless-pattern-saddle.png',
}

export const QuoteCard = ({
  children,
  title,
  bodyContent,
  backgroundImage,
  backgroundColor,
  button,
  showQuote,
  minHeightMobile,
  minHeightDesktop,
  ...delegated
}) => {

  // We want to provide the complimentary color for our backgroundColor.
  const quoteColor =
    showQuote && backgroundColor && backgroundColor.split('.').length === 2
      ? `${backgroundColor.split('.')[0]}.lightest`
      : 'magic.lightest';

  return (
    <TextContainer
      backgroundImage={backgroundImage}
      backgroundColor={backgroundColor}
      backgroundImagePosition='alignLeft'
      minHeightMobile={minHeightMobile}
      minHeightDesktop={minHeightDesktop}
      showOverlay={false}
      {...delegated}>
      {showQuote ? <FloatQuote color={quoteColor}>&ldquo;</FloatQuote> : null}
      <Background backgroundImage={backgroundPatterns[backgroundImage]} />
      <TextContentWrapper>{children}</TextContentWrapper>
    </TextContainer>
  );
};

QuoteCard.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  bodyContent: PropTypes.string,
  button: PropTypes.shape({}),
  children: PropTypes.node,
  minHeightDesktop: PropTypes.string,
  minHeightMobile: PropTypes.string,
  showQuote: PropTypes.bool,
  title: PropTypes.string,
};

QuoteCard.defaultProps = {
  backgroundColor: null,
  backgroundImage: null,
  bodyContent: null,
  button: null,
  children: null,
  minHeightDesktop: '400px',
  minHeightMobile: '250px',
  showQuote: false,
  title: null,
};

export { TextContainer };

export const QuoteCardWithChildren = withChildren(QuoteCard);
export default QuoteCardWithChildren;
