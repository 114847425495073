import ArticlesNotFound from './search-articles-not-found';
import ArticlesFound from './search-articles-found';

const SearchArticles = ({ filteredArticles, loadMore, hasMore, query,isLoading, props }) => {
  const { foundResultsTitle, notFoundResultsTitle, notFoundSubtitle } = props || {};
  return (
    <>
      {filteredArticles?.length > 0 ? (
        <ArticlesFound
          articles={filteredArticles}
          query={query}
          hasMore={hasMore}
          loadMore={loadMore}
          isLoading={isLoading}
          foundResultsTitle={foundResultsTitle}
        />
      ) : (
        <ArticlesNotFound
         notFoundResultsTitle={notFoundResultsTitle} 
         notFoundSubtitle={notFoundSubtitle}
         />
      )}
    </>
  );
};

export default SearchArticles;
