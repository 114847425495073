import { css } from 'styled-components'
import { LinkBaseStyles, linkVariants } from '../../components/link/link-base';

const markdownStyles = css`
  /* 
  * We are targeting gf_styled__markdown explicitly for inheritance so that way markdown elements
  * can receive new styling without having to render out a React component.
  */ 
  .gf_styled__markdown {

    /* 
      Set up our base typographic CSS for our text elements.
      These will receive the CSS variables defined individually below.
    */
    h1, h2, h3, h4, h5, p, a, li {
      ${props => props.theme.typography.baseCSS}
    }
    h1 {
      ${props => props.theme.typography.title}      
    }
    h2 {
      ${props => props.theme.typography.heading}
    }
    h3 {
      ${props => props.theme.typography.subheading}
    }
    h4 {
      ${props => props.theme.typography.header4}
    }
    h5 {
      ${props => props.theme.typography.caption}
    }
    p, li {
      ${props => props.theme.typography.paragraph}
    }
    a {
      ${LinkBaseStyles}
      ${(props) => linkVariants(props)({ variant: 'body' })}
    }
  }
`

export default markdownStyles