import React from 'react';

const FeatureFlagsContext = React.createContext({});

export default FeatureFlagsContext;

export const withFeatureFlags = (Component) => {
  const FeatureFlagsComponent = (props) => (
    <FeatureFlagsContext.Consumer>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      {(context) => <Component {...props} featureFlags={context} />}
    </FeatureFlagsContext.Consumer>
  );
  return FeatureFlagsComponent;
};
