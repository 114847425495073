import React, { useCallback, useContext, useEffect } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

import { Builder, BuilderBlocks, withChildren } from '@builder.io/react/lite';

import BuilderModalContext from './builderModalContext';
import { modalTypes } from './modal.config';
import useLocalStorage from '../util/use-local-storage';

const LAST_REFERRAL_CODE_KEY = '_gf_last_referral_code';

/**
 * Custom builder.io component to coordinate and launch modals.
 */
const BuilderModal = ({
  builderBlock,
  builderState,
  contentLabel,
  dataTestId,
  modalUniqueId,
  launchModal,
  modalType,
  modalPriority,
  reactRootSelector,
  showPopupTimes,
  popupDelay,
}) => {
  useEffect(() => {
    // Needed for accessibility.
    ReactModal.setAppElement(
      reactRootSelector || builderState.context.REACT_ROOT
    );
    // eslint-disable-next-line
  }, []);

  const isPopup = modalType === modalTypes.popup;
  const isLaunch = modalType === modalTypes.launch;
  const isReferral = modalType === modalTypes.referral;
  const modalId = modalUniqueId || builderBlock.id;

  const [dismissed, setDismissed] = useLocalStorage(modalId, 0);
  const showPopup = dismissed < showPopupTimes;

  const [lastReferralCode, setLastReferralCode] = useLocalStorage(
    LAST_REFERRAL_CODE_KEY,
    ''
  );

  /**
   * Hook in to ModalPriorityContext to coordinate with other Modals onsite.
   */
  const {
    isModalOpen,
    openModal: openModalCb,
    closeModal,
  } = useContext(builderState.context.ModalPriorityContext);

  /**
   * Close callback, bound to this modalId
   */
  const onRequestClose = useCallback(() => {
    closeModal(modalId);
    if (modalType === modalTypes.popup) {
      setDismissed(dismissed + 1);
    }
    // eslint-disable-next-line
  }, [modalId]);

  /**
   * Open callback, bound to this modalId
   */
  const openModal = useCallback(() => {
    openModalCb(modalId, modalPriority);
    // eslint-disable-next-line
  }, [modalId]);

  /**
   * Timeout for Popup style modal.
   */
  useEffect(() => {
    if (!showPopup || !isPopup || Builder.isEditing) {
      return;
    }
    setTimeout(() => {
      openModal();
    }, popupDelay);
    // eslint-disable-next-line
  }, [showPopup, modalId]);

  /**
   * Referral Popup logic
   * Will only show a referral popup one time. Otherwise, should display
   * inline with the page in other component.
   */
  const referralCode = builderState?.state?.referrals?.referralCode;
  const affiliateCode = builderState?.state?.affiliates?.id;
  useEffect(() => {
    if (
      isReferral &&
      (referralCode || affiliateCode) &&
      (!showPopup || referralCode !== lastReferralCode || affiliateCode !== lastReferralCode)
    ) {
      openModal();
      setLastReferralCode(referralCode || affiliateCode);
    }
  }, [
    affiliateCode,
    isReferral,
    referralCode,
    lastReferralCode,
    openModal,
    setLastReferralCode,
    showPopup,
  ]);

  const hasLaunchModalSection =
    isLaunch && launchModal && Array.isArray(launchModal) && launchModal[0];

  return (
    <>
      {(modalType === modalTypes.popup || modalType === modalTypes.referral) &&
      Builder.isEditing ? (
        <button type='button' onClick={openModal}>
          Open Modal - {modalId}
        </button>
      ) : null}

      {/* Set up BuilderModalContext.
          Child components can use this to
          open/close this modal. */}
      <BuilderModalContext.Provider
        // useCallback is equivalent to useMemo
        /* eslint-disable-next-line */
        value={{
          openModal,
          closeModal: onRequestClose,
        }}>
        {hasLaunchModalSection && (
          <BuilderBlocks
            child
            parentElementId={builderBlock.id}
            dataPath='component.options.launchModal.0.content'
            blocks={launchModal[0].content}
          />
        )}
        <ReactModal
          contentLabel={contentLabel || 'Glowforge Modal'}
          isOpen={isModalOpen(modalId)}
          onRequestClose={onRequestClose}
          data={dataTestId ? {'test-id': dataTestId} : null}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1,
              backgroundColor: '#00000033',
            },
            // Hide the defult content. This allows builder.io to control
            // width/height/style of modal
            content: {
              backgroundColor: 'transparent',
              inset: '0',
              position: 'absolute',
              padding: '0',
              overflow: 'visible',
              // Allow pointerEvents to passthrough to overlay
              // so can close when clicked.
              pointerEvents: 'none',
            },
          }}>
          <BuilderBlocks
            style={{
              // capture pointerEvents inside of content
              pointerEvents: 'auto',
            }}
            child
            parentElementId={builderBlock?.id}
            blocks={builderBlock.children}
          />
        </ReactModal>
      </BuilderModalContext.Provider>
    </>
  );
};

BuilderModal.propTypes = {
  builderBlock: PropTypes.shape({
    id: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  builderState: PropTypes.shape({
    context: PropTypes.shape({
      REACT_ROOT: PropTypes.string,
      ModalPriorityContext: PropTypes.shape({}),
    }),
    state: PropTypes.shape({
      referrals: PropTypes.shape({
        referralCode: PropTypes.string,
      }),
      affiliates: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  }).isRequired,
  contentLabel: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  launchModal: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
  modalUniqueId: PropTypes.string,
  modalPriority: PropTypes.number,
  modalType: PropTypes.oneOf(Object.values(modalTypes)),
  reactRootSelector: PropTypes.string,
  showPopupTimes: PropTypes.number,
  popupDelay: PropTypes.number,
};

BuilderModal.defaultProps = {
  dataTestId: null,
  launchModal: null,
  modalPriority: 10,
  modalType: modalTypes.launch,
  modalUniqueId: null,
  popupDelay: 3000,
  reactRootSelector: null,
  showPopupTimes: 1,
};

export default withChildren(BuilderModal);
