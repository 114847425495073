import React from 'react';
import PropTypes from 'prop-types';
import { withChildren, BuilderBlocks } from '@builder.io/react/lite';
import {
  Background,
  ContentWrapper,
  Overlay,
  Video,
  Wrapper,
} from './hero-banner-v1.styles';
import {
  backgroundPositions,
  backgroundRepeats,
  backgroundSizes,
  heroHeights,
} from './hero-banner-v1.config';

/**
 * Hero Banner V1.  
 */
export const HeroBanner = ({
  background,
  backgroundMobile,
  backgroundPosition,
  backgroundPositionMobile,
  backgroundRepeat,
  backgroundRepeatMobile,
  backgroundSize,
  backgroundSizeMobile,
  builderState,
  builderBlock,
  heroHeightDesktop,
  heroHeightMobile,
  children,
  isVideo,
  showOverlay,
  ...others
}) => (
  <Wrapper
    heroHeightDesktop={heroHeightDesktop}
    heroHeightMobile={heroHeightMobile}
    includeNav={builderState?.includeNav ?? true}
    {...others}>
    <Background
      background={background}
      backgroundMobile={backgroundMobile}
      backgroundPosition={backgroundPosition}
      backgroundPositionMobile={backgroundPositionMobile}
      backgroundSize={backgroundSize}
      backgroundSizeMobile={backgroundSizeMobile}
      backgroundRepeat={backgroundRepeat}
      backgroundRepeatMobile={backgroundRepeatMobile}
      isVideo={isVideo}>
      {isVideo ? (
        <Video
          playsInline
          autoPlay
          loop='loop'
          muted
          width='100%'
          height='100%'>
          <source src={background} />
        </Video>
      ) : null}
    </Background>
    {showOverlay ? <Overlay opacity={25} /> : null}
    <ContentWrapper>
      <BuilderBlocks
        style={{
          display: 'inline',
        }}
        child
        parentElementId={builderBlock?.id}
        dataPath='this.children'
        blocks={builderBlock.children}
      />
    </ContentWrapper>
  </Wrapper>
);

HeroBanner.propTypes = {
  background: PropTypes.string,
  backgroundMobile: PropTypes.string,
  backgroundPosition: PropTypes.oneOf(backgroundPositions),
  backgroundPositionMobile: PropTypes.oneOf(backgroundPositions),
  backgroundRepeat: PropTypes.oneOf(backgroundRepeats),
  backgroundRepeatMobile: PropTypes.oneOf(backgroundRepeats),
  backgroundSize: PropTypes.oneOf(backgroundSizes),
  backgroundSizeMobile: PropTypes.oneOf(backgroundSizes),
  builderBlock: PropTypes.shape({
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.elementType]),
    id: PropTypes.string,
  }).isRequired,
  builderState: PropTypes.shape({
    includeNav: PropTypes.bool,
  }),
  heroHeightDesktop: PropTypes.oneOf(heroHeights),
  heroHeightMobile: PropTypes.oneOf(heroHeights),
  children: PropTypes.node,
  isVideo: PropTypes.bool,
  showOverlay: PropTypes.bool,
};

HeroBanner.defaultProps = {
  background: null,
  backgroundMobile: null,
  backgroundPosition: backgroundPositions[0],
  backgroundPositionMobile: backgroundPositions[0],
  backgroundRepeat: backgroundRepeats[0],
  backgroundRepeatMobile: backgroundRepeats[0],
  backgroundSize: backgroundSizes[0],
  backgroundSizeMobile: backgroundSizes[0],
  builderState: { includeNav: true },
  heroHeightDesktop: heroHeights[0],
  heroHeightMobile: heroHeights[0],
  children: null,
  isVideo: false,
  showOverlay: true,
};

export const HeroBannerWithChildren = withChildren(HeroBanner);
export default HeroBannerWithChildren;
