export default {
  name: 'Glowforge:FAQSectionList',
  friendlyName: 'FAQ Section List',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F2709b7f3aea14033af6ad3248e232b94',
  noWrap: true,
  defaultStyles: {
    display: 'grid',
  },
  inputs: [
    {
      name: 'items',
      friendlyName: 'Question/Answers',
      helperText:
        `These are references to FAQ Sections. This allows Q/A's to be reused.
        To add a new FAQ, create a new FAQ Section model https://builder.io/content?model=ec3af41a9b314e0b92920d4e4cb6bd71`,
      type: 'list',
      subFields: [
        {
          name: 'questionAnswer',
          type: 'reference',
          model: 'faq-section',
        },
      ],
    },
    {
      name: 'selectMultiple',
      type: 'boolean',
      defaultValue: true,
      helperText: 'If true, allows multiple sections to be open at once.',
    },
  ],
};
