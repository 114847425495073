import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { BuilderBlocks } from '@builder.io/react/lite';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Swiper, SwiperSlide } from 'swiper/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { EffectFade, Controller } from 'swiper';

import { ContentSliderContainer } from './slider.styles';

export default function ContentSlider({ id, slides, currentSlide, options }) {
  const slider = useRef(null);
  const [sliderVisibility, setSliderVisibility] = useState(false);
  const { speed } = options;

  useEffect(() => {
    if (slider.current) {
      slider.current?.swiper?.slideTo(currentSlide, speed);
    }
  }, [currentSlide, speed]);

  return (
    <ContentSliderContainer
      className={`content-slider${!sliderVisibility ? ' hidden' : ''}`}>
      <Swiper
        initialSlide={currentSlide}
        onSwiper={() => setSliderVisibility(true)}
        ref={slider}
        slidesPerView={1}
        modules={[Controller, EffectFade]}
        effect='fade'
        noSwiping='true'
        noSwipingClass='swiper-slide'
        fadeEffect={{
          crossFade: true,
        }}>
        {slides.map(({ delay = 0, content = '' },index) => (
          <SwiperSlide
            key={id + index}
            className='content-slide'
            data-swiper-autoplay={delay * 1000}>
            <BuilderBlocks
              parentElementId={id}
              blocks={content}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </ContentSliderContainer>
  );
}

ContentSlider.propTypes = {
  id: PropTypes.string.isRequired,
  slides: PropTypes.node,
  currentSlide: PropTypes.number,
  options: PropTypes.shape({
    speed: PropTypes.number,
  }),
};

ContentSlider.defaultProps = {
  slides: [],
  currentSlide: 1,
  options: PropTypes.shape({
    speed: 500,
  }),
};
