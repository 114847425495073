const retailMapConfig = {
    name: 'Glowforge:Retail Map',
    inputs: [
        {
            name: 'iframeHeight',
            type: 'string',
            defaultValue: '60vh',
            enum: ['50vh', '60vh', '70vh', '80vh', '90vh', '100vh'],
        },
        {
            name: 'mobileIframeHeight',
            type: 'string',
            defaultValue: '60vh',
            enum: ['50vh', '60vh', '70vh', '80vh', '90vh', '100vh'],
        },
        {
            name: 'locations',
            type: 'list',
            subFields: [
                {
                    name: 'title',
                    type: 'string',
                    defaultValue: 'Joann'
                },
                {
                    name: 'iframe',
                    type: 'longText'
                }
            ],
        }
    ]
}

export default retailMapConfig