import React from 'react';
import PropTypes from 'prop-types';
import ButtonBaseComponent from './button-base';

/**
 * @property {node} children
 * @property {string} href
 * @property {node} icon
 */
const Button = ({
  children,
  href,
  icon,
  ...otherProps
}) => {
  
  return (
    <ButtonBaseComponent 
      {...otherProps}
      href={href}
      as={href ? 'a' : 'button'}
    >
      {children}
      {icon && (
        <div className='button-icon-wrapper'>
          {icon}
        </div>
      )}
    </ButtonBaseComponent>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  icon: PropTypes.node,
  target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
  ...ButtonBaseComponent.propTypes
};

Button.defaultProps = {
  href: null,
  icon: null,
  ...ButtonBaseComponent.defaultProps
};

export default Button;
