import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { GridContainer } from '../grid-container'
import VisuallyHidden from '../visually-hidden'

import MobileNav from './mobile-nav'
import DesktopNav from './desktop-nav'
import defaultRoutes from './shared/routes'

const Header = styled(GridContainer)`
  background-color: ${props => props.theme.colors.white};
  position: sticky;
  top: 0;
  height: 56px;
  z-index: 10;
  box-shadow: ${props => props.theme.effects.boxShadow.cards};
  @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
    top: -1px;
  }
`;
// Note, the last @media is because of a Chrome bug with high dpi devices
// (retina)
// Link to issue on Google: https://bit.ly/35gkOhY
// Link to where I got the chrome detect: https://www.browserstack.com/guide/create-browser-specific-css#toc2

const TopNav = ({
  routes = defaultRoutes,
  ...props
}) => (
  <Header variant='full'>
    {/* Visually hidden extra top-level navigation - see GROW-376 */}
    <VisuallyHidden>
      <nav aria-label='Customer Service'>
        <a href='/about-us'>About Us</a>
        <a href='/https://assets.ctfassets.net/ljtyf78xujn2/1HCXSo66lWoLcQTlX4clK9/dc90b273b64e489029124282b19dc2af/Glowforge_Manual_2.9.pdf'>
          User Manual
        </a>
        <a href='/support'>Support</a>
      </nav>
    </VisuallyHidden>
    <MobileNav routes={routes} {...props} />
    <DesktopNav routes={routes} {...props} />
  </Header>
);

TopNav.propTypes = {
  routes: PropTypes.shape({
    primaryRoutes: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
        local: PropTypes.bool,
        logoLink: PropTypes.bool,
      }),
    ),
    userRoutes: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
        local: PropTypes.bool,
      }),
    ),
  }),
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  userFetchState: PropTypes.oneOf([
    'pending',
    'error',
    'complete'
  ]),
  buttonSection: PropTypes.node,
  activeLink: PropTypes.string,
};

TopNav.defaultProps = {
  routes: {},
  user: {},
  userFetchState: 'pending',
  buttonSection: null,
  activeLink: '',
};

export default TopNav
