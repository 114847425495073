import {
  BREAKPOINTS,
  COLORS,
  EFFECTS,
  GUTTERS,
  QUERIES,
} from './constants'
import { typography, variants } from './variants'
import { getHexOpacity } from './utils'

const ThemeObject = {
  breakpoints: BREAKPOINTS,
  colors: COLORS,
  effects: EFFECTS,
  gutters: GUTTERS,
  queries: QUERIES,
  typography: {
    ...typography,
    variants,
  },
  utils: {
    getHexOpacity
  },
}

export default ThemeObject