import { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { PopoverAnchorWrapper } from './usePopover.styles';
import Popover from './PopoverComponent';

/* eslint-disable max-len */
/**
 * @typedef popoverOptions
 * @property {{ 'open' | 'closed' }} initialState - whether the PopOver should start open or closed.
 */

/**
 * @typedef Popover
 * @property {{ 'top' | 'right' | 'bottom' | 'left'}} position - The absolute position of the popover
 *  as relates to its parent element.
 * @property {{'start' | 'end'}} align - Whether to align the popover at the start or end of its parent.
 * @property {number} arrowSize - the size of the popover Arrow. Defaults to 8. Currently a bit wonky.
 */

/**
 * @typedef hookReturn
 * @property {ref} anchorRef - A ref to attach to the desired anchor element. Used to place Popover
 * @property {Popover} Popover - the Popover element that will wrap your Children.
 * @property {node} PopoverAnchorWrapper - An optional helper Wrapper to position the Popover.
 * @property {boolean} isOpen - Whether the Popover is currently open.
 * @property {function} toggle - Imperatively toggles the popover
 * @property {function} close - Imperatively closes the popover
 * @property {object} popoverProps - a helper object for destructring into Popover Component
 * @property {boolean} popoverProps.isOpen - Whether the Popover is currently open.
 * @property {function} popoverProps.close - Imperatively closes the popover
 * @property {ref} popoverProps.anchorRef - A ref to attach to the desired anchor element. Used to place Popover
 */

/**
 * The usePopover hook makes a popover/tool-tip like experience with which users can interact.
 * It returns a `<Popover>` used to wrap whichever elem you you want to anchor the Popover onto.
 * It also exposes imperative handlers and an isOpen boolean.
 *
 * The tricky piece of this is that you need to destructure popoverProps into <Popover/>.
 * This is so that way the instantiated Popover component know about and react to the hook's state.
 *
 * @param {popoverOptions} options - The popover's initial State.
 * @returns {}
 */
const usePopover = (options = {}) => {
  const { initialState } = options;
  const anchorRef = useRef();
  const [isOpen, setOpen] = useState(initialState === 'open');

  const toggle = useCallback((e) => {
    e.stopPropagation();
    setOpen((prev) => !prev)
  }, []);

  const close = useCallback(() => setOpen(false), []);

  const popoverProps = {
    isOpen,
    close,
    anchorRef,
  };

  return {
    isOpen,
    popoverProps,
    PopoverAnchorWrapper,
    Popover,
    toggle,
    close,
    anchorRef,
  };
};

usePopover.propTypes = {
  options: PropTypes.shape({
    initialState: PropTypes.bool,
  }),
};

/* eslint-enablemax-len */

export default usePopover;
