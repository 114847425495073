import PropTypes from 'prop-types';
import React from 'react';
import { clientOnly, FakeWindow } from '../../../util';
import { windowType } from '../prop-types';
import usePageviewsAndTime from './use-pageviews-and-time';

const PageviewsAndTime = ({
  name,
  minutes,
  pages,
  url,
  callback,
  root,
  reset,
}) => {
  usePageviewsAndTime({
    name,
    minutes,
    pages,
    url,
    callback,
    root,
    reset,
  });

  return <></>;
};
PageviewsAndTime.propTypes = {
  name: PropTypes.string.isRequired,
  minutes: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  root: windowType,
  reset: PropTypes.bool,
};
PageviewsAndTime.defaultProps = {
  root: clientOnly(() => window) || new FakeWindow(),
  reset: false,
};

export default PageviewsAndTime;
