/* eslint-disable react/prop-types */
import NextApp from 'next/app';
import getConfig from 'next/config';
import React, { useEffect } from 'react';
import Head from 'next/head';
import useSWR from 'swr';
import { ThemeProvider, StyleSheetManager } from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import isPropValid from '@emotion/is-prop-valid';
import { LazyMotion } from 'framer-motion';

import { theme, GlobalStyle } from '@glowforge/gf-styled/src/theme';
import PageMetadata from '@glowforge/gf-styled/src/components/contentful-views/page-metadata';
import { interpretMetadata } from '@glowforge/gf-styled/src/models/page-metadata';

import AppGlobalStyle from './app.styles';
import Scripts from '../components/scripts';
import Analytics from '../components/analytics';
import Footer from '../components/footer';
import RouterProgressBar from '../components/router-progress-bar/router-progress-bar';
import UserProvider from '../context/UserContext/UserProvider';
import LocationProvider from '../context/LocationContext/LocationProvider';
import FeatureFlagsContext from '../context/FeatureFlagsContext';
import ShareProgramProvider from '../context/ShareProgramContext/ShareProgramProvider';
import ModalPriorityProvider from '../context/ModalPriorityContext/ModalPriorityProvider';
import { getDefaultMetadata, getGlobalFooterRebrand } from '../api/contentful';
import { getAllFlagsState, allFlagsStateVariable } from '../api/feature-flags';
import { getNavbar } from '../api/navbar';
import { REACT_ROOT } from '../constants';
import { trackCoreWebVitals } from '../util/web-vitals';
import Navbar from '../components/navbar';

const loadFeatures = () =>
  import('@glowforge/gf-styled/src/util/framer-load-dom-features').then(
    (res) => res.default,
  );

const {
  publicRuntimeConfig: { GF_DOTCOM_GF_API_ENDPOINT },
} = getConfig();

const APP_CACHE_KEY = 'app';

/**
 * Fetch data required by all pages
 */
const fetchData = async () => {
  const [defaultMetadata, allFlags, footerRebrandContent] = await Promise.all([
    getDefaultMetadata(),
    getAllFlagsState(),
    getGlobalFooterRebrand(),
  ]);

  return {
    defaultMetadata,
    allFlags,
    footerRebrandContent,
  };
};

const App = ({
  initialData,
  countryCode,
  stateCode,
  pageProps,
  Component,
  builderNav
}) => {

  const { data: appData } = useSWR(APP_CACHE_KEY, () => fetchData(), {
    fallbackData: initialData,
    revalidateOnMount: true,
  });

  const { allFlags, defaultMetadata, footerRebrandContent } = appData;

  const {
    includeFooter = true,
    includeNav = true,
    hideBuyButton = false,
  } = pageProps;

  const interpretedMetadata = interpretMetadata(defaultMetadata);
  const { pageTitle: defaultTitle, ...trimmedMetadata } = interpretedMetadata;

  useEffect(() => {
    trackCoreWebVitals();
  }, []);

  /* eslint-disable react/jsx-props-no-spreading, jsx-a11y/html-has-lang */
  return (
    <StyleSheetManager
      enableVendorPrefixes
      // eslint-disable-next-line react/jsx-no-bind
      shouldForwardProp={(propName, elementToBeRendered) =>
        typeof elementToBeRendered === 'string' ? isPropValid(propName) : true
      }>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <AppGlobalStyle />
        <ModalPriorityProvider>
          <LocationProvider>
            <FeatureFlagsContext.Provider value={allFlags}>
              <Scripts />
              <UserProvider>
                <ShareProgramProvider>
                  <div className='app'>
                    <Analytics />
                    <Head>
                      <link
                        rel='alternate'
                        type='application/rss+xml'
                        title='Glowforge - the 3D Laser Printer » Home Comments Feed'
                        href='https://blog.glowforge.com/feed/'
                      />
                      <link rel='shortcut icon' href='/dotcom/favicon.png' />
                      <meta httpEquiv='x-ua-compatible' content='ie=edge' />
                      <meta name='robots' content='noodp' />
                      <meta property='og:locale' content='en_US' />
                      <meta name='viewport' content='width=device-width' />
                      <meta charSet='utf-8' />
                      <script
                        type='text/javascript'
                        /* eslint-disable max-len */
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: `
                        window._gf_react_root = '${REACT_ROOT}';
                        window.GF_MADLIB_ENDPOINT='${GF_DOTCOM_GF_API_ENDPOINT}/functions/put-email';
                        window.GF_COUNTRY_CODE='${countryCode}';
                        window.GF_STATE_CODE='${stateCode}';
                        window.${allFlagsStateVariable}=${JSON.stringify(
                            allFlags,
                          )};
                      `,
                        }}
                      /* eslint-enable max-len */
                      />
                    </Head>
                    <PageMetadata
                      {...trimmedMetadata}
                      pageTitle={defaultTitle || 'Glowforge'}
                    />
                    {/* lazy loading framer motion animations. */}
                    <LazyMotion features={loadFeatures} strict>
                      {includeNav ? (
                        <Navbar {...{ ...builderNav, hideBuyButton }} />
                      ) : null}
                      <RouterProgressBar includeNav={includeNav} />
                      <Component {...pageProps} />
                      <Footer
                        includeFooter={includeFooter}
                        footerRebrandContent={footerRebrandContent}
                      />
                    </LazyMotion>
                  </div>
                </ShareProgramProvider>
              </UserProvider>
            </FeatureFlagsContext.Provider>
          </LocationProvider>
        </ModalPriorityProvider>
      </ThemeProvider>
    </StyleSheetManager>
  );
};

App.getInitialProps = async (appContext) => {
  const [initialData, appProps] = await Promise.all([
    fetchData(),
    NextApp.getInitialProps(appContext),
  ]);
  
  // Used to get initial builderNav data -> update happens in Navbar component
  const builderNav = await getNavbar();
  return {
    initialData,
    builderNav,
    ...appProps,
  };
};

export default App;
