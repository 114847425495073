import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ContentSlider from './content-slider';
import MediaSlider from './media-slider';
import { SliderWrapper } from './slider.styles';
import 'swiper/swiper-bundle.min.css';

export default function SliderComponent({ id, slides, options }) {
  const { toggleActiveCardContent, initialSlide } = options;
  const [currentSlide, setCurrentSlide] = useState(initialSlide.desktop);
  if (slides?.length === 0) return 'The carousel is empty. Add slides.';

  return (
    <SliderWrapper>
      <MediaSlider {...{ id, slides, setCurrentSlide, options }} />
      {toggleActiveCardContent === true ? (
        <ContentSlider {...{ id, slides, currentSlide, options }} />
      ) : null}
    </SliderWrapper>
  );
};

SliderComponent.propTypes = {
  id: PropTypes.string.isRequired,
  slides: PropTypes.arrayOf(PropTypes.shape({})),
  options: PropTypes.shape({
    toggleActiveCardContent: PropTypes.bool,
    initialSlide: PropTypes.shape({
      mobile: PropTypes.number,
      tablet: PropTypes.number,
      desktop: PropTypes.number,
    }),
  }),
};

SliderComponent.defaultProps = {
  slides: [],
  options: {
    toggleActiveCardContent: true,
    initialSlide: {
      mobile: 0,
      tablet: 0,
      desktop: 0,
    },
  },
};
