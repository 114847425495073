// @ts-check
import getConfig from 'next/config';
import { clientOnly } from '../util';

/**
 * LaunchDarkly integration
 */

const {
  serverRuntimeConfig: {
    GF_DOTCOM_LAUNCH_DARKLY_SDK_KEY
  },
} = getConfig();

/**
 * Global variable where flag data is stored. Client-side code uses this global
 * data to look up flag state.
 */
export const allFlagsStateVariable = 'GF_ALL_FLAGS_STATE';

/**
 * Singleton instance of the LaunchDarkly client
 * https://docs.launchdarkly.com/sdk/server-side/node-js
 */
let ldClient;

/**
 * The LaunchDarkly client has to be initialized asynchronously, which means
 * flags will not be available immediately when the server starts up. Accessing
 * flags can only be done through this async function to avoid a situation where
 * flags are refererenced before they have been fetched. This function is only
 * invoked on the server.
 */
const getClient = async () => {
  if (!ldClient) {
    // Use `require` instead of a top level import so we can run this on the
    // server only
    // eslint-disable-next-line global-require
    const LaunchDarkly = require('@launchdarkly/node-server-sdk');
    ldClient = LaunchDarkly.init(GF_DOTCOM_LAUNCH_DARKLY_SDK_KEY);
  }
  await ldClient.waitForInitialization();

  return ldClient;
};

/**
 * If this is called on the server, we will fetch new data from the LaunchDarkly
 * API. If called client-side, flag state will have been stored in a global
 * variable in the document head, so we return that.
 */
export const getAllFlagsState = async () => {
  if (clientOnly(undefined)) {
    return window[allFlagsStateVariable] || {};
  }

  const client = await getClient();
  const context = {
    key: 'gf-dotcom',
    kind: 'application'
  }
  const flags = await client.allFlagsState(context);

  return flags.toJSON();
};
