import { createGlobalStyle } from 'styled-components'
import cssReset from './cssReset'
import markdownStyles from './markdownStyles'

/**
 * GlobalStyle is a globally injected style sheet.
 * ALL declarations will be applied globally, unless its ignore flag is set.
 * If any declaration reaches deeply or causes indirection, please document it thoroughly.
 * 
 * @param {boolean} ignoreCSSReset - Setting this will cause GlobalStyle to not reset CSS.
 * @param {boolean} ignoreMarkdownStyles - Setting this will cause GlobalStyle to not override MD.
 */

const GlobalStyle = createGlobalStyle`
  ${props => props.ignoreCSSReset ? null : cssReset}
  ${props => props.ignoreMarkdownStyles ? null : markdownStyles}
`

export default GlobalStyle