import React from 'react';

const ModalPriorityContext = React.createContext({
  modals: {},
  // eslint-disable-next-line no-unused-vars
  isModalOpen: (modalId) => false,
  // eslint-disable-next-line no-unused-vars
  openModal: (modalId, priority) => { },
  // eslint-disable-next-line no-unused-vars
  closeModal: (modalId) => { },
});

export default ModalPriorityContext;
