/* eslint-disable import/prefer-default-export */

/**
 * 
 * @param {Object} user 
 * @param {string} user.display_name
 * @param {string} user.first_name
 * @param {string} user.last_name
 * @returns 
 */
export const userDisplayName = (user) => {
  if (user.display_name) {
    return `${user.display_name}`;
  }
  if (user.first_name) {
    // John D.
    return `${user.first_name} ${user.last_name ? `${user.last_name?.slice(0,1)}.` : ''}`;
  }
  return user.email;
};