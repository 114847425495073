import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SVG from '../../svg/svg';

import {
  NavLink,
  Dropdown,
  DropdownIcon,
  DropdownContainer,
  DropdownItems,
} from './top-nav.styles';

function NavDropdown({ link, activeLink }) {
  const [toggleDropdown, setToggleDropdown] = useState(false);

  if (!link?.subnav?.value?.data) return null;

  const {
    subnav: {
      value: {
        data: { navItems },
      },
    },
  } = link;

  return (
    <DropdownContainer active={toggleDropdown}>
      <NavLink
        {...link.url ? { href: link.url } : {}}
        className='main-link'
        $active={link.url === activeLink}>
        {link.text}
        <DropdownIcon className='icon'>
          <SVG
            id='chevron-down'
            fill='#000'
            stroke='#000'
            width={10}
            height={10}
          />
        </DropdownIcon>
      </NavLink>
      <DropdownIcon
        className='icon'
        onClick={() => setToggleDropdown(!toggleDropdown)}>
        <SVG
          id='chevron-down'
          fill='#000'
          stroke='#000'
          width={10}
          height={10}
        />
      </DropdownIcon>
      <Dropdown className='dropdown-wrapper'>
        <DropdownItems className='dropdown-items'>
          {navItems.map((item) => (
            <NavLink
              key={item.text}
              {...item.url ? { href: item.url } : {}}
              active={link.url === activeLink}>
              {item.text}
            </NavLink>
          ))}
        </DropdownItems>
      </Dropdown>
    </DropdownContainer>
  );
}

NavDropdown.propTypes = {
  activeLink: PropTypes.string,
  link: PropTypes.shape({
    subnav: PropTypes.shape(),
    text: PropTypes.string,
    url: PropTypes.string,
  }),
};

NavDropdown.defaultProps = {
  activeLink: '',
  link: {},
};

export default NavDropdown;
