
export default {
  name: 'Glowforge:ROICalculator',
  image: 'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F64129cd54314415d9400530570344285?width=2000',
  friendlyName: 'ROI Calculator',
  noWrap: true,
  defaultStyles: {},
  inputs: [
    {
      type: 'list',
      name: 'optionsItemSize',
      friendlyName: 'Item Size/Items per Sheet',
      helperText: 'How many can you fit on a sheet? Also, minimum Unit Price (prevents negative numbers from displaying)', // eslint-disable-line max-len
      subFields: [
        {
          name: 'label',
          friendlyName: 'Example Size Item Name',
          type: 'string',
          defaultValue: 'Earring Sized',
        },
        {
          name: 'value',
          friendlyName: 'Item per PG Sheet',
          type: 'number',
          defaultValue: 50,
        },
        {
          name: 'minimumPrice',
          friendlyName: 'Minimum Price for item type',
          helperText: 'Can prevent negative numbers from showing in the earnings',
          type: 'number',
          defaultValue: 25,
        }
      ],
      defaultValue: [
        { label: 'Earring-sized', value: 50, minimumPrice: 10 },
        { label: 'Coaster-sized', value: 5, minimumPrice: 10 },
        { label: 'Mousepad-sized', value: 2, minimumPrice: 25 },
        { label: 'Cutting Board', value: 1, minimumPrice: 50},
      ]
    },
    {
      type: 'string',
      name: 'preItemSize',
      friendlyName: 'Text before item/sheet selection',
      defaultValue: "I'm going to make something",
    },
    {
      type: 'list',
      name: 'optionsMaterialsCost',
      friendlyName: 'Material cost',
      helperText: ' Material and cost per sheet',
      subFields: [
        {
          name: 'label',
          friendlyName: 'Material Name',
          type: 'string',
          defaultValue: 'Solid Wood',
        },
        {
          name: 'value',
          friendlyName: 'Material Cost',
          type: 'number',
          defaultValue: 30
        }
      ],
      defaultValue: [
        { label: 'Solid wood', value: 30 },
        { label: 'Plywood', value: 20 },
        { label: 'Acrylic', value: 10 },
        { label: 'Leather', value: 40 },
        { label: 'Stone', value: 15 },
      ]
    },
    {
      type: 'string',
      name: 'preMaterials',
      friendlyName: 'Text before materials selection',
      defaultValue: "made out of",
    },
    {
      type: 'list',
      name: 'optionsItemPrice',
      friendlyName: 'Unit Price',
      helperText: 'How much do you sell this for?',
      subFields: [
        {
          name: 'label',
          friendlyName: 'Item Price Label',
          type: 'string',
          defaultValue: '$10',
        },
        {
          name: 'value',
          friendlyName: 'Item Price (as a number)',
          type: 'number',
          defaultValue: 10
        }
      ],
      defaultValue: [
        { label: '$10', value: 10 },
        { label: '$25', value: 25 },
        { label: '$50', value: 50 },
        { label: '$100', value: 100 },
        { label: '$250', value: 250 },
        { label: '$500', value: 500 },
      ]
    },
    {
      type: 'string',
      name: 'preItemPrice',
      friendlyName: 'Text before item price selection',
      defaultValue: "I'll charge about",
    },
    {
      type: 'string',
      name: 'postItemPrice',
      friendlyName: 'Text after item price selection',
      defaultValue: "each,",
    },
    {
      type: 'list',
      name: 'optionsSalesVolume',
      friendlyName: 'Unit Volume',
      helperText: 'How many do you expect to sell?',
      subFields: [
        {
          name: 'label',
          friendlyName: 'Sales Volume Label',
          type: 'string',
          defaultValue: '5',
        },
        {
          name: 'value',
          friendlyName: 'Sales Volume',
          type: 'number',
          defaultValue: 5
        }
      ],
      defaultValue: [
        { label: '5', value: 5 },
        { label: '10', value: 10 },
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        { label: '100', value: 100 },
      ]
    },
    {
      type: 'string',
      name: 'preSalesVolume',
      friendlyName: 'Text before sales volume selection',
      defaultValue: "and sell about",
    },
    {
      type: 'string',
      name: 'postSalesVolume',
      friendlyName: 'Text after sales volume selection',
      defaultValue: "in a month",
    },
    {
      type: 'string',
      name: 'calculateButtonText',
      friendlyName: 'Calculate button text',
      defaultValue: "Calculate Earnings"
    },
    {
      type: 'string',
      name: 'earningsPreText',
      friendlyName: 'Earnings Pre Text',
      defaultValue: "You'll Make",
    },
    {
      type: 'string',
      name: 'perMonthText',
      friendlyName: 'Earnings Post Text',
      defaultValue: 'per month',
    },
    {
      type: 'string',
      name: 'materialsPostText',
      friendlyName: 'Materials Cost Text',
      defaultValue: "after material costs."
    },
    {
      type: 'string',
      name: 'glowforgePaymentPerMonth',
      friendlyName: 'Glowforge payment per month line',
      defaultValue: "Your Glowforge payment is only $139–$300/month.",
    }
  ]
}