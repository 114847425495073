// @ts-check
import React from 'react';
import {
  widgetDefaultProps,
  widgetPropType,
} from '@glowforge/gf-styled/src/models/widget';

import ExploreMaterials from './explore-materials';
import ExploreMaterialsSecondary from './explore-materials-secondary';

const ExploreMaterialsWidget = ({
  layout,
  materials,
  attributes,
  selectMaterialsDesktop,
  selectMaterialsMobile,
}) =>
  layout === 'primary' ? (
    <ExploreMaterials
      {...{
        materials,
        attributes,
        selectMaterialsDesktop,
        selectMaterialsMobile,
      }}
      isBuilder
    />
  ) : (
    <ExploreMaterialsSecondary
      {...{
        materials,
        attributes,
        selectMaterialsDesktop,
        selectMaterialsMobile,
      }}
      isBuilder
    />
  );

ExploreMaterialsWidget.propTypes = { ...widgetPropType };

ExploreMaterialsWidget.defaultProps = { ...widgetDefaultProps };

export default ExploreMaterialsWidget;
