import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function ExternalSVG({ className, height, onClick, url, width, Wrapper, wrapperClass }) {
	const [svgString, setSvgString] = useState('');
	useEffect(() => {
		fetch(url)
			.then((response) => response.text())
			.then((svgData) => {
				let parsedSvgString;
				try {
					const svg = new DOMParser().parseFromString(svgData, 'text/xml').documentElement;
					svg.setAttribute('class', className);
					svg.setAttribute('height', height);
					svg.setAttribute('width', width);
					parsedSvgString = new XMLSerializer().serializeToString(svg);
				} catch (e) {
					parsedSvgString = '';
				}
				setSvgString(parsedSvgString);
			})
			.catch(error => {
				console.error(error);
			});
	}, [url]);

	return <Wrapper onClick={onClick} className={wrapperClass} dangerouslySetInnerHTML={{ __html: svgString }} />;
}

ExternalSVG.propTypes = {
	url: PropTypes.string.isRequired,
	className: PropTypes.string.isRequired,
	wrapperClass: PropTypes.string.isRequired,
	Wrapper: PropTypes.elementType.isRequired,
	onClick: PropTypes.func,
};

ExternalSVG.defaultProps = {
	onClick: null,
};

export default ExternalSVG;
