import { idConfig } from "../util/shared-configs";

/* eslint-disable max-len */
const modalOpenButton = {
  '@type': '@builder.io/sdk:Element',
  layerName: 'Modal Open Button',
  responsiveStyles: {
    large: {
      textAlign: 'left',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  component: {
    name: 'Glowforge:Button',
    options: {
      text: 'Launch Modal',
      variant: 'primary',
      actionButton: true,
      action: 'openModal',
    },
  },
};

const modalCloseButton = {
  ...modalOpenButton,
  layerName: 'Modal Close Button',
  component: {
    ...modalOpenButton.component,
    options: {
      text: 'Close',
      variant: 'primary',
      actionButton: true,
      action: 'closeModal',
    },
  },
};

/**
 * Close button that appears at top right of modals
 */
const modalCloseIconButton = {
  '@type': '@builder.io/sdk:Element',
  layerName: 'Modal Close Icon',
  responsiveStyles: {
    large: {
      position: 'absolute',
      top: '0px',
      right: '0px',
      backgroundColor: 'transparent',
      borderRadius: '4px',
      border: 'none',
      padding: '10px',
      zIndex: '2',
    },
  },
  component: {
    name: 'Glowforge:Button',
    options: {
      text: '',
      variant: 'primary',
      actionButton: true,
      action: 'closeModal',
      addIcon: true,
      // X svg
      customIcon:
        'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F7f657d57fb1e419aa808651329de38e7?quality=60&width=200&height=200',
    },
  },
};

export const modalTypes = {
  popup: 'popup',
  launch: 'launch',
  referral: 'referral',
};

export default {
  name: 'Glowforge:LaunchModal',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fb78c987c709f460a8271ab27957fad5a',
  inputs: [
    {
      name: 'modalPriority',
      helperText:
        "Priority over other modals on the page. You'll need to coordinate this manually.",
      type: 'number',
      defaultValue: 10,
    },
    {
      name: 'modalUniqueId',
      type: 'string',
      required: true,
      defaultValue: 'change-me',
      helperText:
        'This ID should be unique across pages. It is stored in local storage.. It is used to coordinate launching the modal, as well as hiding on users returning',
    },
    {
      name: 'modalType',
      type: 'string',
      helperText:
        'Two types of modals: Pop-up and Launch. Launch requires user interaction, such as clicking a button. Pop-up shows automatically, after some delay.',
      enum: [
        {
          label: 'Pop-up',
          value: modalTypes.popup,
        },
        {
          label: 'Launch',
          value: modalTypes.launch,
        },
        {
          label: 'Referral',
          value: modalTypes.referral,
        },
      ],
      defaultValue: modalTypes.launch,
    },
    {
      name: 'contentLabel',
      type: 'string',
      helperText:
        'Give this modal a descriptive name for screen reader accessbility',
      defaultValue: 'modal',
    },
    {
      name: 'showPopupTimes',
      friendlyName: 'Show Pop-Up N times',
      helperText:
        'A number of times to show popup modal. Defaults to 1, as to not bombard users with modals.',
      type: 'number',
      defaultValue: 1,
      // builder.io evaluates showIf in a
      showIf: (options) => options.get('modalType') === 'popup',
    },
    {
      name: 'popupDelay',
      friendlyName: 'Pop-Up Delay, in milliseconds.',
      type: 'number',
      defaultValue: 1000,
      showIf: (options) => options.get('modalType') === 'popup',
    },
    {
      name: 'launchModal',
      type: 'list',
      broadcast: true,
      showIf: (options) => options.get('modalType') === 'launch',
      onChange: (options) => {
        if (options.get('launchModal').length > 1) {
          options.set('launchModal', options.get('launchModal').slice(0, 1));
          alert('maximum items is 1. Add children to block via editor.');
        }
      },
      subFields: [
        {
          name: 'content',
          type: 'uiBlocks',
          hideFromUI: true,
          defaultValue: [modalOpenButton],
        },
      ],
      defaultValue: [
        {
          content: [
            {
              '@type': '@builder.io/sdk:Element',
              layerName: 'Modal Open Section',
              component: {
                name: 'Glowforge:Section',
                options: {
                  maxWidth: 1200,
                  backgroundColor: 'white',
                },
              },
              children: [modalOpenButton],
            },
          ],
        },
      ],
    },
    idConfig,
  ],
  defaultStyles: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '50px',
    paddingBottom: '50px',
    marginTop: '0px',
    width: 'auto',
    display: 'inline-block',
  },

  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      layerName: 'Modal-Content',
      component: {
        name: 'Glowforge:Section',
        options: {
          maxWidth: 1200,
          backgroundColor: 'white',
        },
      },
      responsiveStyles: {
        large: {
          borderRadius: '4px',
          // account for navbar height
          height: '80vh',
          marginLeft: 'auto',
          marginRight: 'auto',
          position: 'absolute',
          overflow: 'auto',
          width: '80vw',
          padding: '20px',
          inset: '10vh 10vw',
        },
      },
      children: [
        modalCloseIconButton,
        {
          '@type': '@builder.io/sdk:Element',
          responsiveStyles: {
            large: {
              textAlign: 'left',
            },
          },
          component: {
            name: 'Glowforge:Text',
            options: {
              text: `I'm a section heading.`,
              as: 'h2',
              variant: 'title',
            },
          },
        },
        modalCloseButton,
      ],
    },
  ],
};
