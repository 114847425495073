

import badgeIconPro from '../../svg/badge-icon-pro.svg'
import blueHeat from '../../svg/blue-heart.svg'
import cart from '../../svg/cart.svg'
import checkCircle from '../../svg/check-circle.svg'
import checkmark from '../../svg/checkmark.svg'
import chevronBold from '../../svg/chevron-bold.svg'
import chevronDown from '../../svg/chevron-down.svg'
import chevronLight from '../../svg/chevron-light.svg'
import chevronMedium from '../../svg/chevron-medium.svg'
import close from '../../svg/close.svg'
import coupon from '../../svg/coupon.svg'
import customizable from '../../svg/customizable.svg'
import edit from '../../svg/edit.svg'
import error from '../../svg/error.svg'
import exclamationCircle from '../../svg/exclamation-circle.svg'
import glowforgeIcon from '../../svg/glowforge-icon.svg'
import glowforgeLogo from '../../svg/glowforge-logo.svg'
import heart from '../../svg/heart.svg'
import imgPlaceholder from '../../svg/img-placeholder.svg'
import infoCircle from '../../svg/info-circle.svg'
import info from '../../svg/info.svg'
import lock from '../../svg/lock.svg'
import longArrowRight from '../../svg/long-arrow-right.svg'
import menu from '../../svg/menu.svg'
import minus from '../../svg/minus.svg'
import pencilRuler from '../../svg/pencil-ruler.svg'
import playButton from '../../svg/play-button.svg'
import playCircleBlack from '../../svg/play-circle-black.svg'
import playCircle from '../../svg/play-circle.svg'
import plusSolid from '../../svg/plus-solid.svg'
import plus from '../../svg/plus.svg'
import premiumGem from '../../svg/premium-gem.svg'
import print from '../../svg/print.svg'
import proofgradeBadge from '../../svg/proofgrade-badge.svg'
import proofgrade from '../../svg/proofgrade.svg'
import referrals from '../../svg/referrals.svg'
import remove from '../../svg/remove.svg'
import save from '../../svg/save.svg'
import search from '../../svg/search.svg'
import blogSearch from '../../svg/blog-search.svg'
import blogSearchClose from '../../svg/blog-search-close.svg'
import settings from '../../svg/settings.svg'
import signout from '../../svg/signout.svg'
import sliders from '../../svg/sliders.svg'
import spinner from '../../svg/spinner.svg'
import success from '../../svg/success.svg'
import timesCircle from '../../svg/times-circle.svg'
import trash from '../../svg/trash.svg'
import unlock from '../../svg/unlock.svg'
import warning from '../../svg/warning.svg'
import arrowLeft from '../../svg/arrow-left.svg';

export default {
  'badge-icon-pro': badgeIconPro,
  'blue-heart': blueHeat,
  cart,
  'check-circle': checkCircle,
  checkmark,
  'chevron-bold': chevronBold,
  'chevron-down': chevronDown,
  'chevron-light': chevronLight,
  'chevron-medium': chevronMedium,
  close,
  coupon,
  customizable,
  edit,
  error,
  'exclamation-circle': exclamationCircle,
  'glowforge-icon': glowforgeIcon,
  'glowforge-logo': glowforgeLogo,
  heart,
  'img-placeholder': imgPlaceholder,
  'info-circle': infoCircle,
  info,
  lock,
  'long-arrow-right': longArrowRight,
  menu,
  minus,
  'pencil-ruler': pencilRuler,
  'play-button': playButton,
  'play-circle-black': playCircleBlack,
  'play-circle': playCircle,
  'plus-solid': plusSolid,
  plus,
  'premium-gem': premiumGem,
  print,
  'proofgrade-badge': proofgradeBadge,
  proofgrade,
  referrals,
  remove,
  save,
  search,
  'blog-search': blogSearch,
  'blog-search-close': blogSearchClose,
  settings,
  signout,
  sliders,
  spinner,
  success,
  'times-circle': timesCircle,
  trash,
  unlock,
  warning,
  'arrow-left': arrowLeft,
}