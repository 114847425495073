import { createGlobalStyle } from 'styled-components'
import { theme } from '@glowforge/gf-styled/src/theme';

const AppGlobalStyle = createGlobalStyle`
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.hidden {
  display: none;
}

/* Override Osano default fonts */
.osano-cm-window {
  font-family: 'Exo 2', sans-serif;;

  .osano-cm-info {
    .osano-cm-header {
      font-size: 1.25rem;
    }

    .osano-cm-label {
      font-size: 1.125rem;
    }

    .osano-cm-description {
      font-size: 1rem;
    }

    .osano-cm-description p {
      font-weight: 300;
    }
  }
}

/* Override Osano button defaults */
.osano-cm-button {
  box-shadow: 0 0.625rem 0.9375rem rgba(0, 0, 0, 0.2);
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 700;
  font-size: 1.2rem;
  border-color: ${theme.colors.magic.buttonBase};
  background-color: ${theme.colors.magic.buttonBase};
  border-radius: 10rem;
  padding: 0.375rem 1.125rem;
  text-decoration: unset;
  white-space: nowrap;
}

.osano-cm-button:hover {
  border-color: ${theme.colors.magic.base};
  background-color: ${theme.colors.magic.base};
}

.osano-cm-info-dialog-header button {
  border-color: transparent;
  background-color: transparent;
  stroke: black;
}

.osano-cm-info-dialog-header button:focus {
  background-color: black;
  stroke: white;
}

.osano-cm-widget { display: none; }
`;

export default AppGlobalStyle;
