import React from 'react';
import styled from 'styled-components'
import PropTypes from 'prop-types';
import get from 'lodash/get';

export const mediaObjectPropTypes = {
  file: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

const MediaObject = ({ file,title,description }) => (
  <div className='MediaObject'>
    <img
      className='mediaObjectImage'
      src={`${get(file, 'url')}?w=800`}
      alt={title}
    />
    {description && <div className='caption'>{description}</div>}
  </div>
);

MediaObject.propTypes = mediaObjectPropTypes;

MediaObject.defaultProps = {
  title: null,
  description: null,
};

export default styled(MediaObject)`
text-align: center;
display: inline-flex;

.mediaObjectImage {
  max-width: 100%;
}

.caption {
  color: #c8cbd0;

  margin-top: 0.25rem;
  text-align: center;
}
`;
