const BREAKPOINT_VALUES = [
  770,
  960,
  1600
]

const BREAKPOINTS = [
  `${BREAKPOINT_VALUES[0] / 16}rem`,
  `${BREAKPOINT_VALUES[1] / 16}rem`,
  `${BREAKPOINT_VALUES[2] / 16}rem`,
];

const BREAKPOINTS_ALL = {
  tabletMin: BREAKPOINTS[0],
  desktopMin: BREAKPOINTS[1],
  desktopMax: BREAKPOINTS[2],
  navbarBreakpoint: 1129,
  navbarBreakpointDown: 1128,
}

const QUERIES = {
  'tabletAndUp': `(min-width: ${BREAKPOINTS_ALL.tabletMin})`,
  'desktopDown': `(max-width: ${BREAKPOINTS_ALL.desktopMin})`,
  'desktopAndUp': `(min-width: ${BREAKPOINTS_ALL.desktopMin})`,
  'maxDesktop': `(max-width: ${BREAKPOINTS_ALL.desktopMax})`,
  'navbarBreakpoint': `(min-width: ${BREAKPOINTS_ALL.navbarBreakpoint}px)`,
  'navbarBreakpointDown': `(max-width: ${BREAKPOINTS_ALL.navbarBreakpointDown}px)`
}

export {
  BREAKPOINT_VALUES,
  BREAKPOINTS_ALL, // Contains the navbar breakpoints
  BREAKPOINTS,
  QUERIES
};
