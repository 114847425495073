import React from 'react';
import PropTypes from 'prop-types';
import { Select, Option } from './roi-calculator.styles';

const StyledSelect = ({options, onChange, minimumValue = 0}) => (
  <Select onChange={(e) => onChange(parseInt(e.target.value, 10))}>
    {options.map((option) => {
      // minimum value check to ensure that we don't expose a negative value
      if(option.value >= minimumValue) {
        return (
          <Option key={option.label} value={option.value}>
            {option.label}
          </Option>
        )
      } 
      return null;
    })}
  </Select>
)

StyledSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  minimumValue: PropTypes.number
}

StyledSelect.defaultProps = {
  minimumValue: 0
}

export default StyledSelect