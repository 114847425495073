
import styled from 'styled-components';

const Image = styled('img')`
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;

    &.loader,
    &.result {
    max-width: 450px;
    height: 100%;
    max-height: 450px;
    margin: 0 auto;
    border-radius: 19px;
    border: 2px solid ${(props) => props.theme.colors.slate.lightest};
    }
`;

const Option = styled('div')`
    display: flex;
    width: 100%;
    height: auto;

    input {
        clip: rect(0, 0, 0, 0);
        overflow: hidden;
        position: absolute;
        height: 1px;
        width: 1px;
    }

    label {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        min-height: auto;
        overflow: hidden;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    input[name='phrase'] + label {
        border: 2px solid transparent;
        border-radius: 8px;
        font-family: 'Space Grotesk', 'Space Grotesk', sans-serif;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        font-size: 21px;
        letter-spacing: -1.14;
        font-weight: 600;

        span {
            padding: 18px;
        }
    }

    input[name='art-style'] + label {
        text-align: center;
        border-width: 3px;
        font-size: 17px;
        letter-spacing: -1.14;
        padding: 3px;

        img {
            border-radius: 8px;
            border: 3px solid transparent;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
        }
        span {
            padding: 14px 14px 0;
        }
    }

    input:checked + label {
        border-color: ${(props) => props.theme.colors.magic.base};
    }

    input:checked + label img {
        border-color: ${(props) => props.theme.colors.magic.base};
    }

    @media ${(props) => props.theme.queries.tabletAndUp} {
        input[name='phrase'] + label {
            min-height: 131px;
        }
    }
`;

export {
    Option,
    Image
}