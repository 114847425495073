import styled from 'styled-components';
import css from '@styled-system/css';
import PropTypes from 'prop-types';

const topNavHeight = '56px';

const getHeroHeight = (height, includeNav) => {
  switch (height) {
    case '25vh':
      return `calc(25vh - ${includeNav ? topNavHeight : '0px'})`;
    case '33vh':
      return `calc(33vh - ${includeNav ? topNavHeight : '0px'})`;
    case '50vh':
      return `calc(50vh - ${includeNav ? topNavHeight : '0px'})`;
    case '75vh':
      return `calc(75vh - ${includeNav ? topNavHeight : '0px'})`;
    case '100vh':
      return `calc(100vh - ${includeNav ? topNavHeight : '0px'})`;
    default:
      return `calc(100vh - ${includeNav ? topNavHeight : '0px'})`;
  }
};

const setYoutubeAspectRatio = (props) => {
    const [youtubeUrl, verticalVideo] = props.youtubeEmbed;
    if(youtubeUrl.length === 0){
      return ''
    }
    return `
      	${verticalVideo === true
          ? 'padding-bottom: calc(100% * 16/9);'
          : 'padding-bottom: calc(100% * 9/16);'
        }
        @media ${props.theme.queries.desktopAndUp} {
          padding-bottom: calc(100% * 9/16);
        }
        > div{
          z-index: 0;
        }
    `
 }

const Wrapper = styled('div')`
  ${(props) =>
    css({
      color: props.textColor,
    })}
  height: 100%;
  min-height: ${(props) =>
    getHeroHeight(props.heroHeightMobile, props.includeNav)};

  @media ${(props) => props.theme.queries.desktopAndUp} {
    min-height: ${(props) =>
      getHeroHeight(props.heroHeightDesktop, props.includeNav)};
  }

  ${(props) => setYoutubeAspectRatio(props)}
 `;

Wrapper.propTypes = {
  heroHeightDesktop: PropTypes.string,
  heroHeightMobile: PropTypes.string,
  includeNav: PropTypes.bool,
};

Wrapper.defaultProps = {
  heroHeightDesktop: '100vh',
  heroHeightMobile: '100vh',
  includeNav: true,
};

const Background = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${(props) =>
    props.isVideoMobile
    ? 'background-image: none;'
    : `background-image: url(${
      props.backgroundMobile || props.background
    }?w=800&fm=jpg&fl=progressive&q=70);`}
  background-position: ${(props) => props.backgroundPositionMobile};
  background-repeat: ${(props) => props.backgroundRepeatMobile};
  background-size: ${(props) => props.backgroundSizeMobile};
  overflow: hidden;
  pointer-events: none;
  /* pull behind any siblings. */
  z-index: -2;

  @media ${(props) => props.theme.queries.desktopAndUp} {
    /* Only show background if there's no video */
    ${(props) =>
      props.isVideo
        ? 'background-image: none;'
        : `background-image: url(${props.background}?fm=jpg&fl=progressive&q=70);`}
    background-position: ${(props) => `${props.backgroundPosition}`};
    background-repeat: ${(props) => props.backgroundRepeat};
    background-size: ${(props) => props.backgroundSize};
  }
`;

const Overlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) =>
    props.theme.utils.getHexOpacity(props.theme.colors.black, props.opacity)};
  pointer-events: none;
  z-index: -1;
`;

const Video = styled('video')`
  /*
    We currently do not load video on mobile.
    We might want to reconsider this as a passed in property, so we can have mobile videos.
  */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
`;


const ChildrenWrapper = styled('div')`
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

export { Background, Overlay, Video, Wrapper, ChildrenWrapper };
