import { variant } from '@styled-system/variant';
import { css } from 'styled-components';

const TYPOGRAPHY_BASE_CSS = css`
  font-size: var(--font-size-min);
  @media ${(props) => props.theme.queries.desktopAndUp} {
    font-size: var(--font-size-max);
  }
  @supports (font-size: clamp(1rem, 1rem, 1rem)) {
    font-size: clamp(
      var(--font-size-min, 1rem),
      calc(1rem + var(--font-size-target, 2vw)),
      var(--font-size-max, 1.3rem)
    );
  }
  /*
    Fix for safari bug, whereby resizing window doesn't update vw in calc().
    https://stackoverflow.com/questions/63965489/safari-14-min-max-clamp-not-working-with-vw-and-px-values
    N.B.: To use this trick in SCSS/SASS/Less, use 0.000001vh;
  */
  min-height: 0vh;
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  text-transform: var(--text-transform);
  line-height: var(--line-height, 1);
  letter-spacing: var(--letter-spacing);
  @media ${(props) => props.theme.queries.desktopAndUp} {
    letter-spacing: var(--letter-spacing-desktop, var(--letter-spacing));
  }
`;

const typography = {
  baseCSS: TYPOGRAPHY_BASE_CSS,
  title: {
    '--font-family': '"Space Grotesk", sans-serif',
    '--font-size-min': `${51 / 16}rem`,
    '--font-size-max': `${85 / 16}rem`,
    '--font-size-target': '5.5vw',
    '--font-weight': '500',
    '--text-transform': 'none',
    '--letter-spacing-desktop': '-4.25px',
    '--letter-spacing': '-2.55px',
    '--line-height': '1',
  },
  heading: {
    '--font-family': '"Space Grotesk", sans-serif',
    '--font-size-min': `${26 / 16}rem`,
    '--font-size-max': `${38 / 16}rem`,
    '--font-weight': '500',
    '--text-transform': 'none',
    '--letter-spacing': '-1.3px',
    '--letter-spacing-desktop': '-1.9px',
    '--line-height': '1.2',
  },
  subheading: {
    '--font-family': '"Space Grotesk", sans-serif',
    '--font-size-min': `${16 / 16}rem`,
    '--font-size-max': `${22 / 16}rem`,
    '--font-weight': '500',
    '--text-transform': 'none',
    '--letter-spacing': '-0.4px',
    '--letter-spacing-desktop': '-0.55px',
    '--line-height': '1.45',
  },
  header4: {
    '--font-family': '"Space Grotesk", sans-serif',
    '--font-size-min': '1rem',
    '--font-size-max': '1.5rem',
    '--font-weight': '600',
    '--text-transform': 'none',
  },
  caption: {
    '--font-family': '"Space Grotesk", sans-serif',
    '--font-size-min': `${14 / 16}rem`,
    '--font-size-max': `${14 / 16}rem`,
    '--font-weight': '600',
    '--text-transform': 'uppercase',
  },
  paragraph: {
    '--font-family': '"Exo 2", sans-serif',
    '--font-size-min': `${17 / 16}rem`,
    '--font-size-max': `${17 / 16}rem`,
    '--font-weight': '400',
    '--text-transform': 'none',
    '--letter-spacing': '-0.08px',
    '--letter-spacing-desktop': '-0.09px',
    '--line-height': '1.5',
  },
};

export const variants = variant({
  variants: typography,
});

export default typography;
