import { idConfig } from "../util/shared-configs";

/* eslint-disable max-len */
export const ACTIONS_ENUM = {
  closeModal: 'closeModal',
  openModal: 'openModal',
};

const buttonConfig = {
  name: 'Glowforge:Button',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F81a15681c3e74df09677dfc57a615b13',
  noWrap: true,
  defaultStyles: {
    flexDirection: 'row',
  },
  inputs: [
    {
      name: 'variant',
      type: 'string',
      defaultValue: 'primary',
      enum: ['primary', 'ghost', 'ghost-blue'],
    },
    {
      name: 'text',
      type: 'string',
      defaultValue: 'Button Text',
    },
    {
      type: 'boolean',
      name: 'actionButton',
      defaultValue: false,
    },
    {
      type: 'text',
      name: 'action',
      enum: [
        {
          label: 'Close Modal',
          value: ACTIONS_ENUM.closeModal,
        },
        {
          label: 'Open Modal',
          value: ACTIONS_ENUM.openModal,
        },
      ],
      showIf: (options) => options.get('actionButton'),
    },
    {
      name: 'link',
      type: 'url',
      defaultValue: null,
      showIf: (options) => !options.get('actionButton'),
    },
    {
      name: 'dotcomInternalNavigation',
      type: 'boolean',
      defaultValue: false,
      showIf: (options) => options.get('link'),
    },
    {
      name: 'openLinkInNewTab',
      helperText:
        'If you have a link, turn this on if you want it to open a new page instead of navigating away from the current one.',
      type: 'boolean',
      defaultValue: false,
      showIf: (options) => options.get('link'),
    },
    {
      name: 'addIcon',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'chooseCommonIcon',
      helperText:
        "Choose from an existing icon that is already on the platform. If you don't see it, there is an upload option as well.",
      type: 'string',
      enum: [
        {
          label: 'None',
          value: null,
        },
        {
          label: 'Right Arrow',
          value:
            'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fb08ad2d1eee44230b8f3675858105e12',
        },
        {
          label: 'Play',
          value: 'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F889a87ffdd554918808fb03b9cdd37f2',
        },
      ],
      defaultValue: 'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fb08ad2d1eee44230b8f3675858105e12',
      showIf: (options) => options.get('addIcon'),
    },
    {
      name: 'customIcon',
      type: 'file',
      allowedFileTypes: ['svg'],
      showIf: (options) => options.get('addIcon'),
    },
    {
      name: 'iconSize',
      type: 'number',
      defaultValue: 24,
      showIf: (options) =>
        options.get('addIcon') &&
        (options.get('customIcon') || options.get('chooseCommonIcon')),
    },
    {
      name: 'noMobileBoxShadow',
      type: 'boolean',
      defaultValue: false,
    },
    idConfig,
  ],
};

export default buttonConfig;
