import PropTypes from 'prop-types';
import styled from 'styled-components';
import css from '@styled-system/css';
import variant from '@styled-system/variant';

const Card = styled('div')
  .attrs((props) => ({
    className: `gf-styled__card__${props.variant}`,
  }))`
  position: relative;
  border-radius: 6px;
  isolation: isolate; // Cards should have their own stacking context
  // allows child content to be stretched to edge, but still have border-radius
  overflow: ${(props) => (props.overflowHidden ? 'hidden' : 'visible')};
  box-shadow: var(--hover-box-shadow);

  ${(props) =>
    css({
      backgroundColor: props.backgroundColor,
    })}

  @media ${(props) => props.theme.queries.desktopAndUp} {
    box-shadow: var(--box-shadow);

    ${(props) =>
      css({
        backgroundColor:
          props.variant === 'hoverOnly' ? 'unset' : props.backgroundColor,
      })}
    transition: box-shadow 0.25s ease-in-out, background-color 0.25s ease-in-out;
  }


  &:hover {
    @media ${(props) => props.theme.queries.desktopAndUp} {
      cursor: ${(props) => (props.hoverPointer ? 'pointer' : 'auto')};
      box-shadow: var(${(props) =>
        props.variant === 'hoverOnly' ? '--hover-box-shadow' : '--box-shadow'});
      ${(props) =>
        css({
          backgroundColor: props.backgroundColor,
        })};
    }
  }

  ${(props) =>
    variant({
      variants: {
        primary: {
          '--box-shadow': props.theme.effects.boxShadow.cards,
          '--hover-box-shadow': props.theme.effects.boxShadow.cards,
        },
        hoverOnly: {
          '--box-shadow': 'none',
          '--hover-box-shadow': props.theme.effects.boxShadow.cards,
        },
      },
    })}
`;

Card.propTypes = {
  backgroundColor: PropTypes.string,
  overflowHidden: PropTypes.bool,
  hoverPointer: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'hoverOnly']),
};

Card.defaultProps = {
  variant: 'primary',
  overflowHidden: true,
  hoverPointer: false,
};

export default Card;
