import React from 'react';
import PropTypes from 'prop-types';
import SVG from '../components/svg';
import Button from '../components/button';
import Link from '../components/link';
import { registerWidget } from '../components/contentful-views/sections';

const variants = ['default','primary', 'ghost', 'ghost-blue', 'body', 'nav', 'legal', 'white'];

/**
 * Maps to the ButtonLink content model on Contentful
 * https://app.contentful.com/spaces/ljtyf78xujn2/content_types/buttonLink/fields
 */
const ButtonLinkWidget = ({ model }) => {

  // Get fields that need to be converted for component
  let icon = model?.fields?.icon?.fields?.id;
  icon = icon && <SVG id={icon} className='buttonIcon' />;

  // Make sure legacy buttonLinks have an appropriate variant
  const variant = model?.fields?.type;
  const componentProps = {
    className: model?.fields?.className,
    href: model?.fields?.url,
    icon,
    rel: model?.fields?.rel,
    target: model?.fields?.target,
    // Coming from contentful, let's default variant to nav items.
    variant: variants.includes(variant) ? variant : 'nav',
  };

  const children = model?.fields?.text;

  // Use type field to determine Button or Link
  let component = null;
  switch (componentProps.variant) {
    case 'default':
    case 'body':
    case 'nav':
    case 'legal':
    case 'white':
      component = (
        <Link {...componentProps}>
          {children}
        </Link>
      );
      break;
    default:
      component = (
        <Button {...componentProps}>
          {children}
        </Button>
      );
  }

  return component;
};

ButtonLinkWidget.propTypes = {
  model: PropTypes.shape({
    fields: PropTypes.shape({
      className: PropTypes.string,
      icon: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.shape({
          fields: PropTypes.shape({
            id: PropTypes.string
          })
        })
      ]),
      rel: PropTypes.string,
      target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
      text: PropTypes.string.isRequired,
      type: PropTypes.oneOf(variants),
    }),
  }),
};

ButtonLinkWidget.defaultProps = {
  model: {},
};

registerWidget('buttonLink', ButtonLinkWidget);

export default ButtonLinkWidget;
