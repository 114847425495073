import { BREAKPOINT_VALUES } from '../../theme/constants/breakpoints';

export default function sliderProperties(id, options) {

    const {
        slidesPerView,
        spaceBetween,
        initialSlide,
        speed = 400,
        effect = null,
        autoplay = false,
        loop = false,
    } = options

    const effectName = effect?.toLowerCase()

    return {
        slidesPerView: slidesPerView.mobile,
        spaceBetween: spaceBetween.mobile,
        initialSlide: (initialSlide.mobile || 1) - 1,
        speed,
        autoplay,
        loop,
        observer: true,
        observeParents: true,
        navigation: {
            nextEl: `[builder-id="${id}"] .slider-button-next`,
            prevEl: `[builder-id="${id}"] .slider-button-prev`,
        },
        pagination: {
            el: `[builder-id="${id}"] .slider-pagination`,
            clickable: true,
        },
        centeredSlides: effectName !== 'slide',
        effect: effectName,
        coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 300,
            modifier: 2,
            slideShadows: false,
        },
        breakpoints: {
            [BREAKPOINT_VALUES[0]]: {
                slidesPerView: slidesPerView.tablet,
                spaceBetween: spaceBetween.tablet,
                initialSlide: (initialSlide.tablet || 1) - 1,
                coverflowEffect: {
                    depth: 100,
                    modifier: 2,
                },
            },
            [BREAKPOINT_VALUES[1]]: {
                slidesPerView: slidesPerView.desktop,
                spaceBetween: spaceBetween.desktop,
                initialSlide: (initialSlide.desktop || 1) - 1,
                coverflowEffect: {
                    depth: 100,
                    modifier: 2,
                },
            },
        },

    }
}
