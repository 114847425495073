/**
 * @type {import('@builder.io/sdk/dist/src/builder.class').Component}
 */
export default {
  name: 'Glowforge:HubspotForm',
  image: 'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F583dd63ce1e44967b9b3d8f4b0279bac?quality=60&width=200&height=200',
  // both docsLink and description are not utilized by the builder ui? https://forum.builder.io/t/registering-custom-components-docslink/2480
  docsLink: 'https://glowforge.atlassian.net/wiki/spaces/SW/pages/2850226295/Component+-+Hubspot+Forms+in+builder.io',
  description: 'https://glowforge.atlassian.net/wiki/spaces/SW/pages/2850226295/Component+-+Hubspot+Forms+in+builder.io',
  inputs: [
    {
      friendlyName: 'Hubspot Form Link',
      helperText: `See docs here: https://glowforge.atlassian.net/wiki/spaces/SW/pages/2850226295/Component+-+Hubspot+Forms+in+builder.io`,
      name: 'formLink',
      type: 'string',
      defaultValue: '',
    },
    {
      name: 'buttonType',
      defaultValue: 'default',
      type: 'string',
      enum: ['default', 'edu'],
    }
  ],
}