import PropTypes from 'prop-types'
import styled from 'styled-components'
import variant from '@styled-system/variant'

/* GRID_GAP sizes are defined by Design spec. */
const GRID_GAP = 10

/*
  MAX_DESKTOP_WIDTH is defined by design spec.
  In effect, we want to keep our middle column's (main-start / main-end) desaktop max width to 1324.
  At the same time, we want to keep the minimum gutter to 42px.

  However, because full-start / main-start and main-end / full-end are grid columns, they
  have gaps of 10px between them (see grid-gap below).

  We therefore need to remove 10 px from each side for a total of 20 from the calculated max.
  */
const MAX_DESKTOP_WIDTH = `${1324 - (GRID_GAP * 2)}px`

/**
 * @typedef {'default' | 'body'} variant
 */

/**
 * GridContainer sets up our standard 4/12 column grids for mobile/desktop.
 * These columns are labeled [col-start #] / [col-end #] respectively.
 * It also accepts a variant prop. This prop is an emun ['body', 'full'].
 * 
 * The 4/12 column layout on the body variant is constrained to the middle of the screen, 
 * and includes responsive margins.
 * The body variant includes [full-start]/[full-end] and [main-start]/[main-end] grid-columns.
 * 
 * @param {object} props
 * @param {{ 'body' | 'full' }} props.variant - variant enum of ['body', 'full']
 * @returns {React.Element}
 */

const GridContainer = styled('div').attrs(props => ({
  className: `gf_styled__GridContainer__${props.variant}`
}))`
  position: relative;
  display: grid;
  width: 100%;
  grid-column-gap: ${GRID_GAP}px;
  
  grid-template-columns: var(--mobile-grid);

  @media ${props => props.theme.queries.desktopAndUp} {
    grid-template-columns: var(--desktop-grid--constrained);
  }

  @media (min-width: ${MAX_DESKTOP_WIDTH}) {
    grid-template-columns: var(--desktop-grid)
  }
  
  /* 
    css function does not work well with variant().
    @styled-system uses its own css() function under the hood.
    Trying to access theme object within css from within variant() just breaks.
  */
  
  ${props => variant({
    variants: {
      body: {
        '--mobile-grid': `
          [full-start main-start]
            repeat(4, [col-start] minmax(0, 1fr) [col-end])
          [main-end full-end];`,

          '--desktop-grid--constrained': `
          [full-start gutter-start main-start]
            repeat(12, [col-start] minmax(0, 1fr) [col-end])
          [full-start gutter-end main-end];`,

          '--desktop-grid': `
          [full-start gutter-start main-start]
            repeat(12, [col-start] minmax(0, 1fr) [col-end])
          [full-start gutter-end main-end];`,
      },
      full: {
        // account for grid gap and convert to rems.
        '--mobile-grid': `
          [full-start gutter-start]
            ${(props.theme.gutters.mobile - GRID_GAP)}px
              [main-start]
                repeat(4, [col-start] minmax(0, 1fr) [col-end])
              [main-end]
              ${(props.theme.gutters.mobile - GRID_GAP)}px
          [full-end gutter-end];`,

        '--desktop-grid--constrained': `
          [full-start gutter-start]
            ${(props.theme.gutters.desktop - (GRID_GAP * 2))}px
              [main-start]
                repeat(12, [col-start] minmax(0, 1fr) [col-end])
              [main-end]
            ${(props.theme.gutters.desktop - (GRID_GAP * 2))}px
          [full-end gutter-end];`,

        '--desktop-grid': `
          [full-start]
            minmax(
              0,
              calc((100vw - ${MAX_DESKTOP_WIDTH})/2)
            )
              [gutter-start]
                ${(props.theme.gutters.desktop - (GRID_GAP * 2))}px
              [main-start]
                repeat(12, [col-start] minmax(0, 1fr) [col-end])
              [main-end]
                ${(props.theme.gutters.desktop - (GRID_GAP * 2))}px
              [gutter-end]
            minmax(
              0,
              calc((100vw - ${MAX_DESKTOP_WIDTH})/2)
            )
          [full-end];`,
      },
      clear: {
        // used to clear the styles
        display: 'block',
      }
    }
  })}
`

GridContainer.propTypes = {
    /** Switches between variants.
     * `default` does not include left/right margins/
     * `body` includes left/right margins, and  constrains center content based on viewport width.
     * See XD Design docs for additional information or refer to `grid-container.less`.
     */
  variant: PropTypes.oneOf(['body', 'full']).isRequired
}

GridContainer.defaultProps = {
  variant: 'body'
}

export default GridContainer
