import getConfig from 'next/config';
import {
  getCookie,
  getCookieValue,
  setCookie
} from '../../util/cookies';

const {
  publicRuntimeConfig: {
    GF_DOTCOM_ENV,
    GF_DOTCOM_SHOPIFY_URL,
  },
} = getConfig();

export const parseAffiliate = (affiliate) => {
  const values = affiliate.trim().split('-');

  // The url does not create key:value pairs for this info
  // so if the template for it is changed, this needs to be updated
  // as well.
  return {
    name: values[0] || null,
    code: values[1] || null,
    params: (values.slice(2)).join('|') || null
  };
}

const parseAffiliateCookie = (cookie) => ({
    name: getCookieValue(cookie, 'name|', 'code|'),
    code: getCookieValue(cookie, 'code|', 'params|'),
    params: getCookieValue(cookie, 'params|'),
  });

// Based on the tracking URL set up in Impact: https://app.impact.com/secure/advertiser/tracking-settings/gateway-trackingsettings-flow.ihtml?execution=e2s1
// If this gets changed, it risks affecting this logic.
export const parseAffiliateURL = () => {
  if (typeof window !== "undefined") {
  const campaign = new URLSearchParams(window.location.search).get('utm_campaign') || '';

  const source = new URLSearchParams(window.location.search).get('utm_source') || '';

  if (!campaign || !source) return null;

  if (source !== 'impact') return null;

  /* 
  * UTM campaign returns the name of the affiliate along 
  * with other information, we'll want to parse that out.
  * All campaign values are - separated, so we'll use that
  */
  return parseAffiliate(decodeURI(campaign));
  }
  return null;
}

export const getAffiliateInfo = () => {
  const existingAffiliate = getCookie('affiliate');
  let affiliateInfo;

  if (existingAffiliate) {
    affiliateInfo = {
      ...parseAffiliateCookie(existingAffiliate),
      hasCookie: true,
    };
  } else {
    affiliateInfo = parseAffiliateURL();

    if (affiliateInfo?.params?.includes('content_partner')) return undefined;
  }
  return affiliateInfo;
}

export const buildShopifyPrinterUrl = (fbuy, fbshare, referralCode) => {

  if (!fbuy && !fbshare && !referralCode) return '';

  const collection = GF_DOTCOM_ENV === 'production' ? 'printers' : 'glowforge-printers';

  let url = `${GF_DOTCOM_SHOPIFY_URL}/collections/${collection}?`;

  if(fbuy) {
    url += `fbuy=${fbuy}`;
  }

  if(fbshare) {
    if(fbuy) {
      url += `&`;
    }
    url += `fbshare=${fbshare}`;
  }

  if(referralCode) {
    if(fbuy || fbshare) {
      url += `&`;
    }
    url += `referralCode=${referralCode}`;
  }

  return url;
}

export const parseReferralCookie = (cookie) => {
  const name = getCookieValue(cookie, 'name|', 'code|');
  const code = getCookieValue(cookie, 'code|', 'fbuy|');
  const fbuy = getCookieValue(cookie, 'fbuy|', 'fbshare|');
  const fbshare = getCookieValue(cookie, 'fbshare|', 'referrerId|');
  const referrerId = getCookieValue(cookie, 'referrerId|');

  return {
    code,
    name,
    shopUrl: buildShopifyPrinterUrl(fbuy, fbshare, code),
    parameters: {
      fbuy,
      fbshare,
      referrerId,
    },
  };
}

export const setShareProgramCookie = (cookieName, sharerName, sharerId, additionalInfo = {}) => {
  if (!sharerId) return;

  // To help keep the way we access the data consistent, we'll format the cookie
  // to be the same order as the url with the - as the separator.
  let uriComponent = `name|${sharerName}code|${sharerId}`;
  const additionalEntries = Object.entries(additionalInfo);

  if (additionalEntries.length > 0) {
    additionalEntries.forEach(([key, value]) => {
      uriComponent += `${key}|${value}`;
    });
  }

  // Setting dotcomOnly to false for use on Shopify
  setCookie(cookieName, uriComponent, 30, false);
}
