import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Wrapper,
  OptionsWrapper,
  Text,
  BottomWrapper,
  SelectContainer,
  SubmitContainer,
  CalculateButton,
  EarningsContainer,
  EarningsText,
  EarningsAmountText,
} from './roi-calculator.styles'

import StyledSelect from './styled-select';

const ROICalcualtor = ({
  calculateButtonText,
  earningsPreText,
  glowforgePaymentPerMonth,
  materialsPostText,
  optionsItemPrice,
  optionsItemSize,
  optionsMaterialsCost,
  optionsSalesVolume,
  perMonthText,
  postItemPrice,
  postSalesVolume,
  preItemPrice,
  preItemSize,
  preMaterials,
  preSalesVolume,
}) => {
  const [submitted, setSubmitted] = useState(false);

  const [size, setSize] = useState(optionsItemSize[0]?.value || 0);
  const [material, setMaterial] = useState(optionsMaterialsCost[0]?.value || 0);
  const [price, setPrice] = useState(optionsItemPrice[0]?.value || 0);
  const [minimumPrice, setMinimumPrice] = useState(0)
  const [volume, setVolume] = useState(optionsSalesVolume[0]?.value) || 0;

  useEffect(() => {
    // Filter through the item size prop which contains the minimum price
    const currentMinimum = optionsItemSize.filter(
      (item) => item.value === size
    )[0]?.minimumPrice || 0;
    // If the current minimum price is greater than then current selected price,
    // update that so we don't show a loss (negative earnings)
    if(currentMinimum > price) {
      setPrice(currentMinimum)
    }
    // Have to reset the minimum price so the price selection works again
    // as expected with all possible value options
    setMinimumPrice(currentMinimum)
  }, [size, price, setPrice, optionsItemSize])

  // Calculation based on a spreadsheet by Dan
  const perMonth = Math.floor( volume * ( price - ( material / size )));
  
  return (
    <Wrapper>
      <OptionsWrapper>
        <SelectContainer>
          <Text>{preItemSize}</Text>
          <StyledSelect
            options={optionsItemSize}
            onChange={(value) => setSize(value)}
          />
        </SelectContainer>
        <SelectContainer>
          <Text>{preMaterials}</Text>
          <StyledSelect 
            options={optionsMaterialsCost}
            onChange={(value) => setMaterial(value)}
          />
        </SelectContainer>
        <SelectContainer>
          <Text>{preItemPrice}</Text>
          <StyledSelect 
            options={optionsItemPrice}
            onChange={(value) => setPrice(value)}
            minimumValue={minimumPrice}
          />
          <Text>{postItemPrice}</Text>
        </SelectContainer>
        <SelectContainer>
          <Text>{preSalesVolume}</Text>
          <StyledSelect 
            options={optionsSalesVolume}
            onChange={(value) => setVolume(value)}
          />
          <Text>{postSalesVolume}</Text>
        </SelectContainer>
      </OptionsWrapper>
      <BottomWrapper>
        {submitted ? (
          <EarningsContainer>
            <EarningsText>
              {earningsPreText}
              <EarningsAmountText>
                ${perMonth}
              </EarningsAmountText> 
              {perMonthText}
            </EarningsText>
            <EarningsText>
              {materialsPostText}
            </EarningsText>
            <EarningsText style={{marginTop: 16}}>
              {glowforgePaymentPerMonth}
            </EarningsText>
          </EarningsContainer>
        ):(
          <SubmitContainer>
            <CalculateButton type='button' onClick={()=> setSubmitted(true)}>
              {calculateButtonText}
            </CalculateButton>
          </SubmitContainer>
        )}
      </BottomWrapper>
    </Wrapper>
  )
}

const optionsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value:  PropTypes.number,
  })
)

ROICalcualtor.propTypes = {
  preItemSize: PropTypes.string,
  optionsItemSize: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value:  PropTypes.number,
      minimumPrice: PropTypes.number,
    })
  ),
  preMaterials: PropTypes.string,
  optionsMaterialsCost: optionsPropType,
  preItemPrice: PropTypes.string,
  optionsItemPrice: optionsPropType,
  postItemPrice: PropTypes.string,
  preSalesVolume: PropTypes.string,
  optionsSalesVolume: optionsPropType,
  postSalesVolume: PropTypes.string,
  calculateButtonText: PropTypes.string,
  earningsPreText: PropTypes.string,
  perMonthText: PropTypes.string,
  materialsPostText: PropTypes.string,
  glowforgePaymentPerMonth: PropTypes.string,
}

ROICalcualtor.defaultProps = {
  preItemSize: "I'm going to make something",
  optionsItemSize: [],
  preMaterials: "made out of",
  optionsMaterialsCost: [],
  preItemPrice: "I'll charge about",
  optionsItemPrice: [],
  postItemPrice: "each,",
  preSalesVolume: "and sell about",
  optionsSalesVolume: [],
  postSalesVolume: "in a month.",
  calculateButtonText: "Calculate Earnings",
  earningsPreText: "You'll make",
  perMonthText: "per month",
  materialsPostText: "after material costs.",
  glowforgePaymentPerMonth: "Your Glowforge payment is only $139–$300/month.",
}

export default ROICalcualtor