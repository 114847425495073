import React from 'react';
import PropTypes from 'prop-types';
import LinkBaseComponent from './link-base';

/**
 * @property {node} children
 * @property {node} icon 
 */
const Link = ({
  children,
  icon,
  ...otherProps
}) => (
  <LinkBaseComponent {...otherProps}>
    {children}
    {icon && (
      <div className='button-icon-wrapper'>
        {icon}
      </div>
    )}
  </LinkBaseComponent>
);

Link.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  icon: PropTypes.node,
  target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
  ...LinkBaseComponent.propTypes
};

Link.defaultProps = {
  children: <span/>,
  icon: null,
  ...LinkBaseComponent.defaultProps
};

export default Link;
