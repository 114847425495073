/**
 * @type {import('@builder.io/sdk/dist/src/builder.class').Component}
 */
export default {
  name: 'exploreMaterials',
  friendlyName: 'Explore Materials',
  // shows options when editing children
  broadcast: true,
  noWrap: true,
  defaultStyles: {},
  image:
    'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fb082775cbfbb4ff595fb9a5fc5dc45c1?quality=60&width=200&height=200',
  inputs: [
    {
      name: 'layout',
      type: 'string',
      defaultValue: 'primary',
      enum: ['primary', 'secondary']
    },
    {
      name: 'selectMaterialsDesktop',
      type: 'string',
      defaultValue: '< Select materials >',
    },
    {
      name: 'selectMaterialsMobile',
      type: 'string',
      defaultValue: '< Swipe to select more materials >',
    },
    {
      type: 'list',
      name: 'materials',
      defaultValue: [
        {
          id: 'Name Placeholder',
          images: [
            {
              image:
                'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d',
            },
          ],
        },
      ],
      subFields: [
        {
          name: 'id',
          friendlyName: 'Material Name',
          type: 'string',
          defaultValue: 'Name Placeholder',
        },
        {
          name: 'images',
          type: 'list',
          subFields: [
            {
              name: 'image',
              type: 'file',
              allowedFileTypes: ['jpeg', 'jpg', 'png', 'webp'],
              defaultValue:
                'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d',
            },
          ],
        },
      ],
    },
  ],
};
