import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import styled from 'styled-components';
import { m } from 'framer-motion';

// Animation states
const progressBarVariants = {
  loading: {
    opacity: 1,
    width: '100%',
    display: 'block',
    transition: {
      duration: 1,
      ease: 'easeOut',
    },
  },
  complete: {
    width: '100%',
    opacity: 0,
    ease: 'linear',
    transitionEnd: {
      width: '0%',
      opacity: 1,
    },
  },
};

const StyledBarWrapper = styled('div')`
  position: fixed;
  top: ${(props) => (props.includeNav ? '56px' : '0px')};
  z-index: 4000;
  width: 100%;

  .progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    width: 100%;
    background-color: #00c0d4;
  }
`;

/**
 * Renders a progress bar whenever the route changes to provide immediate
 * feedback to users
 */
const RouterProgressBar = ({ includeNav }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onRouteChangeStart = useCallback(() => {
    setIsLoading(true);
  }, [setIsLoading]);

  const onRouteChangeEnd = useCallback(() => {
    setIsLoading(false);
  }, [setIsLoading]);

  useEffect(() => {
    Router.events.on('routeChangeStart', onRouteChangeStart);
    Router.events.on('routeChangeComplete', onRouteChangeEnd);
    Router.events.on('routeChangeError', onRouteChangeEnd);
    return () => {
      Router.events.off('routeChangeStart', onRouteChangeStart);
      Router.events.off('routeChangeComplete', onRouteChangeEnd);
      Router.events.off('routeChangeError', onRouteChangeEnd);
    };
  }, [onRouteChangeStart, onRouteChangeEnd]);

  /**
   * To develop loading style, set animate explicitly to
   * 'loading'
   */
  const animate = isLoading ? 'loading' : 'complete';

  return (
    <StyledBarWrapper includeNav={includeNav} className='RouteProgressBar'>
      <m.div
        className='progress-bar'
        initial='complete'
        variants={progressBarVariants}
        animate={animate}
      />
    </StyledBarWrapper>
  );
};

RouterProgressBar.propTypes = {
  includeNav: PropTypes.bool,
};

RouterProgressBar.defaultProps = {
  includeNav: true,
};

export default RouterProgressBar;
