import React from 'react';
import PropTypes from 'prop-types';

import { userDisplayName } from '../../../util/user-util';
import { usePopover } from '../../../hooks';
import SVG from '../../svg';

import UserPopover from './desktop-user-nav-popover';
import { SignInLink, UserButton, Wrapper } from './desktop-user-nav.styles';

const UserNavSection = ({
  activeLink,
  user = {},
  userFetchState = 'pending',
  routes = [],
}) => {
  const noUser = Object.keys(user).length === 0;
  const isPending = userFetchState === 'pending';

  const { Popover, PopoverAnchorWrapper, popoverProps, toggle, anchorRef } =
    usePopover();

  return (
    <Wrapper>
      {noUser || isPending ? (
        <SignInLink isPending={isPending} href='/login'>
          Sign In
        </SignInLink>
      ) : (
        <>
          <PopoverAnchorWrapper ref={anchorRef}>
            <UserButton
              onClick={toggle}
              style={{ cursor: 'pointer' }}
              role='button'
              tabIndex='0'
              aria-label='User Options'
              aria-haspopup='dialog'
              aria-expanded={popoverProps.isOpen}
              popoverOpen={popoverProps.isOpen}>
              {userDisplayName(user)}
              <SVG id='chevron-down' width={14} height={14} />
            </UserButton>
          </PopoverAnchorWrapper>
          <Popover {...popoverProps} location='left' align='end'>
            <UserPopover routes={routes} activeLink={activeLink} />
          </Popover>
        </>
      )}
    </Wrapper>
  );
};

UserNavSection.propTypes = {
  activeLink: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.shape({}),
  userFetchState: PropTypes.oneOf(['pending', 'error', 'complete']),
};

UserNavSection.defaultProps = {
  routes: [],
  user: {},
  userFetchState: 'pending',
  activeLink: '',
};

export default UserNavSection;
