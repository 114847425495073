import styled from 'styled-components';
import css from '@styled-system/css';
import Button from '@glowforge/gf-styled/src/components/button/button-base';
import Link from '@glowforge/gf-styled/src/components/link/link-base';

import GridContainer from '@glowforge/gf-styled/src/components/grid-container/grid-container';
import { Title } from '@glowforge/gf-styled/src/components/typography/Typography';

export const Background = styled('div')`
  height: 75%;
  width: 75%;
  margin-left: -1rem; // account for padding on TextContainer in mobile.
  grid-column: main-start / main-end;
  grid-row: 1;
  align-self: stretch; // fill up vertical space.
  background-image: url(${props => props.backgroundImage}?w=800);
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;

  @media ${props => props.theme.queries.desktopAndUp} {
    height: 87%;    
    background-image: url(${props => props.videoPresent ? 'none' : props.backgroundImage});
    margin-left: 0;
  }
`;

export const TextContainer = styled(GridContainer)`
  color: ${props => props.theme.colors.white};
  position: relative;
  grid-column: var(--grid-column);
  --grid-column: col-start 1 / col-end 4;
  // content does not extend to edges
  padding: 0 1rem;
  min-height: ${props => props.minHeightMobile};
  display: grid;
  /* align-items: center; */
  z-index: 0;

  ${props => css({
    backgroundColor: props.backgroundColor
  })}

  @media ${props => props.theme.queries.desktopAndUp} {
    grid-row: 1; // place on same row as image.
    width: 100%;
    margin-top: 50px;
    min-height: ${props => props.minHeightDesktop};;
    padding: 0;
  };
`;

export const FloatQuote = styled(Title)`
  position: absolute;
  font-size: 9rem;
  line-height: 0;
  top: 25px;
  z-index: 1;
  ${props => css({
    color: props.color
  })}
  @media ${props => props.theme.queries.desktopAndUp} {
    font-size: 11rem;
    grid-column: col-start 2 / col-end 2;
    left: -16px;
  }
`;

/**
* Wrapper for text content of text container.
*/
export const TextContentWrapper = styled('div')`
  grid-column: var(--grid-column);
  --grid-column: col-start 1 / col-end 12;
  grid-row: 1; // keep in same row as background textures.
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 72px 24px 40px 24px;

  > :first-child {
    font-size: 30px;
  }

  & ${Link}, ${Button} {
    align-self: stretch;
  }

  @media ${props => props.theme.queries.desktopAndUp} {
    & ${Link}, ${Button} {
      align-self: flex-start;
    }
  }
`;