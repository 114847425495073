import {
  HeroContainer,
  BackgroundImageContainer,
  HeroContentContainer,
  HeroContentWrapper,
  HeroTitle,
} from '../blog-hero.styles';
import { Overlay } from '../../hero-banner/hero-banner.styles';

const BlogHeroCategory = ({ props }) => {
  const {
    showOverlay,
    backgroundImageDesktop,
    backgroundImageMobile,
    backgroundPositionDesktop,
    backgroundPositionMobile,
    overlayOpacityLevel,
    textColor
  } = props;
  const { name, image } = props?.builderState?.content?.data || {};
  const desktopImage = backgroundImageDesktop ? backgroundImageDesktop : image;
  const mobileImage = backgroundImageMobile ? backgroundImageMobile : backgroundImageDesktop ? backgroundImageDesktop : image;
  return (
    name && (
      <HeroContainer>
        <HeroContentContainer>
          <HeroContentWrapper>
            <HeroTitle isCategoryTitle={true} textColor={textColor} className='blog-hero-title'>{name}</HeroTitle>
          </HeroContentWrapper>
        </HeroContentContainer>
        {(desktopImage || mobileImage) && (
          <>
            <BackgroundImageContainer
              desktopImage={desktopImage}
              mobileImage={mobileImage}
              backgroundPositionDesktop={backgroundPositionDesktop}
              backgroundPositionMobile={backgroundPositionMobile}
            />
            {showOverlay && <Overlay opacity={overlayOpacityLevel} />}
          </>
        )}
      </HeroContainer>
    )
  );
};

export default BlogHeroCategory;
