import React from 'react';
import Head from 'next/head'
import { interpretedMetadataPropType } from '../../models/page-metadata';
import MetaTags from './util/meta-tags';

/**
 * Render a next head component to build a rich set of metadata into the
 * page's <head>. Values deeper down the component tree override higher ones,
 * so this can easily be used to create and then override default values
 * throughout the page.
 * This does not render any directly displayed markup.
 * Page Metadata model in Contentful:
 *    https://app.contentful.com/spaces/ljtyf78xujn2/content_types/pageMetadata/fields
 */
const PageMetadata = (props) => {
  const {
    pageTitle, // Primary metadata title, destined for the <title> tag
    shareTitle, // Defaults to pageTitle; destined for opengraph/twitter title
    description,
    primaryImage,
    twitterCard,
    twitterSite,
    additionalMetadata,
    pageTitleText, // Deprecated metadata from parent Page object
    metaDescription, // Deprecated metadata from parent Page object
    jsonLd,
  } = props;

  // MetaTags literally handles <meta> tags. This includes the official
  // page description <meta name="description".../> as well as OpenGraph and
  // Twitter metadata tags e.g. <meta name="twitter:title".../> but, notably,
  // does not include the <title> tag, which are handed to Helmet separately.
  const meta = new MetaTags()
    .title(shareTitle || pageTitle || pageTitleText) // (og|twitter):title
    .description(description || metaDescription)
    .image(primaryImage && primaryImage.url)
    .openGraph('image:width', primaryImage && primaryImage.width)
    .openGraph('image:height', primaryImage && primaryImage.height)
    .twitter('card', twitterCard)
    .twitter('site', twitterSite)
    .pushAll(additionalMetadata);

  return (
    <Head>
      <title key='title'>{pageTitle || pageTitleText}</title>
        {meta.tags.map((m) => {
          const key = m?.name || m?.property || m?.content;
          return (
              <meta key={key} {...m} />
          )
        })}
      {jsonLd ? 
        <script 
          type='application/ld+json' 
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
          key='page-jsonld'
        />
        : null}
    </Head>
  );
};

PageMetadata.propTypes = interpretedMetadataPropType;

PageMetadata.defaultProps = {
  pageTitle: null,
  shareTitle: null,
  description: null,
  primaryImage: { url: null, width: null, height: null },
  openGraphType: null,
  twitterCard: null,
  twitterSite: null,
  additionalMetadata: [],
  pageTitleText: null,
  metaDescription: null,
  jsonLd: null,
};

export default PageMetadata;
