import styled from 'styled-components';
import { ArrowButton } from '../horizontal-scroll/horizontal-scroll.styles';

const SliderWrapper = styled('div')`
 position: relative;
 .swiper {
    z-index: 0;
    width: 100%;
  }
 .gf-styled__card__primary{
    box-shadow: none;
    overflow: unset;
 }
`;

const ContentSliderContainer = styled('div')`
    &.hidden{
        display: none;
    }
    .content-slide .gf-styled__card__primary > *:not(.content) {
        display: none;
    }
`;

const MediaSliderContainer = styled('div')`
    display: flex;
    align-items: center;
    .swiper-coverflow .swiper-slide:not(.swiper-slide-visible, .swiper-slide-active){
        opacity: 0;
    }
    .swiper-coverflow .swiper-slide {
        .gf-styled__card__primary > div::after {
            content: '';
            background: transparent;
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            transition: all .3s ease;
        }
        &:not(.swiper-slide-active){
            .gf-styled__card__primary > div::after {
                background: rgba(255,255,255,.6)
            }
        }
    }

    .no-content-slide .gf-styled__card__primary .content {
        display: none;
    }
 `;

const NavigationButton = styled(ArrowButton)`
    background: transparent;
    width: auto;
    height: auto;
    position: relative;
    align-self: stretch;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    svg{
        fill: ${(props) => props.theme.colors.white};
        stroke: ${(props) => props.theme.colors.white};
    }
    &.slider-button-next{
        right: -10px;
    }
    &.slider-button-prev{
        transform: rotate(-180deg);
        left: -10px;
    }
    &.swiper-button-disabled{
        opacity: 0;
        pointer-events: none;
    }
    @media ${(props) => props.theme.queries.desktopAndUp} {
        svg{
            width: 30px;
            height: 30px;
            fill: rgba(255, 255, 255, 0.6);
            stroke: rgba(255, 255, 255, 0.6);
            transition: all .3s ease;
        }
        &.slider-button-next{
            right: -1vw;
        }
        &.slider-button-prev{
            left: -1vw;
        }
        &:after{
            content: '';
            width: 60px;
            height: 60px;
            position: absolute;
            background: transparent;
            border-radius: 100%;
            transition: all .3s ease;
            left: 50%;
            transform: translateX(-53%);
        }
        &:hover {
            svg{
                fill: ${(props) => props.theme.colors.white};
                stroke: ${(props) => props.theme.colors.white};
            }
            &:after{
                background: rgba(255, 255, 255, 0.2);
            }
        }
    }
`

const SliderPagination = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border: 1px solid ${(props) => props.theme.colors.magic.base};
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.white};
    transition: background-color 0.25s ease-in-out;
    padding: 0;
    opacity: 1;
    &.swiper-pagination-bullet-active {
      background-color: ${(props) => props.theme.colors.magic.base};
    }
  }
`;

export {
    SliderWrapper,
    ContentSliderContainer,
    MediaSliderContainer,
    SliderPagination,
    NavigationButton
}