import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import UserContext, { fetchState } from './UserContext';
import { fetchUser } from '../../api/storefront';
import { fetchCoreUser } from '../../api/core';

export default function UserProvider({ children }) {
  const [user, setUser] = useState({});
  const [userFetchState, setUserFetchState] = useState(fetchState.PENDING);

  useEffect(() => {
    async function getUser() {
      try {
        const ecomUser = await fetchUser();
        const coreUser = await fetchCoreUser();

        const userInfo = { ...ecomUser, ...coreUser};

        if (userInfo && !userInfo.errors) {
          setUser(userInfo);
          setUserFetchState(fetchState.COMPLETE);
        } else {
          setUser({});
          setUserFetchState(fetchState.COMPLETE);
        }
      } catch (e) {
        setUser({});
        setUserFetchState(fetchState.ERROR);
      }
    }

    getUser();
  }, []);

  return (
    <UserContext.Provider
      value={useMemo(() => ({
        user,
        userFetchState 
      }), [user, userFetchState])}>
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
