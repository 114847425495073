const articleType = [
    'author',
    'search',
    'category'
]

export default {
    name: 'Glowforge:BlogArticlesFilteredList',
    friendlyName: 'Blog Articles Filtered List',
    image: 'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F5cde037e64374ab3a024bb2627684595?format=webp&width=200',
    noWrap: true,
    inputs: [
        {
            name: 'typeList',
            friendlyName: 'Type Articles',
            type: 'string',
            enum: articleType,
        },
     
        {
            name: 'notFoundTitle',
            showIf: (typeList) => typeList.get('typeList') === 'category' || typeList.get('typeList') === 'author',
            friendlyName: 'Not Found Title',
            type: 'text',
            defaultValue: 'No Articles found here.'
        },
        {
            name: 'foundResultsTitle',
            showIf: (typeList) => typeList.get('typeList') === 'search',
            friendlyName: 'Found Results Title',
            type: 'text',
            defaultValue: 'Results for:'
        },
        {
            name: 'notFoundResultsTitle',
            showIf: (typeList) => typeList.get('typeList') === 'search',
            friendlyName: 'Not Found Title',
            type: 'text',
            defaultValue: 'Nothing Found'
        },
        {
            name: 'notFoundSubtitle',
            showIf: (typeList) => typeList.get('typeList') === 'search',
            friendlyName: 'Not Found Subtitle',
            type: 'text',
            defaultValue: 'Sorry, but nothing matched your search terms. Please try again with some different keywords.'
        }
    ],
};
