/**
 * Take from: https://usehooks-ts.com/react-hook/use-intersection-observer
 * This is primarily for lazyloading things in.
 * 
 * Ex:
 * const ref = useRef(null)
 * const entry = useIntersectionObserver(ref, {})
 * const isVisible = !!entry?.isIntersecting
 * 
 */

 import { useEffect, useState } from 'react'

 function useIntersectionObserver(
   elementRef,
   {
     threshold = 0,
     root = null,
     rootMargin = '0%',
     freezeOnceVisible = false,
   },
  ) {
   const [entry, setEntry] = useState()
 
   const frozen = entry?.isIntersecting && freezeOnceVisible
 
   const updateEntry = ([entryItem]) => {
     setEntry(entryItem)
   }
 
   useEffect(() => {
     const node = elementRef?.current // DOM Ref
     const hasIOSupport = !!window.IntersectionObserver
 
     if (!hasIOSupport || frozen || !node) return
 
     const observerParams = { threshold, root, rootMargin }
     const observer = new IntersectionObserver(updateEntry, observerParams)
 
     observer.observe(node)
 
     /* eslint-disable-next-line */
     return () => observer.disconnect()
 
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [elementRef?.current, JSON.stringify(threshold), root, rootMargin, frozen])
 
   return entry
 }
 
 export default useIntersectionObserver
 