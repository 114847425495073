import FakeLocalStorage from './fake-local-storage';

export default class FakeWindow {
  constructor() {
    const noop = () => {};
    this.localStorage = new FakeLocalStorage();
    this.location = { href: '', search: '' };
    this.analytics = { page: noop, track: noop };
  }
}
